import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { signOutAction, getInquiries } from "../redux/auth/authActions";
import { Navigate, Link, Router, Route, Routes } from "react-router-dom";
import SecondaryHeader from "../components/SecondaryHeader";
import Footer from "../components/Footer";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Button, message, Upload, Table, Tag, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons"; // Import Ant Design Loading icon

import banner from "../assets/images/warranty-information.png";
import RedirectToSignin from "../components/RedirectToSignin";
import RedirectToHome from "../components/RedirectToHome";
import PrimaryMobileHeader from "../components/PrimaryMobileHeader";
import UserDashboardSideMenu from "../components/UserDashboardSideMenu";
import InputField from "../components/InputField";
import TrackingModal from "../components/TrackingModal";

const Inquries = () => {
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector((state) => state.auth);
  const [isMobile, setIsMobile] = useState(false);
  const [inquiries, setInquiries] = useState([]);
  const [loading, setLoading] = useState(false);

  const [trackingModalVisible, setTrackingModalVisible] = useState(false);
  const [trackingData, setTrackingData] = useState([]);
  const [formData, setFormData] = useState([]);

  const fetchInquiries = async () => {
    setLoading(true); // Start loading
    const response = await dispatch(getInquiries());
    setInquiries(response?.data);
    setLoading(false); // Stop loading
  };

  const handleSignOut = () => {
    dispatch(signOutAction());
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600); // Define the breakpoint for mobile
    };

    // Set initial value
    handleResize();
    fetchInquiries();

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Column definition for the table
  const columns = [
    {
      title: "No",
      dataIndex: "id", // 'id' used from your inquiries array
      key: "id",
      render: (text, record, index) => index + 1, // to render serial numbers starting from 1
    },
    {
      title: "Message",
      dataIndex: "message",
      key: "message",
    },
    {
      title: "Status",
      dataIndex: "trackable", // trackable contains the statuses
      key: "status",
      render: (trackable) => {
        // Access the latest status from the trackable array
        const latestTrackable = trackable?.[0]?.status || "Unknown";

        let color = "";

        // Map statuses to colors
        switch (latestTrackable) {
          case "CREATED":
            color = "orange";
            break;
          case "RECEIVED":
            color = "blue";
            break;
          case "REVIEWED":
            color = "yellow";
            break;
          case "REPLIED":
            color = "cyan";
            break;
          case "CONTACTED":
            color = "purple";
            break;
          case "RESOLVED":
            color = "green";
            break;
          case "CLOSED":
            color = "red";
            break;
          case "CANCELLED":
            color = "gray";
            break;
          case "VERIFIED":
            color = "lime";
            break;
          case "PENDING":
            color = "gold";
            break;
          case "APPROVED":
            color = "green";
            break;
          case "REJECTED":
            color = "red";
            break;
          default:
            color = "default";
            break;
        }

        return <Tag color={color}>{latestTrackable}</Tag>;
      },
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Button type="primary" onClick={() => handleTracking(record)}>
          Track
        </Button>
      ),
    },
  ];

  // Handle the tracking button click
  const handleTracking = (record) => {
    setTrackingData(record?.trackable);
    setFormData(record);
    setTrackingModalVisible(true);
    // You can add your tracking logic here
  };
  const handleCloseModal = () => setTrackingModalVisible(false);

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />; // Ant Design loading icon

  return (
    <div>
      <RedirectToSignin />
      <RedirectToHome />
      {!isMobile ? <SecondaryHeader /> : <PrimaryMobileHeader />}
      <div className="container">
        <div className="tendorsPage mt-4 pb-5">
          <div className="row justify-content-center">
            <div className="col-12">
              <h3 className="mb-4 text-center">My Dashboard</h3>{" "}
              {/* Center title on mobile */}
              <div className="row">
              <div className="col-12 col-md-3 mb-4">
                  {" "}
                  {/* Adjust column sizes for mobile */}
                  <UserDashboardSideMenu />
                </div>
                <div className={`col-lg-9 col-12`}>
                  <div className="card bg-white border-0 rounded-10">
                    <div className="card-body">
                      <form>
                        <div className="card rounded-10 bg-white border-0 mb-5">
                          <div className="card-body p-3 p-md-5">
                            {" "}
                            {/* Adjust padding for mobile */}
                            <h4 className="mb-4 text-center text-md-left">
                              Inquiries
                            </h4>{" "}
                            {/* Responsive alignment */}
                            {loading ? (
                              <div className="text-center">
                                <Spin indicator={antIcon} />{" "}
                              </div>
                            ) : (
                              <div className="table-responsive">
                                <Table
                                  dataSource={inquiries}
                                  columns={columns}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <TrackingModal
        visible={trackingModalVisible}
        onClose={handleCloseModal}
        trackingData={trackingData}
        details={formData}
      />
    </div>
  );
};

export default Inquries;
