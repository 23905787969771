// import "./ServicePage.css";
import "./Logistics.css";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  signOutAction,
  getCarMake,
  getCarModel,
  getCertificates,
  documentationForm,
} from "../redux/auth/authActions";
import { Navigate, Link } from "react-router-dom";
import SecondaryHeader from "../components/SecondaryHeader";
import Footer from "../components/Footer";
import "react-phone-input-2/lib/style.css";
import banner from "../assets/images/image (4).png";
import RedirectToSignin from "../components/RedirectToSignin";
import PrimaryMobileHeader from "../components/PrimaryMobileHeader";
import RedirectToHome from "../components/RedirectToHome";
import "../pages/DocAndCertificates.css";
import { right } from "@popperjs/core";
import { Checkbox } from "antd";
import { useNavigate } from "react-router-dom";
import { Button, message, Upload } from "antd";
import { useTranslation } from "react-i18next";
import CustomSelect from "../components/CustomSelect";
import { UploadOutlined } from "@ant-design/icons";
import i18n from "../i18n";
const ServicePage = () => {
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector((state) => state.auth);
  const { t } = useTranslation();
  const [isMobile, setIsMobile] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [refId, setRefId] = useState("");
  const [carMake, setCarMake] = useState([]);
  const [carModel, setCarModel] = useState([]);
  const [selectedCarMake, setSelectedCarMake] = useState("");
  const [selectedCarModel, setSelectedCarModel] = useState("");
  const [certificatesPrices, setCertificatePrices] = useState([]);
  const reduxState = useSelector((state) => state.auth);
  const [formData, setFormData] = useState({
    car_make_id: "",
    car_model_id: "",
    year: "",
    vin: "",
    comments: "",
    service_items: [],
    attachments: [],
    certificates: {
      RTACertificates: false,
      TrafficCertificates: false,
      AbcCertificatess: false,
      AbcdCertificates: false,
      RTACertificates: false,
    },
  });
  // Inside your ServicePage component
  const navigate = useNavigate();

  const certificatePrices = {
    RTACertificates: 190,
    TrafficCertificates: 299,
    AbcCertificatess: 99,
    AbcdCertificates: 410,
  };

  const fetchCarMake = async () => {
    const response = await dispatch(getCarMake());
    setCarMake(response?.data);
  };
  const fetchCarModel = async (id) => {
    const response = await dispatch(getCarModel(id));
    setCarModel(response?.data);
  };
  const fetchCertificates = async () => {
    const response = await dispatch(getCertificates());
    setCertificatePrices(response?.data);

    // setCarModel(response?.data);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "car_make_id") {
      const selectedBrand = carMake?.find(
        (brand) => brand?.id === parseInt(value)
      );
      if (selectedBrand) {
        setSelectedCarMake(selectedBrand?.name);
        const brandSlug = selectedBrand.slug; // You can remove this line after testing
        fetchCarModel(brandSlug); // Call fetchCarModel with the selected brand id
      } else {
        console.error("Brand not found for the given id");
      }
    }
    if (name === "car_model_id") {
      console.log("Car Model ID:", value);

      const selectedModel = carModel?.find(
        (model) => model?.id === parseInt(value)
      );
      console.log("Car Model ID:", value, selectedModel);
      if (selectedModel) {
        setSelectedCarModel(selectedModel?.name);
        const modelSlug = selectedModel.slug; // You can remove this line after testing
        // fetchCarModel(brandSlug); // Call fetchCarModel with the selected brand id
      } else {
        console.error("Brand not found for the given id");
      }
      // selectedModel && setSelectedCarModel(selectedModel?.name);
    }
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await dispatch(documentationForm(formData));
    const selectedCertificateIds = formData.service_items;

    // Create an array of certificates with true/false status based on selection
    const certificatesWithStatus = certificatesPrices.map((certificate) => ({
      ...certificate,
      selected: selectedCertificateIds.includes(certificate.id), // Set selected status to true/false
    }));

    // Extract selected certificates (names only for review)
    const selectedCertificates = certificatesWithStatus
      .filter((cert) => cert.selected) // Only take selected certificates
      .map((cert) => cert.name); // Extract names of selected certificates

    // Calculate subtotal based on selected certificates' costs
    const subtotal = certificatesWithStatus
      .filter((cert) => cert.selected) // Only selected certificates
      .reduce((total, cert) => total + cert.cost, 0); // Sum their costs

    // Assuming the grand total is the same as subtotal (can modify for additional fees/taxes)
    const grandTotal = subtotal;

    // Prepare data to pass to the Review page
    const dataToReview = {
      ...formData, // Include all filled form data
      certificates: certificatesWithStatus, // Pass certificates with their true/false status
      selectedCertificates, // Include only the selected certificates (names)
      subtotal, // Pass the subtotal
      grandTotal, // Pass the grand total
      selectedCarMake, // Car make (assume this is retrieved from formData or elsewhere)
      selectedCarModel, // Car model (assume this is retrieved from formData or elsewhere)
    };
    // Navigate to the Review page and pass the data
    navigate("/review", { state: { formData: dataToReview } });
  };

  const handleCheckboxChange = (e, certId) => {
    const { checked } = e.target;

    // Add or remove certificate IDs based on checkbox state
    setFormData((prevData) => {
      const updatedServiceItems = checked
        ? [...prevData.service_items, certId] // Add certificate ID
        : prevData.service_items.filter((id) => id !== certId); // Remove certificate ID if unchecked

      return {
        ...prevData,
        service_items: updatedServiceItems,
      };
    });
  };
  const allowedFileTypes = [
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "image/jpeg",
    "image/png",
  ];
  const handleFileUpload = (e, attachmentName) => {
    const file = e.target.files[0];
    if (file) {
      // Check if the uploaded file type is allowed
      if (allowedFileTypes.includes(file.type)) {
        setFormData((prevData) => {
          const updatedAttachments = [...prevData.attachments];

          // Check if an attachment with this name already exists
          const existingAttachmentIndex = updatedAttachments.findIndex(
            (att) => att.name === attachmentName
          );

          if (existingAttachmentIndex !== -1) {
            // If the attachment exists, replace the file
            updatedAttachments[existingAttachmentIndex].file = file;
          } else {
            // If it doesn't exist, add a new attachment object
            updatedAttachments.push({ name: attachmentName, file });
          }
          return {
            ...prevData,
            attachments: updatedAttachments,
          };
        });
      } else {
        // Show an error message if the file type is not allowed
        // alert("Invalid file type. Please upload a PDF, Word document, JPEG, or PNG file.");
        message.error(
          `${file.name} is not a valid file type. Only PDF, Word documents, and images are allowed.`
        );
      }
    }
  };

  const handleSignOut = () => {
    dispatch(signOutAction());
  };

  useEffect(() => {
    reduxState?.carMake ? setCarMake(reduxState?.carMake) : fetchCarMake();
    fetchCertificates();
    const detectedLanguage =
      localStorage.getItem("selectedLanguage") ||
      navigator.language.split("-")[0] ||
      "en";
    i18n.changeLanguage(detectedLanguage);
    document.documentElement.lang = detectedLanguage;
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="bg-white">
      <RedirectToSignin />
      <RedirectToHome />
      {!isMobile ? <SecondaryHeader /> : <PrimaryMobileHeader />}

      <div className="container">
        <div className="tendorsPage mt-4 pb-5">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-7" style={{ width: "1300px" }}>
              <img
                src={banner}
                alt="Banner"
                className="img-fluid mb-5"
                style={{ height: "494px", width: "1308px", objectFit: "cover" }}
              />
            </div>
            <div
              className="col-12 col-sm-7"
              style={{ width: "1038px", marginBottom: "2rem" }}
            >
              <h2
                className="text-center fw-bold mb-5"
                style={{ fontSize: "40px", color: "#050B20" }}
              >
                {t("doc_cert")}
              </h2>
              <div
                className="table-responsive-lg mt-4"
                style={{ paddingLeft: "4rem", paddingRight: "4rem" }}
              >
                <form onSubmit={handleSubmit}>
                  <div className="row mb-3 ">
                    <div className="col-md-8">
                      {/* Left Column */}
                      <div className="row mb-3">
                        <div className="col-md-4 mb-3">
                          <select
                            className="form-select bg-white doc-page-input doc-page-text"
                            name="car_make_id"
                            value={formData.car_make_id}
                            onChange={handleChange}
                            required
                            style={{ color: "#888" }}
                          >
                            <option value="">{`${t("make")}`}</option>
                            {carMake?.map((make) => (
                              <option key={make.id} value={make.id}>
                                {make.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="col-md-4 mb-3">
                          <select
                            className="form-select bg-white doc-page-input doc-page-text"
                            name="car_model_id"
                            value={formData.car_model_id}
                            onChange={handleChange}
                            required
                            style={{ color: "#888" }}
                          >
                            <option value="">{`${t("model")}`}</option>
                            {carModel?.map((make) => (
                              <option key={make.id} value={make.id}>
                                {make.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="col-md-4 mb-3">
                          <select
                            className="form-select bg-white doc-page-input doc-page-text"
                            name="year"
                            value={formData.year}
                            onChange={handleChange}
                            required
                            style={{ color: "#888" }}
                          >
                            <option value="">{t("year")}</option>
                            <option value="2024">2024</option>
                            <option value="2023">2023</option>
                            <option value="2022">2022</option>
                            <option value="2021">2021</option>
                          </select>
                        </div>
                      </div>

                      <div className="mb-4">
                        <input
                          type="text"
                          className="form-control bg-white doc-page-input "
                          id="vin"
                          name="vin"
                          value={formData.vin}
                          onChange={handleChange}
                          placeholder={t("enter_vin")}
                          required
                        />
                      </div>
                      <div className="mb-3">
                        <textarea
                          className="form-control bg-white doc-page-input"
                          id="comments"
                          name="comments"
                          rows="3"
                          value={formData.comments}
                          onChange={handleChange}
                          placeholder={t("enter_comments")}
                          style={{ height: "227px" }}
                        ></textarea>
                      </div>
                      {/* <div className="row mb-3">
                        <div className="col-md-6 mb-3">
                          <label className="btn btn-outline-secondary w-100">
                            {t("invoice_upload")}
                            <input
                              type="file"
                              accept={allowedFileTypes.join(",")}
                              onChange={(e) => handleFileUpload(e, "Invoice")}
                              style={{ display: "none" }}
                            />
                          </label>
                        </div>
                        <div className="col-md-6 mb-3">
                          <label className="btn btn-outline-secondary w-100">
                            {t("export_doc")}
                            <input
                              type="file"
                              accept={allowedFileTypes.join(",")}
                              onChange={(e) =>
                                handleFileUpload(e, "Export Documents")
                              }
                              style={{ display: "none" }}
                            />
                          </label>
                          <p className="mt-2"></p>
                        </div>
                      </div> */}
                      <div className="row mb-3">
                        {/* Invoice Upload */}
                        <div className="col-md-6 mb-3">
                          <label className="btn btn-outline-secondary w-100 d-flex justify-content-around doc-page-input align-items-center">
                            {t("invoice_upload")}
                            <input
                              type="file"
                              accept={allowedFileTypes.join(",")}
                              onChange={(e) => handleFileUpload(e, "Invoice")}
                              style={{ display: "none" }}
                            />
                            <Button
                              icon={<UploadOutlined />}
                              style={{
                                width: "24px",
                                height: "24px",
                                border: "none",
                              }}
                            ></Button>
                          </label>
                          {/* Display uploaded file name for "Invoice" */}
                          {formData.attachments.find(
                            (att) => att.name === "Invoice"
                          )?.file && (
                            <p className="mt-2 text-muted">
                              {" "}
                              <strong>
                                {
                                  formData.attachments.find(
                                    (att) => att.name === "Invoice"
                                  )?.file?.name
                                }
                              </strong>
                            </p>
                          )}
                        </div>

                        {/* Export Documents Upload */}
                        <div className="col-md-6 mb-3">
                          <label className="btn btn-outline-secondary w-100 d-flex justify-content-around doc-page-input align-items-center">
                            {t("export_doc")}
                            <input
                              type="file"
                              accept={allowedFileTypes.join(",")}
                              onChange={(e) =>
                                handleFileUpload(e, "Export Documents")
                              }
                              style={{ display: "none" }}
                            />
                            <Button
                              icon={<UploadOutlined />}
                              style={{
                                width: "24px",
                                height: "24px",
                                border: "none",
                              }}
                            ></Button>
                          </label>
                          {/* Display uploaded file name for "Export Documents" */}
                          {formData.attachments.find(
                            (att) => att.name === "Export Documents"
                          )?.file && (
                            <p className="mt-2 text-muted">
                              {" "}
                              <strong>
                                {
                                  formData.attachments.find(
                                    (att) => att.name === "Export Documents"
                                  )?.file?.name
                                }
                              </strong>
                            </p>
                          )}
                        </div>
                      </div>
                    </div>

                    {/* Right Column (Certifications) */}
                    <div className="col-md-4">
                      <div className="certifications-container">
                        {certificatesPrices?.map((certificate) => (
                          <div
                            key={certificate.id}
                            className="d-flex align-items-center justify-content-between mb-3"
                          >
                            <div
                              className="certification-box p-3 rounded border w-100 d-flex justify-content-between align-items-center"
                              style={{ minHeight: "3rem" }}
                            >
                              <label
                                className="form-check-label doc-page-text"
                                htmlFor={certificate.code}
                              >
                                {certificate.name}
                              </label>
                              <span
                                style={{ fontWeight: "600" }}
                                className="doc-page-price"
                              >
                                {certificate.cost} AED
                              </span>
                            </div>
                            <input
                              type="checkbox"
                              id={certificate.code}
                              name={certificate.code}
                              checked={formData.service_items.includes(
                                certificate.id
                              )}
                              onChange={(e) =>
                                handleCheckboxChange(e, certificate.id)
                              }
                              className="form-check-input rounded-circle ms-2 doc-page-form-check"
                              style={{ width: "27px", height: "27px" }}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>

                  {/* <div className="text-center">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      style={{
                        backgroundColor: "#000740",
                        width: "100%",
                        maxWidth: "267px",
                        height: "52px",
                        marginBottom: "30px",
                        fontSize: "20px",
                      }}
                    >
                      {t("continu_review")}
                    </button>
                  </div> */}
                </form>
              </div>
            </div>
            <div className="text-center">
              <button
                onClick={handleSubmit}
                className="btn btn-primary"
                style={{
                  backgroundColor: "#000740",
                  width: "100%",
                  maxWidth: "267px",
                  height: "45px",
                  marginBottom: "30px",
                  fontSize: "20px",
                }}
              >
                {t("continu_review")}
              </button>
            </div>
            <div className="container-text col-12 col-sm-7">
              {/* Container for the fade effect */}
              <div className="fade-container">
                <div className="scrollable-section">
                  <div className="scrollable-content ms-2">
                    <div className="row">
                      {/* Payment Methods Section */}
                      <div className="col-12 pb-3">
                        <p>
                          <h4>
                            Obtain Your Vehicle Documentation & Certificates
                            with Ease
                          </h4>
                        </p>
                        <p>
                          At Cruise Motors, we know how important it is to have
                          the correct documentation and certifications for your
                          vehicle, whether for export, compliance, or
                          importation. That’s why we’ve made it simple for you
                          to apply for the required documents through our
                          Documentation & Certification service. With a quick
                          form submission, we handle the rest— catering to
                          certificate requirements for various countries, no
                          matter how specific.
                        </p>
                        <p>
                          <strong>What Certificates Can You Apply For?</strong>
                        </p>
                        <p>
                          We offer a range of essential vehicle documents to
                          meet your needs:
                        </p>
                        <p>
                          <strong>
                            . RTA Export Certificate (without Plate Number):
                          </strong>{" "}
                          Required for vehicle exports, this certificate proves
                          your car is ready for export without the need for a
                          plate number.
                        </p>
                        <p>
                          <strong>
                            .GSO (Gulf Standards Organization) Certificate:{" "}
                          </strong>
                          For vehicles destined for GCC countries, this
                          certification ensures compliance with regional
                          standards.
                        </p>
                        <p>
                          <strong>.Certificate of Origin:</strong> Required by
                          customs in many countries, this certificate confirms
                          where your vehicle was manufactured and helps ensure
                          smooth importation.
                        </p>
                        <p>
                          <strong>.Certificate of Conformity</strong>: Essential
                          for vehicle imports, this certificate confirms your
                          car meets the safety and environmental standards of
                          your destination country.
                        </p>
                      </div>

                      {/* <div className="col-12">
                        <Link className="theme-color" to="/terms-and-condition">
                          {t("trms_condtn")}
                        </Link>
                      </div> */}

                      {/* Additional Information Section */}
                      <div className="col-12 mt-4">
                        <p>
                          <strong>How it Works</strong>
                        </p>
                        <p>
                          <strong>
                            Step 1: Fill Out the Application Form & Make the
                            Payments
                          </strong>{" "}
                          We Simply fill in the form with your vehicle details
                          and the specific documentation or certificates you
                          require. This includes information like the make,
                          model, year, and any additional details relevant to
                          the certification.
                        </p>
                        <p>
                          <strong>Step 2: We Process Your Request</strong> We
                          Once we have your submission, our team will review the
                          information and begin the process of obtaining your
                          requested documents. We work closely with authorities
                          and certification bodies to ensure everything is done
                          accurately and efficiently.
                        </p>
                        <p>
                          <strong>Step 3: Receive Your Certificates</strong> We
                          Once the documents are issued, we’ll notify you and
                          arrange for the swift delivery of your vehicle
                          certificates. Whether digital or physical copies, we
                          ensure they are delivered promptly so you can move
                          forward with your vehicle plans.
                        </p>
                      </div>

                      <div className="col-12 mt-4">
                        <h4 className="fw-bold">
                          Why Apply for Certificates through Cruise Motors?
                        </h4>
                        <p>
                          Comprehensive Documentation Solutions: We handle every
                          type of vehicle certificate— from export certificates
                          to compliance documents—making it easier for you to
                          meet regulatory requirements.
                        </p>
                        <p>
                          For Each Country: Different countries have different
                          certification needs, and we cater to all. Whether
                          you’re exporting or importing, we ensure your vehicle
                          has the correct documents in place.
                        </p>
                      </div>
                      <div className="col-12 mt-4">
                        <h4 className="fw-bold">
                          Ready to Obtain Your Vehicle Certificates?
                        </h4>
                        <p>
                          If you need an RTA Export Certificate, GSO
                          Certificate, or any other vehicle documentation, we’re
                          here to help. Fill out our Documentation &
                          Certification Form today and let us handle the process
                          from start to finish.
                        </p>
                        <p>
                          Get your vehicle certified quickly and hassle-free
                          with Cruise Motors. Apply now!
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default ServicePage;
