import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  signOutAction,
  submitRequestQuotationForm,
  getCarMake,
  getCarModel,
  getCountry,
  getState,
  getCity,
  getPort,
} from "../redux/auth/authActions";
import { Navigate, Link } from "react-router-dom";
import SecondaryHeader from "../components/SecondaryHeader";
import Footer from "../components/Footer";
import banner from "../assets/images/quotation-page.png";
import log1 from "../assets/images/log1.png";
import log2 from "../assets/images/log2.png";
import { Button, InputGroup, FormControl } from "react-bootstrap";
import { message, Upload } from "antd";
import InputField from "../components/InputField";
import RedirectToSignin from "../components/RedirectToSignin";
import RedirectToHome from "../components/RedirectToHome";
import PrimaryMobileHeader from "../components/PrimaryMobileHeader";
import SuccessForm from "./SuccessForm";
import { useTranslation } from "react-i18next";
import CustomSelect from "../components/CustomSelect";
import i18n from "../i18n";
const RequestForQuotation = () => {
  const dispatch = useDispatch();
  const [isMobile, setIsMobile] = useState(false);
  const { isAuthenticated } = useSelector((state) => state.auth);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [refId, setRefId] = useState("");
  const { t, i18n } = useTranslation();
  const [carMake, setCarMake] = useState([]);
  const [carModel, setCarModel] = useState([]);
  const [country, setCountry] = useState([]);
  const [pickupStates, setPickupStates] = useState([]);
  const [departureStates, setDepartureStates] = useState([]);
  const [pickupCities, setPickupCities] = useState([]);
  const [departureCities, setDepartureCities] = useState([]);
  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);
  const [port, setPort] = useState([]);
  const reduxState = useSelector((state) => state.auth);
  const [rows, setRows] = useState([
    { quantity: 1, car_make_id: "", car_model_id: "", year: "" },
  ]);
  const [formData, setFormData] = useState({
    departure_country_id: "",
    departure_state_id: "",
    departure_city_id: "",
    // destination: "",
    transportation_type: "air", // Default transport type
  });

  const [errors, setErrors] = useState({});

  const validate = () => {
    let formErrors = {};
    let rowErrors = [];

    if (!formData.departure_country_id)
      formErrors.departure_country_id = t("errors.country");
    if (!formData.departure_city_id)
      formErrors.departure_city_id = t("errors.city");
    if (!formData.departure_state_id)
      formErrors.departure_state_id = t("errors.state");
    // if (!formData.destination) formErrors.destination = "Enter Departure destination";
    if (!formData.pickup_country_id)
      formErrors.pickup_country_id = t("errors.country");
    if (!formData.pickup_city_id) formErrors.pickup_city_id = t("errors.city");
    if (!formData.pickup_state_id)
      formErrors.pickup_state_id = t("errors.state");

    // Validate rows
    rows.forEach((row, index) => {
      let rowError = {};
      if (!row.car_make_id) rowError.car_make_id = `${t("errors.make")}`;
      if (!row.car_model_id) rowError.car_model_id = `${t("errors.model")}`;
      if (!row.year) rowError.year = `${t("errors.year")}`;
      if (Object.keys(rowError).length > 0) {
        rowErrors[index] = rowError;
      }
    });

    return { ...formErrors, rowErrors };
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "departure_country_id") {
      fetchState(value, setDepartureStates); // Fetch and update departure states
    } else if (name === "pickup_country_id") {
      fetchState(value, setPickupStates); // Fetch and update pickup states
    }

    // Fetch city based on state selection
    else if (name === "departure_state_id") {
      fetchCity(value, setDepartureCities); // Fetch and update departure cities
    } else if (name === "pickup_state_id") {
      fetchCity(value, setPickupCities); // Fetch and update pickup cities
    }
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleRowChange = (index, e) => {
    const { name, value } = e.target;
    if (name === "car_make_id") {
      const selectedBrand = carMake?.find(
        (brand) => brand?.id === parseInt(value)
      );
      if (selectedBrand) {
        const brandSlug = selectedBrand.slug;
        fetchCarModel(brandSlug); // Call fetchCarModel with the selected brand id
      } else {
        console.error("Brand not found for the given id");
      }
    }
    const updatedRows = [...rows];
    updatedRows[index] = { ...updatedRows[index], [name]: value };
    setRows(updatedRows);
  };

  const handleSignOut = () => {
    dispatch(signOutAction());
  };

  const addRow = () => {
    setRows([
      ...rows,
      { quantity: 1, car_make_id: "", car_model_id: "", year: "" },
    ]);
  };

  const removeRow = (index) => {
    const updatedRows = rows.filter((_, i) => i !== index);
    setRows(updatedRows);
  };

  const increment = (index) => {
    const updatedRows = [...rows];
    updatedRows[index].quantity += 1;
    setRows(updatedRows);
  };

  const decrement = (index) => {
    const updatedRows = [...rows];
    if (updatedRows[index].quantity > 1) {
      updatedRows[index].quantity -= 1;
    }
    setRows(updatedRows);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formErrors = validate();

    if (Object.keys(formErrors).length > 1) {
      setErrors(formErrors);
      return;
    }

    if (formErrors?.rowErrors?.length > 0) {
      setErrors(formErrors);
      return;
    }

    // Combine form data with row data
    const combinedData = {
      ...formData,
      quotation_items: rows,
    };

    const response = await dispatch(submitRequestQuotationForm(combinedData));
    if (response?.code == 200) {
      message.success("Submitted successfully!");
      setRefId(response?.data?.code);
      setFormSubmitted(true);
    } else {
      message.error(response?.message || "Submission failed!");
    }
  };
  const fetchCarMake = async () => {
    const response = await dispatch(getCarMake());
    setCarMake(response?.data);
  };
  const fetchCountry = async () => {
    const response = await dispatch(getCountry());
    setCountry(response?.data);
  };

  const fetchState = async (id, setStateCallback) => {
    const response = await dispatch(getState(id));
    setStateCallback(response?.data);
  };
  const fetchCity = async (id, setStateCallback) => {
    const url_id = formData.departure_country_id
      ? formData.departure_country_id + "/" + id
      : id;
    const response = await dispatch(getCity(url_id));
    setStateCallback(response?.data);
  };

  const fetchCarModel = async (id) => {
    const response = await dispatch(getCarModel(id));
    setCarModel(response?.data);
  };
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600); // Define the breakpoint for mobile
    };
    reduxState?.carMake ? setCarMake(reduxState?.carMake) : fetchCarMake();
    reduxState?.countries ? setCountry(reduxState?.countries) : fetchCountry();
    const detectedLanguage =
      localStorage.getItem("selectedLanguage") ||
      navigator.language.split("-")[0] ||
      "en";
    i18n.changeLanguage(detectedLanguage);
    document.documentElement.lang = detectedLanguage;

    // Set initial value
    handleResize();

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="bg-white">
      <RedirectToSignin />
      <RedirectToHome />
      {!isMobile ? <SecondaryHeader /> : <PrimaryMobileHeader />}
      <div className="container">
        <div className="servicesPageList mt-4 pb-5">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-7">
              <img src={banner} alt="Banner" className="img-fluid mb-5" />
              <div className="card rounded-10 bg-white border-0 mb-5">
                <div className="card-body p-5">
                  <h2 className="mb-4 text-center">
                    {t("request_for_quotation")}
                  </h2>
                  {formSubmitted ? (
                    <SuccessForm
                      refId={refId}
                      resetForm={() => {
                        setFormSubmitted(false);
                      }}
                    />
                  ) : (
                    <form onSubmit={handleSubmit}>
                      {rows?.map((row, index) => (
                        <div
                          className="d-flex flex-column flex-md-row align-items-start gap-3 mb-3"
                          key={index}
                        >
                          <div className="col-10 col-sm-11 col-md-11">
                            <div className="row g-3">
                              <div className="col-12 col-sm-3">
                                <InputGroup className="">
                                  <Button
                                    variant="outline-secondary"
                                    className="h-100"
                                    onClick={() => decrement(index)}
                                  >
                                    <i className="bi bi-dash"></i>
                                  </Button>
                                  <FormControl
                                    type="text"
                                    readOnly
                                    name="quantity"
                                    value={row.quantity}
                                    onChange={(e) => handleRowChange(index, e)}
                                    min="1"
                                    className="font-13 text-center"
                                  />
                                  <Button
                                    variant="outline-secondary"
                                    onClick={() => increment(index)}
                                  >
                                    <i className="bi bi-plus"></i>
                                  </Button>
                                </InputGroup>
                              </div>
                              {/* <div className="col-12 col-sm-3">
                                <InputField
                                  fieldType="select"
                                  selectOption={`${t("make")}*`}
                                  name="car_make_id"
                                  value={row.car_make_id}
                                  onChange={(e) => handleRowChange(index, e)}
                                  options={carMake?.map((make) => ({
                                    value: make.id, // Using the car make ID as the value
                                    label: make.name, // Using the car make name as the label
                                  }))}
                                  customClass={
                                    errors?.rowErrors &&
                                    errors.rowErrors[index]?.car_make_id
                                      ? "form-select is-invalid"
                                      : "form-select"
                                  }
                                />
                                {errors.rowErrors &&
                                  errors.rowErrors[index] &&
                                  errors.rowErrors[index].car_make_id && (
                                    <div className="text-danger font-13">
                                      {errors.rowErrors[index].car_make_id}
                                    </div>
                                  )}
                              </div> */}
                              <div className="col-12 col-sm-3">
                                <CustomSelect
                                  name="car_make_id"
                                  options={carMake?.map((make) => ({
                                    value: make.id,
                                    label: make.name,
                                  }))}
                                  value={row.car_make_id}
                                  onChange={(e) => handleRowChange(index, e)} // Pass event to handleRowChange
                                  placeholder={`${t("make")}*`} // Placeholder for the select
                                  customClass={
                                    errors?.rowErrors &&
                                    errors.rowErrors[index]?.car_make_id
                                      ? "is-invalid custom-select" // Add custom class with error handling
                                      : "custom-select"
                                  }
                                />
                                {errors.rowErrors &&
                                  errors.rowErrors[index]?.car_make_id && (
                                    <div className="text-danger font-13">
                                      {errors.rowErrors[index].car_make_id}
                                    </div>
                                  )}
                              </div>
                              {/* <div className="col-12 col-sm-3">
                                <InputField
                                  fieldType="select"
                                  selectOption={`${t("model")}*`}
                                  name="car_model_id"
                                  value={row.car_model_id}
                                  onChange={(e) => handleRowChange(index, e)}
                                  options={carModel?.map((make) => ({
                                    value: make.id, // Using the car make ID as the value
                                    label: make.name, // Using the car make name as the label
                                  }))}
                                  customClass={
                                    errors?.rowErrors &&
                                    errors.rowErrors[index]?.car_model_id
                                      ? "form-select is-invalid"
                                      : "form-select"
                                  }
                                />
                                {errors.rowErrors &&
                                  errors.rowErrors[index] &&
                                  errors.rowErrors[index].car_model_id && (
                                    <div className="text-danger font-13">
                                      {errors.rowErrors[index].car_model_id}
                                    </div>
                                  )}
                              </div> */}
                              <div className="col-12 col-sm-3">
                                <CustomSelect
                                  name="car_model_id"
                                  options={carModel?.map((model) => ({
                                    value: model.id, // Using the car model ID as the value
                                    label: model.name, // Using the car model name as the label
                                  }))}
                                  value={row.car_model_id} // Pass the selected value
                                  onChange={(e) => handleRowChange(index, e)} // Handle change for the model select
                                  placeholder={`${t("model")}*`} // Placeholder text for the select input
                                  customClass={
                                    errors?.rowErrors &&
                                    errors.rowErrors[index]?.car_model_id
                                      ? "is-invalid custom-select" // Add custom class for error handling
                                      : "custom-select"
                                  }
                                />
                                {errors.rowErrors &&
                                  errors.rowErrors[index]?.car_model_id && (
                                    <div className="text-danger font-13">
                                      {errors.rowErrors[index].car_model_id}
                                    </div>
                                  )}
                              </div>
                              {/* <div className="col-12 col-sm-3">
                                <InputField
                                  fieldType="select"
                                  selectOption={`${t("year")}*`}
                                  name="year"
                                  value={row.year}
                                  onChange={(e) => handleRowChange(index, e)}
                                  options={[
                                    { value: "2020", label: "2020" },
                                    { value: "2021", label: "2021" },
                                    { value: "2022", label: "2022" },
                                    { value: "2023", label: "2023" },
                                    { value: "2024", label: "2024" },
                                  ]}
                                  customClass={
                                    errors?.rowErrors &&
                                    errors.rowErrors[index]?.year
                                      ? "form-select is-invalid"
                                      : "form-select"
                                  }
                                />
                                {errors.rowErrors &&
                                  errors.rowErrors[index] &&
                                  errors.rowErrors[index].year && (
                                    <div className="text-danger font-13">
                                      {errors.rowErrors[index].year}
                                    </div>
                                  )}
                              </div> */}
                              <div className="col-12 col-sm-3">
                                <CustomSelect
                                  name="year"
                                  options={[
                                    { value: "2020", label: "2020" },
                                    { value: "2021", label: "2021" },
                                    { value: "2022", label: "2022" },
                                    { value: "2023", label: "2023" },
                                    { value: "2024", label: "2024" },
                                  ]}
                                  value={row.year} // Pass the selected value
                                  onChange={(e) => handleRowChange(index, e)} // Handle change for the year select
                                  placeholder={`${t("year")}*`} // Placeholder text for the select input
                                  customClass={
                                    errors?.rowErrors &&
                                    errors.rowErrors[index]?.year
                                      ? "is-invalid custom-select" // Add custom class for error handling
                                      : "custom-select"
                                  }
                                />
                                {errors.rowErrors &&
                                  errors.rowErrors[index]?.year && (
                                    <div className="text-danger font-13">
                                      {errors.rowErrors[index].year}
                                    </div>
                                  )}
                              </div>
                            </div>
                          </div>
                          {rows.length > 1 && (
                            <div className="col-2 col-sm-1 col-md-1 d-flex align-items-end">
                              <button
                                type="button"
                                className="btn btn-danger w-100"
                                onClick={() => removeRow(index)}
                              >
                                <i className="bi bi-trash"></i>
                              </button>
                            </div>
                          )}
                        </div>
                      ))}

                      <div className="text-end mb-3">
                        <div className="row g-3">
                          <div className="col-10 col-sm-11 col-md-11"></div>
                          <div className="col-2 col-sm-1 col-md-1">
                            <button
                              type="button"
                              className="btn btn-primary w-100"
                              onClick={addRow}
                            >
                              <i className="bi bi-plus-lg"></i>
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className="row g-3 mb-4 text-start">
                        <div className="row g-3 ">
                          <label className="font-13 text-uppercase text-muted mb-2">
                            {t("pol")}
                          </label>
                          {/* <div className="col-12 col-sm-4">
                            <InputField
                              fieldType="select"
                              selectOption={`${t("country")}*`}
                              name="departure_country_id"
                              value={formData.departure_country_id}
                              onChange={handleChange}
                              options={country?.map((make) => ({
                                value: make.id, // Using the car make ID as the value
                                label: make.name, // Using the car make name as the label
                              }))}
                              customClass={
                                errors?.departure_country_id
                                  ? "form-select is-invalid"
                                  : "form-select"
                              }
                            />
                            {errors.departure_country_id && (
                              <div className="text-danger font-13">
                                {errors.departure_country_id}
                              </div>
                            )}
                          </div>
                          <div className="col-12 col-sm-4">
                            <InputField
                              fieldType="select"
                              selectOption={`${t("state")}`}
                              name="departure_state_id"
                              value={formData.departure_state_id}
                              onChange={handleChange}
                              options={departureStates?.map((make) => ({
                                value: make.id, // Using the car make ID as the value
                                label: make.name, // Using the car make name as the label
                              }))}
                              customClass={
                                errors?.departure_state_id
                                  ? "form-select is-invalid"
                                  : "form-select"
                              }
                            />
                            {errors.departure_state_id && (
                              <div className="text-danger font-13">
                                {errors.departure_state_id}
                              </div>
                            )}
                          </div>
                          <div className="col-12 col-sm-4">
                            <InputField
                              fieldType="select"
                              selectOption={`${t("city")}*`}
                              name="departure_city_id"
                              value={formData.departure_city_id}
                              onChange={handleChange}
                              options={departureCities?.map((make) => ({
                                value: make.id, // Using the car make ID as the value
                                label: make.name, // Using the car make name as the label
                              }))}
                              customClass={
                                errors?.departure_city_id
                                  ? "form-select is-invalid"
                                  : "form-select"
                              }
                            />
                            {errors.departure_state_id && (
                              <div className="text-danger font-13">
                                {errors.departure_city_id}
                              </div>
                            )}
                          </div> */}
                          <div className="col-12 col-sm-4">
                            <CustomSelect
                              name="departure_country_id"
                              options={country?.map((make) => ({
                                value: make.id, // Use the country ID as the value
                                label: make.name, // Use the country name as the label
                              }))}
                              value={formData.departure_country_id} // Pass the selected value
                              onChange={(e) => handleChange(e)} // Handle change for the country select
                              placeholder={`${t("country")}*`} // Placeholder text for the select input
                              customClass={
                                errors?.departure_country_id
                                  ? "is-invalid custom-select" // Add custom class for error handling
                                  : "custom-select"
                              }
                            />
                            {errors.departure_country_id && (
                              <div className="text-danger font-13">
                                {errors.departure_country_id}
                              </div>
                            )}
                          </div>

                          <div className="col-12 col-sm-4">
                            <CustomSelect
                              name="departure_state_id"
                              options={departureStates?.map((make) => ({
                                value: make.id, // Use the state ID as the value
                                label: make.name, // Use the state name as the label
                              }))}
                              value={formData.departure_state_id} // Pass the selected value
                              onChange={(e) => handleChange(e)} // Handle change for the state select
                              placeholder={`${t("state")}`} // Placeholder text for the select input
                              customClass={
                                errors?.departure_state_id
                                  ? "is-invalid custom-select" // Add custom class for error handling
                                  : "custom-select"
                              }
                            />
                            {errors.departure_state_id && (
                              <div className="text-danger font-13">
                                {errors.departure_state_id}
                              </div>
                            )}
                          </div>

                          <div className="col-12 col-sm-4">
                            <CustomSelect
                              name="departure_city_id"
                              options={departureCities?.map((make) => ({
                                value: make.id, // Use the city ID as the value
                                label: make.name, // Use the city name as the label
                              }))}
                              value={formData.departure_city_id} // Pass the selected value
                              onChange={(e) => handleChange(e)} // Handle change for the city select
                              placeholder={`${t("city")}*`} // Placeholder text for the select input
                              customClass={
                                errors?.departure_city_id
                                  ? "is-invalid custom-select" // Add custom class for error handling
                                  : "custom-select"
                              }
                            />
                            {errors.departure_city_id && (
                              <div className="text-danger font-13">
                                {errors.departure_city_id}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="row g-3">
                          <label className="font-13 text-uppercase text-muted mb-2">
                            {t("pod")}
                          </label>
                          {/* <div className="col-12 col-sm-4">
                            <InputField
                              fieldType="select"
                              selectOption={`${t("country")}*`}
                              name="pickup_country_id"
                              value={formData.pickup_country_id}
                              onChange={handleChange}
                              options={country?.map((make) => ({
                                value: make.id, // Using the car make ID as the value
                                label: make.name, // Using the car make name as the label
                              }))}
                              customClass={
                                errors?.pickup_country_id
                                  ? "form-select is-invalid"
                                  : "form-select"
                              }
                            />
                            {errors.pickup_country_id && (
                              <div className="text-danger font-13">
                                {errors.pickup_country_id}
                              </div>
                            )}
                          </div>
                          <div className="col-12 col-sm-4">
                            <InputField
                              fieldType="select"
                              selectOption={`${t("state")}`}
                              name="pickup_state_id"
                              value={formData.pickup_state_id}
                              onChange={handleChange}
                              options={pickupStates?.map((make) => ({
                                value: make.id, // Using the car make ID as the value
                                label: make.name, // Using the car make name as the label
                              }))}
                              customClass={
                                errors?.pickup_state_id
                                  ? "form-select is-invalid"
                                  : "form-select"
                              }
                            />
                            {errors.pickup_state_id && (
                              <div className="text-danger font-13">
                                {errors.pickup_state_id}
                              </div>
                            )}
                          </div>
                          <div className="col-12 col-sm-4">
                            <InputField
                              fieldType="select"
                              selectOption={`${t("city")}*`}
                              name="pickup_city_id"
                              value={formData.pickup_city_id}
                              onChange={handleChange}
                              options={pickupCities?.map((make) => ({
                                value: make.id, // Using the car make ID as the value
                                label: make.name, // Using the car make name as the label
                              }))}
                              customClass={
                                errors?.pickup_city_id
                                  ? "form-select is-invalid"
                                  : "form-select"
                              }
                            />
                            {errors.pickup_city_id && (
                              <div className="text-danger font-13">
                                {errors.pickup_city_id}
                              </div>
                            )}
                          </div> */}
                          <div className="col-12 col-sm-4">
                            <CustomSelect
                              name="pickup_country_id"
                              options={country?.map((make) => ({
                                value: make.id, // Use the country ID as the value
                                label: make.name, // Use the country name as the label
                              }))}
                              value={formData.pickup_country_id} // Pass the selected value
                              onChange={(e) => handleChange(e)} // Handle change for the country select
                              placeholder={`${t("country")}*`} // Placeholder text for the select input
                              customClass={
                                errors?.pickup_country_id
                                  ? "is-invalid custom-select" // Add custom class for error handling
                                  : "custom-select"
                              }
                            />
                            {errors.pickup_country_id && (
                              <div className="text-danger font-13">
                                {errors.pickup_country_id}
                              </div>
                            )}
                          </div>

                          <div className="col-12 col-sm-4">
                            <CustomSelect
                              name="pickup_state_id"
                              options={pickupStates?.map((make) => ({
                                value: make.id, // Use the state ID as the value
                                label: make.name, // Use the state name as the label
                              }))}
                              value={formData.pickup_state_id} // Pass the selected value
                              onChange={(e) => handleChange(e)} // Handle change for the state select
                              placeholder={`${t("state")}`} // Placeholder text for the select input
                              customClass={
                                errors?.pickup_state_id
                                  ? "is-invalid custom-select" // Add custom class for error handling
                                  : "custom-select"
                              }
                            />
                            {errors.pickup_state_id && (
                              <div className="text-danger font-13">
                                {errors.pickup_state_id}
                              </div>
                            )}
                          </div>

                          <div className="col-12 col-sm-4">
                            <CustomSelect
                              name="pickup_city_id"
                              options={pickupCities?.map((make) => ({
                                value: make.id, // Use the city ID as the value
                                label: make.name, // Use the city name as the label
                              }))}
                              value={formData.pickup_city_id} // Pass the selected value
                              onChange={(e) => handleChange(e)} // Handle change for the city select
                              placeholder={`${t("city")}*`} // Placeholder text for the select input
                              customClass={
                                errors?.pickup_city_id
                                  ? "is-invalid custom-select" // Add custom class for error handling
                                  : "custom-select"
                              }
                            />
                            {errors.pickup_city_id && (
                              <div className="text-danger font-13">
                                {errors.pickup_city_id}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-12 col-sm-12">
                          <label className="font-13 text-uppercase text-muted mb-2">
                            {t("transport_type")}
                          </label>

                          <div className="d-flex justify-content-start">
                            <div className="form-check me-5">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="transportation_type"
                                value="air"
                                checked={formData.transportation_type === "air"}
                                onChange={handleChange}
                              />
                              <label className="form-check-label" htmlFor="air">
                                {t("air")}
                              </label>
                            </div>
                            <div className="form-check me-5">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="transportation_type"
                                value="sea"
                                checked={formData.transportation_type === "sea"}
                                onChange={handleChange}
                              />
                              <label className="form-check-label" htmlFor="sea">
                                {t("sea")}
                              </label>
                            </div>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="transportation_type"
                                value="land"
                                checked={
                                  formData.transportation_type === "land"
                                }
                                onChange={handleChange}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="land"
                              >
                                {t("land")}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row g-3 text-center">
                        <div className="col-12 col-sm-12">
                          <button
                            type="submit"
                            className="btn btn-primary btn-lg ps-5 pe-5 font-15 mb-3"
                          >
                             {t("submit")}
                          </button>
                          <p className="m-0 text-muted font-15">
                            {t("respond_time")}
                          </p>
                        </div>
                      </div>
                    </form>
                  )}
                </div>
              </div>

              <div class="container-text">
                <div class="fade-container">
                  <div class="scrollable-section">
                    <div class="scrollable-content ms-2">
                      <div class="row">
                        {/* <!-- Request For Quotation Section --> */}
                        <div class="col-12 pb-3">
                          <h3 class="fw-bold">Request For Quotation</h3>

                          <h4 class="fw-bold">Get Your Free Quote Today!</h4>
                          <p>
                            Shipping your car to another country might feel
                            overwhelming—but getting a custom quote? That’s the
                            easy part! Whether you’re shipping a single vehicle
                            or an entire fleet, we’re here to help you plan
                            better, save money, and get peace of mind.
                          </p>
                          <p>
                            <strong>Why Request a Quote from Us?</strong>
                          </p>
                          <ul>
                            <li>
                              <strong>Made Just for You:</strong> Every car
                              shipment is unique. Our form gives you the chance
                              to tell us exactly what you need—whether it’s EXW,
                              CNF, FOB, CIF, or FCA. We craft a custom plan that
                              fits your situation perfectly.
                            </li>
                            <li>
                              <strong>Unbeatable Rates:</strong> We leverage our
                              extensive network to get you competitive pricing
                              without cutting corners on quality. Your budget
                              matters, and we make sure you get the best value.
                            </li>
                            <li>
                              <strong>Expert Guidance:</strong> Shipping a car
                              involves a lot of moving parts—literally. Our team
                              of experts walks you through the process
                              step-by-step, ensuring everything runs smoothly
                              from start to finish.
                            </li>
                            <li>
                              <strong>Fast & Simple:</strong> Our online form
                              takes just a few moments to complete. Give us the
                              details, and we’ll get back to you quickly with a
                              comprehensive quote.
                            </li>
                            <li>
                              <strong>Honest & Transparent:</strong> No hidden
                              fees, no surprises. Your information is secure
                              with us, and we prioritize clear, upfront
                              communication every step of the way.
                            </li>
                          </ul>

                          <h4 class="fw-bold">How It Works:</h4>
                          <ol>
                            <li>
                              <strong>Fill Out the Form:</strong> Let us know
                              the key details—your car, your shipping
                              preferences, and where it’s going. It only takes a
                              few minutes!
                            </li>
                            <li>
                              <strong>Receive Your Quote:</strong> We’ll review
                              your info and send you a personalized, detailed
                              quote based on your needs.
                            </li>
                            <li>
                              <strong>Review at Your Pace:</strong> Take your
                              time reviewing your quote. If you have any
                              questions or need adjustments, we’re just a
                              message away.
                            </li>
                          </ol>

                          <h4 class="fw-bold">
                            Ready to Ship Your Car with Ease?
                          </h4>
                          <p>
                            Filling out our “Request for Quotation” form is the
                            first step to stress-free car shipping. We handle
                            the complex logistics while you focus on what
                            matters most. Don’t wait—get your free,
                            no-obligation quote today and take the hassle out of
                            international car shipping!
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        \
      </div>
      <Footer />
    </div>
  );
};

export default RequestForQuotation;
