import "../components/Brands.css";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import carousel styles
import useScrollAnimation from "./useScrollAnimation";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
import { API_URL } from "../api/authApi";
import { getFeatureBrandList, getBrandList } from "../redux/auth/authActions";
const Brands = () => {
  const elementsRef = useScrollAnimation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const api_url = API_URL.replace("/api", "");
  const [brands, setBrands] = useState([]);
  const reduxState = useSelector((state) => state.auth);
  const fetchFeaturedBrands = async () => {
    let url = "0?featured=0";
    const response = await dispatch(getFeatureBrandList(url));
    setBrands(response?.data?.slice(0, 12));
  };

  // Divide brands into chunks of 6 (or as per the design) per slide
  const chunkBrands = (array, size) => {
    const chunked = [];
    for (let i = 0; i < array?.length; i += size) {
      chunked.push(array.slice(i, i + size));
    }
    return chunked;
  };
  const handleBrandNavigation = (slug, e) => {
    e.preventDefault();
    if (slug) {
      // const queryParams = new URLSearchParams();
      navigate(`/car/listing?make=${slug}`);
    }
  };
  useEffect(() => {
    reduxState?.featuredBrands
      ? setBrands(reduxState?.featuredBrands)
      : fetchFeaturedBrands();
    // Detect and set the default language based on the browser or localStorage
    const detectedLanguage =
      localStorage.getItem("selectedLanguage") ||
      navigator.language.split("-")[0] ||
      "en";
    i18n.changeLanguage(detectedLanguage);
    document.documentElement.lang = detectedLanguage;
  }, [dispatch]);

  const brandChunks = chunkBrands(brands, 6);

  return (
    <div
      className="carouselCol pt-4 pt-sm-5 pb-sm-5"
      ref={(el) => (elementsRef.current[1] = el)}
      data-animation="animate__fadeInUp"
    >
      <div className="d-flex justify-content-between align-items-center mb-2">
        <h4 className="text-capitalize m-0">{t("our_brands")}</h4>
        <Link to="/brands" className="text-dark text-decoration-none">
          {t("show_all_brands")} <i className="bi bi-arrow-right ms-2"></i>
        </Link>
      </div>
      <Carousel
        className="custom-carousel-brand"
        selectedItem={0}
        showThumbs={false}
        autoPlay={false}
        infiniteLoop={false}
        // showArrows={false}
        showStatus={false}
        renderArrowPrev={(onClickHandler, hasPrev) =>
          hasPrev && (
            <button
              type="button"
              onClick={onClickHandler}
              className="custom-prev-arrow-brand"
            >
              <i className="bi bi-chevron-left custom-carousel-icon"></i>
            </button>
          )
        }
        renderArrowNext={(onClickHandler, hasNext) =>
          hasNext && (
            <button
              type="button"
              onClick={onClickHandler}
              className="custom-next-arrow-brand"
            >
              <i className="bi bi-chevron-right custom-carousel-icon"></i>
            </button>
          )
        }
      >
        {brandChunks.map((chunk, index) => (
          <div className="brand-carousel" key={index}>
            <div className="row g-2 g-sm-3">
              {chunk.map((brand) => (
                <div className="col-4 col-sm-4 col-lg-2" key={brand?.id}>
                  <Link
                    className="text-decoration-none text-dark"
                    onClick={(e) => handleBrandNavigation(brand?.slug, e)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="border rounded-10 bg-white text-center">
                      <img
                        src={`${api_url}/${brand?.logo?.path}`} // Appending api_url with logo path
                        alt={brand?.name}
                        style={{ width: "100px", height: "100px" }} // Adjust the size as per your design
                      />
                      <p>{brand?.name}</p>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default Brands;
