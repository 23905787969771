import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import WishlistCard from "../components/WishlistCard"; // Assuming you have a reusable ProductCard component
import "../styles/Wishlist.css"; // For additional styling
import ShopHeader from "../components/ShopHeader";
import RedirectToSignin from "../components/RedirectToSignin";
import RedirectToHome from "../components/RedirectToHome";
import PrimaryMobileHeader from "../components/PrimaryMobileHeader";
import SecondaryHeader from "../components/SecondaryHeader";
import Footer from "../components/Footer";
import { getWishList, removeWishList } from "../redux/auth/authActions";
import { Button } from "react-bootstrap";
import { BsGrid, BsList } from "react-icons/bs";
const wishlistItems = [
  {
    id: 1,
    title: "TOYOTA REPAIR SERVICE KIT IN UAE | CAR PARTS PRICE LIST ONLINE",
    // image: "path-to-image1", // Add the correct image paths
    price: "960",
    originalPrice: "1160",
    discount: "35%",
  },
  {
    id: 2,
    title: "TOYOTA REPAIR SERVICE KIT IN UAE | CAR PARTS PRICE LIST ONLINE",
    // image: "path-to-image2",
    price: "960",
    originalPrice: "1160",
    discount: "35%",
  },
  {
    id: 3,
    title: "TOYOTA REPAIR SERVICE KIT IN UAE | CAR PARTS PRICE LIST ONLINE",
    // image: "path-to-image2",
    price: "960",
    originalPrice: "1160",
    discount: "35%",
  },
  // Add more products as necessary
];

const Wishlist = () => {
  const dispatch = useDispatch();
  const [isMobile, setIsMobile] = useState(false);
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { wishList } = useSelector((state) => state.auth);
  const fetchData = async (page) => {
    try {
      setIsLoading(true);
      const response = await dispatch(getWishList());

      setProducts(response?.data);
      //   setTotalPages(Math.ceil(response?.data?.total / itemsPerPage));
    } catch (error) {
      console.error("API Error:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const removeFromWishlist = async (id) => {
    await dispatch(removeWishList(id));
    fetchData();
  };
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600); // Define the breakpoint for mobile
    };

    // Set initial value
    handleResize();
    wishList ? setProducts(wishList) : fetchData();

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <div style={{backgroundColor:"white"}}>
      <RedirectToSignin />
      <RedirectToHome />

      {!isMobile ? <SecondaryHeader /> : <PrimaryMobileHeader />}
      <div className="container" >
        <div className="wishlist-container">
          <ShopHeader />
          <hr />
          <div className="wishlist-header d-flex justify-content-between align-items-center mb-3">
            {/* Wishlist title and product count */}
            <div className="wishlist-title-container">
              <h2 className="wishlist-title mb-0">Wishlist</h2>
              <span className="product-count">
                {products?.length || 0} Products Found
              </span>
            </div>

            {/* View options and Sort button */}
            <div className="view-options d-flex align-items-center gap-2">
              <Button variant="outline-secondary" className="icon-button">
                <BsGrid /> {/* Grid view icon */}
              </Button>
              <Button variant="outline-secondary" className="icon-button">
                <BsList /> {/* List view icon */}
              </Button>
              <Button variant="outline-secondary">Show</Button>
              <Button variant="dark" style={{ backgroundColor: "#000740" }}>
                Sort By
              </Button>
            </div>
          </div>
          <div className="row g-2 g-sm-3 mb-5 wishlist-grid">
            {products && products.length > 0 ? (
              products.map((item) => (
                <div key={item.id} className="col-12 col-sm-6 col-lg-3">
                  <WishlistCard
                    productData={item}
                    btnText="Add To Cart"
                    isWishlist
                    removeFromWishlist={removeFromWishlist}
                  />
                </div>
              ))
            ) : (
              // Render empty placeholders if no products in wishlist
              <>
                {[...Array(4)].map((_, index) => (
                  <div key={index} className="col-12 col-sm-6 col-lg-3">
                    <div className="empty-card">
                      <p className="empty-text">Empty</p>
                    </div>
                  </div>
                ))}
                <div className="text-center mt-4">
                  <Button
                    variant="dark"
                    className="continue-shopping-btn"
                    style={{ backgroundColor: "#000740" }}
                  >
                    Continue Shopping
                  </Button>
                </div>
              </>
            )}
          </div>
        </div>
        {/* <div className="wishlist-container">
          <ShopHeader />
          <hr />
          <div className="breadcrumb">
            <span>Home /</span>
            <span className="current-page"> Wishlist</span>
          </div>
          <h2>Wishlist ({products?.length})</h2>

          <div className="row g-2 g-sm-3 mb-5 wishlist-grid">
            {products.map((item) => (
              <div key={item.id} className="col-12 col-sm-6 col-lg-3">
                <WishlistCard
                  productData={item}
                  btnText="Add To Cart"
                  isWishlist
                  removeFromWishlist={removeFromWishlist}
                />
              </div>
            ))}
          </div>
        </div> */}
      </div>
      <Footer />
    </div>
  );
};

export default Wishlist;
