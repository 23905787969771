import React, { useEffect, useState } from "react";
import { Modal, Table, Tag, Descriptions } from "antd";

const TrackingModal = ({ visible, onClose, trackingData, details }) => {
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600); // Define the breakpoint for mobile
    };

    // Set initial value
    handleResize();

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const columns = [
    {
      title: "Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at) => new Date(created_at).toLocaleDateString(), // Format date
    },
    {
      title: "Time",
      dataIndex: "created_at",
      key: "time",
      render: (created_at) => new Date(created_at).toLocaleTimeString(), // Format time
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => {
        let color = "";
        switch (status) {
          case "CREATED":
            color = "orange";
            break;
          case "RECEIVED":
            color = "blue";
            break;
          case "REVIEWED":
            color = "yellow";
            break;
          case "REPLIED":
            color = "cyan";
            break;
          case "CONTACTED":
            color = "purple";
            break;
          case "RESOLVED":
            color = "green";
            break;
          case "CLOSED":
            color = "red";
            break;
          case "CANCELLED":
            color = "gray";
            break;
          case "VERIFIED":
            color = "lime";
            break;
          case "PENDING":
            color = "gold";
            break;
          case "APPROVED":
            color = "green";
            break;
          case "REJECTED":
            color = "red";
            break;
          default:
            color = "default";
        }
        return <Tag color={color}>{status}</Tag>;
      },
    },
    {
      title: "Comment",
      dataIndex: "comment",
      key: "comment",
    },
  ];

  return (
    <Modal
      title="Details"
      visible={visible}
      onCancel={onClose}
      footer={null}
      width={isMobile ? "95%" : 800} // Use full width for mobile devices
      style={{ maxWidth: "100%" }} // Ensure the modal doesn't exceed the screen width
    >
      <Descriptions
        title=""
        bordered
        column={isMobile ? 1 : 2} // Switch to 1 column on mobile, 2 columns on larger screens
        className="mb-3"
      >
        {details?.code && (
          <Descriptions.Item label="Code">{details?.code}</Descriptions.Item>
        )}
        {details?.agent_name && (
          <Descriptions.Item label="Agent Name">
            {details?.agent_name}
          </Descriptions.Item>
        )}
        {details?.agent_phone && (
          <Descriptions.Item label="Agent Phone">
            {details?.agent_phone}
          </Descriptions.Item>
        )}
        {details?.client_name && (
          <Descriptions.Item label="Client Name">
            {details?.client_name}
          </Descriptions.Item>
        )}
        {details?.client_phone && (
          <Descriptions.Item label="Client Phone">
            {details?.client_phone}
          </Descriptions.Item>
        )}
        {details?.client_address && (
          <Descriptions.Item label="Client Address">
            {details?.client_address}
          </Descriptions.Item>
        )}
        {details?.car_make?.name && (
          <Descriptions.Item label="Car Make">
            {details?.car_make?.name}
          </Descriptions.Item>
        )}
        {details?.car_model?.name && (
          <Descriptions.Item label="Car Model">
            {details?.car_model?.name}
          </Descriptions.Item>
        )}
        {details?.year && (
          <Descriptions.Item label="Year">{details?.year}</Descriptions.Item>
        )}
        {details?.color && (
          <Descriptions.Item label="Color">{details?.color}</Descriptions.Item>
        )}
        {details?.quantity && (
          <Descriptions.Item label="Quantity">
            {details?.quantity}
          </Descriptions.Item>
        )}
        {details?.destination && (
          <Descriptions.Item label="Destination">
            {details?.destination}
          </Descriptions.Item>
        )}
        {details?.agent_targeted_amount && (
          <Descriptions.Item label="Agent Targeted Amount">
            {details?.agent_targeted_amount}
          </Descriptions.Item>
        )}
        {details?.agent_commission_amount && (
          <Descriptions.Item label="Agent Commission">
            {details?.agent_commission_amount}
          </Descriptions.Item>
        )}

        {/* Additional Inquiry details */}
        {details?.first_name && (
          <Descriptions.Item label="First Name">
            {details?.first_name}
          </Descriptions.Item>
        )}
        {details?.last_name && (
          <Descriptions.Item label="Last Name">
            {details?.last_name}
          </Descriptions.Item>
        )}
        {details?.email && (
          <Descriptions.Item label="Email">{details?.email}</Descriptions.Item>
        )}
        {details?.message && (
          <Descriptions.Item label="Message">
            {details?.message}
          </Descriptions.Item>
        )}
        {details?.created_at && (
          <Descriptions.Item label="Inquiry Created At">
            {new Date(details?.created_at).toISOString().split("T")[0]}
          </Descriptions.Item>
        )}
        {details?.user?.name && (
          <Descriptions.Item label="User Name">
            {details?.user?.name}
          </Descriptions.Item>
        )}
        {details?.user?.email && (
          <Descriptions.Item label="User Email">
            {details?.user?.email}
          </Descriptions.Item>
        )}
        {details?.user?.phone && (
          <Descriptions.Item label="User Phone">
            {details?.user?.phone}
          </Descriptions.Item>
        )}
      </Descriptions>

      <div className="table-responsive">
        <Table
          dataSource={trackingData}
          columns={columns}
          rowKey={(record) => record.id}
        />
      </div>
    </Modal>
  );
};

export default TrackingModal;
