import "./SalesMen.css";

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { signOutAction, getServiceKits } from "../redux/auth/authActions";
import { Navigate } from "react-router-dom";
import Footer from "../components/Footer";
import RedirectToHome from "../components/RedirectToHome";
import RedirectToSignin from "../components/RedirectToSignin";
import ProductGrid from "../components/ProductGrid";
import CardGrid from "../components/CardGrid";
// import productImg from '../assets/images/p-img.png';
import productImg from "../assets/images/ss4.png";
// import modelLogo from '../assets/images/toyota.png';
import modelLogo from "../assets/images/blog91.jpg";
import SecondaryHeader from "../components/SecondaryHeader";
import PrimaryMobileHeader from "../components/PrimaryMobileHeader";
import SearchFilter from "../components/SearchFilter";
import FilterBar from "../components/FilterBar";
import ShopHeader from "../components/ShopHeader";
import ClipLoader from "react-spinners/ClipLoader";
import ReactPaginate from "react-paginate";
import { removeWishList, cartUpdate } from "../redux/auth/authActions";
import ShopSearchFilter from "../components/ShopSearchFilter";
import { RotatingLines } from "react-loader-spinner";
import loaderLogo from "../assets/images/CM LOGO DARK BLUE.png";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
const ServiceKits = () => {
  const dispatch = useDispatch();
  const [isMobile, setIsMobile] = useState(false);
  const { isAuthenticated } = useSelector((state) => state.auth);
  const { t } = useTranslation();
  const btnText = `${t("add_to_cart")}`;
  const [isLoading, setIsLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const reduxState = useSelector((state) => state.auth);
  const cartItems = reduxState?.cartItems;
  const wishList = reduxState?.wishList;
  const itemsPerPage = 20;
  const handleSignOut = () => {
    dispatch(signOutAction());
  };

  const productss = [
    {
      id: 1,
      title: "Ac Filter",
      image: productImg,
      description: "4.0 D5 PowerPulse Momentum Fdr AV",
      price: "125",
      color: "White",
    },
    {
      id: 2,
      title: "Ac Filter",
      image: productImg,
      description: "4.0 D5 PowerPulse Momentum Fdr AV",
      price: "125",
      color: "White",
    },
    {
      id: 3,
      title: "Ac Filter",
      image: productImg,
      description: "4.0 D5 PowerPulse Momentum Fdr AV",
      price: "125",
      color: "White",
    },
    {
      id: 4,
      title: "Ac Filter",
      image: productImg,
      description: "4.0 D5 PowerPulse Momentum Fdr AV",
      price: "125",
      color: "White",
    },
    {
      id: 5,
      title: "Ac Filter",
      image: productImg,
      description: "4.0 D5 PowerPulse Momentum Fdr AV",
      price: "125",
      color: "White",
    },
    {
      id: 6,
      title: "Ac Filter",
      image: productImg,
      description: "4.0 D5 PowerPulse Momentum Fdr AV",
      price: "125",
      color: "White",
    },
    {
      id: 7,
      title: "Ac Filter",
      image: productImg,
      description: "4.0 D5 PowerPulse Momentum Fdr AV",
      price: "125",
      color: "White",
    },
  ];
  const wishlist = async (id) => {
    // Optimistically update the state first
    const updatedProducts = products?.map((product) => {
      if (product.id === id) {
        return { ...product, is_in_wishlist: !product.is_in_wishlist };
      }
      return product;
    });

    // Set the updated product list before calling the API
    setProducts(updatedProducts);

    try {
      // Call the API to update the wishlist
      await dispatch(removeWishList(id));
    } catch (error) {
      console.error("Error updating wishlist:", error);

      // If there's an error, revert the optimistic update
      const revertedProducts = products?.map((product) => {
        if (product.id === id) {
          return { ...product, is_in_wishlist: !product.is_in_wishlist };
        }
        return product;
      });

      setProducts(revertedProducts);
    }
  };
  const updateCart = async (id, method) => {
    const data = {
      product_id: id,
      quantity: "1",
      action: method, // Either "add" or "remove"
    };
    const response = await dispatch(cartUpdate(data));
  };
  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await dispatch(getServiceKits());

      setProducts(response?.data?.result?.data);
    } catch (error) {
      console.error("API Error:", error);
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  const handlePageClick = (data) => {
    const selectedPage = data.selected + 1;
    setCurrentPage(selectedPage);
    fetchData(selectedPage);
  };
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600); // Define the breakpoint for mobile
    };
    const detectedLanguage =
      localStorage.getItem("selectedLanguage") ||
      navigator.language.split("-")[0] ||
      "en";
    i18n.changeLanguage(detectedLanguage);
    document.documentElement.lang = detectedLanguage;
    fetchData();

    // Set initial value
    handleResize();

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener
    return () => window.removeEventListener("resize", handleResize);
  }, [dispatch]);
  const updateProductCartAndWishlistStatus = () => {
    if (!products & !cartItems & !wishList) return;

    const updatedProducts = products?.map((product) => {
      // Check if the product is in the cart
      const isInCart = cartItems?.some(
        (item) => item.product_id === product.id
      );

      // Check if the product is in the wishlist
      const isInWishlist = wishList?.some(
        (item) => item.product_id === product.id
      );

      return {
        ...product,
        is_in_cart: isInCart,
        is_in_wishlist: isInWishlist, // Update both is_in_cart and is_in_wishlist
      };
    });

    setProducts(updatedProducts);
  };
  useEffect(() => {
    // Call this effect when cartItems or wishList changes
    updateProductCartAndWishlistStatus();
  }, [cartItems, wishList]);

  return (
    <div className="bg-white">
      <RedirectToSignin />
      <RedirectToHome />
      {!isMobile ? <SecondaryHeader /> : <PrimaryMobileHeader />}

      <div className="container">
        <div className="text-center pt-3 pb-3">
          <ShopHeader />
          <img src={modelLogo} alt="Logo" style={{ maxWidth: "100%", borderRadius:"20px" }} />
          <FilterBar className="mt-3" />
          <ShopSearchFilter />
          <h3 className="text-uppercase fw-bold">{t("service_kits")}</h3>
        </div>
        {isLoading ? (
          <div className="d-flex justify-content-center align-items-center vh-100">
            {/* <ClipLoader size={50} color={"#123abc"} loading={isLoading} /> */}
            <RotatingLines
              strokeColor="#000740"
              strokeWidth="4"
              animationDuration="0.75"
              width="96"
              visible={true}
            />

            <img
              src={loaderLogo}
              style={{ width: "250px", height: "250px" }}
            ></img>
          </div>
        ) : products?.length === 0 ? (
          <div className="d-flex justify-content-center align-items-center vh-100">
            <h3>{t("no_products")}</h3>
          </div>
        ) : (
          <>
            <CardGrid
              products={products}
              btnText={btnText}
              fromPage={"accessories"}
              removeFromWishlist={wishlist}
            />
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              breakLabel={"..."}
              pageCount={totalPages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              onPageChange={handlePageClick}
              containerClassName={"pagination justify-content-center"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
            />
          </>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default ServiceKits;
