// ScrollToTop.js
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = ({ children }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    // Use smooth scrolling to avoid the abrupt jump
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Enable smooth scrolling
    });
  }, [pathname]);

  return children; // Return the wrapped children (your entire app)
};

export default ScrollToTop;
