import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { signOutAction } from "../redux/auth/authActions";
import { Navigate, Link } from "react-router-dom";
import SecondaryHeader from "../components/SecondaryHeader";
import Footer from "../components/Footer";
import banner from "../assets/images/bank-detail.png";
import RedirectToSignin from "../components/RedirectToSignin";
import RedirectToHome from "../components/RedirectToHome";
import PrimaryMobileHeader from "../components/PrimaryMobileHeader";
import { jsPDF } from "jspdf";
import logo from "../assets/images/white-logo.png"

const BankAccountDetail = () => {
  const dispatch = useDispatch();
  const [isMobile, setIsMobile] = useState(false);
  const { isAuthenticated } = useSelector((state) => state.auth);

  const handleSignOut = () => {
    dispatch(signOutAction());
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600); // Define the breakpoint for mobile
    };

    // Set initial value
    handleResize();

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);

//   const generatePDF = () => {
//     const doc = new jsPDF();

//     // Adding a title
//     doc.setFontSize(18);
//     doc.text("Bank Account Details", 20, 20);

//     // Adding bank details
//     doc.setFontSize(12);
//     doc.text("Bank Website: https://www.emiratesnbd.com/en", 20, 40);
//     doc.text("Bank Address: Emirates NBD Building, Al Barsha 2, Dubai", 20, 50);
//     doc.text("Account Name: Cruise Motors FZE", 20, 60);
//     doc.text("Address: Dubai, United Arab Emirates", 20, 70);
//     doc.text("SWIFT Code: NBD741021", 20, 80);

//     // Return the blob URL for the generated PDF
//     return doc.output("blob");
//   };
const generatePDF = () => {
    const doc = new jsPDF();
  
    // Add brand logo (if you have a base64 image or a path to an image)
    const img = new Image();
    img.src = logo; // Assuming logo is imported
    doc.addImage(img, 'PNG', 15, 10, 50, 20); // Adjust position and size as necessary
  
    // Add Header (Company Name or Title)
    doc.setFontSize(22);
    doc.text('Cruise Motors FZE - Bank Account Details', 40, 30); // Position it relative to logo
  
    // Add some horizontal line for a professional look
    doc.setLineWidth(0.5);
    doc.line(15, 35, 195, 35); // Draw a line across the page
  
    // Add bank details (content)
    doc.setFontSize(16);
    doc.text('Bank Website:', 15, 50);
    doc.setFontSize(12);
    doc.text('https://www.emiratesnbd.com/en', 55, 50);
  
    doc.setFontSize(16);
    doc.text('Bank Address:', 15, 60);
    doc.setFontSize(12);
    doc.text('Emirates NBD Building, Al Barsha 2, Dubai', 55, 60);
  
    doc.setFontSize(16);
    doc.text('Account Name:', 15, 70);
    doc.setFontSize(12);
    doc.text('Cruise Motors FZE', 55, 70);
  
    doc.setFontSize(16);
    doc.text('SWIFT Code:', 15, 80);
    doc.setFontSize(12);
    doc.text('NBD741021', 55, 80);
  
    // Add Footer (with page number or any additional text)
    doc.setFontSize(10);
    doc.text('Page 1', 105, 290); // Centered page number at the bottom
    doc.text('Cruise Motors FZE © 2024 - All rights reserved.', 15, 290);
  
    // Save or Return Blob URL
    return doc.output('blob');
  };

  const downloadPDF = () => {
    const pdfBlob = generatePDF();
    const pdfUrl = URL.createObjectURL(pdfBlob);
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.download = "cruise-motors-bank-account-details.pdf";
    link.click();
  };

  const sharePDF = async () => {
    const pdfBlob = generatePDF();
    const file = new File([pdfBlob], "bank-account-details.pdf", {
      type: "application/pdf",
    });

    // Check if the Web Share API is supported
    if (navigator.canShare && navigator.canShare({ files: [file] })) {
      try {
        await navigator.share({
          title: "Bank Account Details",
          text: "Here are the bank account details for Cruise Motors FZE.",
          files: [file],
        });
      } catch (error) {
        console.error("Error sharing:", error);
      }
    } else {
      alert("Web Share API is not supported on this device.");
    }
  };

  return (
    <div className="bg-white">
      <RedirectToSignin />
      <RedirectToHome />
      {!isMobile ? <SecondaryHeader /> : <PrimaryMobileHeader />}
      <div className="container">
        <div className="servicesPageList mt-4 pb-5">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-7">
              <img src={banner} alt="Banner" className="img-fluid mb-5" />
              <h2 className="mb-4 text-center">Bank Account Details</h2>
              <div className="card rounded-10 mb-5">
                <div className="card-body">
                  <table className="table">
                    <tbody>
                      {/* First row for the buttons, aligned to the right */}
                      <tr>
                        <td colSpan="2">
                          <div className="d-flex justify-content-end">
                            <Link
                              to="#"
                              onClick={downloadPDF}
                              className="font-20 me-3"
                            >
                              <i className="bi bi-cloud-download"></i>
                            </Link>
                            <Link to="#" onClick={sharePDF} className="font-20">
                              <i className="bi bi-share"></i>
                            </Link>
                          </div>
                        </td>
                      </tr>

                      {/* Bank details rows */}
                      <tr>
                        <td>Bank Website </td>
                        <td>https://www.emiratesnbd.com/en</td>
                      </tr>
                      <tr>
                        <td>Bank Address </td>
                        <td>Emirates NBD Building, Al Barsha 2, Dubai</td>
                      </tr>
                      <tr>
                        <td>Account Name</td>
                        <td>Cruise Motors FZE</td>
                      </tr>
                      <tr>
                        <td>Address</td>
                        <td>Dubai, United Arab Emirates</td>
                      </tr>
                      <tr>
                        <td>SWIFT Code</td>
                        <td>NBD741021</td>
                      </tr>
                    </tbody>
                  </table>

                  <Link className="theme-color" to="/terms-and-condition">
                    Terms and Conditions{" "}
                  </Link>
                </div>
              </div>

              <p className="text-muted">
                Bank account details related to the purchase, sale, or shipment
                of a car are a crucial aspect of financial transactions,
                ensuring that payments are securely processed. When dealing with
                a car transaction, whether purchasing a vehicle from a
                dealership, making a payment for shipping services, or settling
                fees related to car maintenance, having accurate and complete
                bank account information is essential. This typically includes
                the account holder's name, the bank name, the account number,
                the bank's routing number, and sometimes the SWIFT code for
                international transactions. These details ensure that funds are
                transferred safely and promptly between parties.
              </p>

              <p className="text-muted">
                In the context of buying or selling a car, bank account details
                play a central role in the transaction. Buyers need the seller's
                or dealership’s account information to transfer payment, while
                sellers need to provide their bank details to receive the funds
                securely. This is especially important in cases where large sums
                of money are involved, such as with high-value vehicles. Both
                parties must ensure that the details provided are accurate to
                avoid any delays or misdirected payments, which could disrupt
                the transaction process.
              </p>

              <p className="text-muted">
                For shipping a car, bank account details are often required when
                securing payment for the logistics services. Companies handling
                the transportation of vehicles will provide their account
                information to facilitate the payment of shipping fees, which
                might include deposits, full payments, or additional charges for
                insurance and expedited services. These payments ensure that the
                car is shipped without delay and in accordance with the
                agreed-upon terms.
              </p>

              <p className="text-muted">
                It is also essential to handle bank account information with
                confidentiality and care, ensuring that sensitive financial
                details are not shared insecurely.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default BankAccountDetail;
