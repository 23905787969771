import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { signOutAction, submitTendorForm } from "../redux/auth/authActions";
import { Navigate, Link } from "react-router-dom";
import SecondaryHeader from "../components/SecondaryHeader";
import Footer from "../components/Footer";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { UploadOutlined } from "@ant-design/icons";
// import type { UploadProps } from 'antd';
import { Button, message, Upload } from "antd";
import banner from "../assets/images/tendors.png";
import InputField from "../components/InputField";
import RedirectToSignin from "../components/RedirectToSignin";
import RedirectToHome from "../components/RedirectToHome";
import PrimaryMobileHeader from "../components/PrimaryMobileHeader";
import SuccessForm from "./SuccessForm";

import { useTranslation } from "react-i18next";
import i18n from "../i18n";
// const props: UploadProps = {
const props = {
  name: "file",
  action: "https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload",
  headers: {
    authorization: "authorization-text",
  },
  onChange(info) {
    if (info.file.status !== "uploading") {
    }
    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
};

const TendorsPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isMobile, setIsMobile] = useState(false);
  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const [formData, setFormData] = useState({
    company_name: "",
    person_name: "",
    person_email: "",
    phone_number: "",
    person_designation: "",
    company_address: "",
    comments: "",
  });
  const userData = user?.data?.user || {};
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [refId, setRefId] = useState("");

  const [uploadedFiles, setUploadedFiles] = useState({
    tradeLicense: [],
    vehicleSpecs: [],
    purchaseOrder: [],
    letterOfIntent: [],
  });

  const [fileList, setFileList] = useState({
    tradeLicense: [],
    vehicleSpecs: [],
    purchaseOrder: [],
    letterOfIntent: [],
  });

  const [errors, setErrors] = useState({});
  const [charCount, setCharCount] = useState(0);
  const charLimit = 200;
  const allowedFileTypes = [
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "image/jpeg",
    "image/png",
  ];
  const validate = () => {
    let formErrors = {};

    if (!formData.company_name)
      formErrors.company_name = t("errors.company_name");
    if (!formData.person_name) formErrors.person_name = t("errors.person_name");
    if (!formData.person_email)
      formErrors.person_email = t("errors.person_email_required");
    if (formData.person_email && !/\S+@\S+\.\S+/.test(formData.person_email)) {
      formErrors.person_email = t("errors.person_email_invalid");
    }
    if (!formData.phone_number)
      formErrors.phone_number = t("errors.phone_number");
    if (!formData.person_designation)
      formErrors.person_designation = t("errors.person_designation");

    // Validate file uploads
    if (fileList.tradeLicense.length === 0)
      formErrors.tradeLicense = t("errors.trade_license");
    if (fileList.vehicleSpecs.length === 0)
      formErrors.vehicleSpecs = t("errors.vehicle_specs");
    // if (fileList.purchaseOrder.length === 0)
    //   formErrors.purchaseOrder = "Purchase Order is required";
    // if (fileList.letterOfIntent.length === 0)
    //   formErrors.letterOfIntent = "Letter Of Intent is required";
    // if (!uploadedFiles.tradeLicense) formErrors.tradeLicense = 'Company Trade License is required';
    // if (!uploadedFiles.vehicleSpecs) formErrors.vehicleSpecs = 'Vehicle Technical Specification Sheet is required';

    return formErrors;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "comments" && value.length > charLimit) {
      return; // Prevent exceeding character limit
    }

    setFormData({
      ...formData,
      [name]: value,
    });

    if (name === "comments") {
      setCharCount(value.length); // Update character count for comments
    }
  };

  const handlePhoneChange = (value) => {
    setFormData({
      ...formData,
      phone_number: value,
    });
  };
  const beforeUpload = (file) => {
    const isAllowedFileType = allowedFileTypes.includes(file.type);

    if (!isAllowedFileType) {
      message.error(
        `${file.name} is not a valid file type. Only PDF, Word documents, and images are allowed.`
      );
    }

    return isAllowedFileType ? false : Upload.LIST_IGNORE; // Prevent upload if file type is not allowed
  };

  const handleFileChange = ({ fileList: newFileList }, fileType) => {
    setFileList({
      ...fileList,
      [fileType]: newFileList,
    });
  };
  const handleUploadChange = (info, fileType) => {
    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);
      setUploadedFiles({
        ...uploadedFiles,
        [fileType]: info.file,
      });
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
      setUploadedFiles({
        ...uploadedFiles,
        [fileType]: null,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formErrors = validate();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }
    const data = new FormData(); // Create FormData object
    data.append("company_name", formData.company_name);
    data.append("person_name", formData.person_name);
    data.append("person_email", formData.person_email);
    data.append("phone_number", formData.phone_number);
    data.append("person_designation", formData.person_designation);
    data.append("company_address", formData.company_address);
    data.append("comments", formData.comments);

    // Append files to form data
    let attachments = [];

    // Add files to attachments array with name and file
    if (fileList.tradeLicense.length > 0) {
      attachments.push({
        name: "Company Trade License",
        file: fileList.tradeLicense[0].originFileObj,
      });
    }
    if (fileList.purchaseOrder.length > 0) {
      attachments.push({
        name: "Purchase Order",
        file: fileList.purchaseOrder[0].originFileObj,
      });
    }
    if (fileList.vehicleSpecs.length > 0) {
      attachments.push({
        name: "Vehicle Technical Spec Sheet",
        file: fileList.vehicleSpecs[0].originFileObj,
      });
    }
    if (fileList.letterOfIntent.length > 0) {
      attachments.push({
        name: "Letter of Intention",
        file: fileList.letterOfIntent[0].originFileObj,
      });
    }

    attachments.forEach((attachment) => {
      data.append(`attachments[][name]`, attachment.name); // Append the name of the attachment
      data.append(`attachments[][file]`, attachment.file); // Append the file of the attachment
    });

    const response = await dispatch(submitTendorForm(data));
    if (response?.code == 200) {
      setRefId(response?.data?.code);
      setFormSubmitted(true);
      message.success("Submitted successfully!");
    } else {
      message.error(response?.message || "Submission failed!");
    }
  };

  const handleSignOut = () => {
    dispatch(signOutAction());
  };
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600); // Define the breakpoint for mobile
    };
    const detectedLanguage =
      localStorage.getItem("selectedLanguage") ||
      navigator.language.split("-")[0] ||
      "en";
    i18n.changeLanguage(detectedLanguage);
    document.documentElement.lang = detectedLanguage;
    // Set initial value
    handleResize();

    // Add event listener
    window.addEventListener("resize", handleResize);
    if (userData) {
      // If user is a company, fill both user and company details
      if (userData?.company) {
        setFormData((prevData) => ({
          ...prevData,
          company_name: userData.company.name || "", // Fill company name
          company_address: userData.company.address || "", // Fill company address
          person_name: userData.name || "", // Fill person name from userData
          person_email: userData.email || "", // Fill person email from userData
          phone_number: userData.phone || "", // Fill person phone from userData
          person_designation: userData.designation || "", // Fill person designation
        }));
      } else {
        // If user is not a company, fill only personal details
        setFormData((prevData) => ({
          ...prevData,
          person_name: userData.name || "", // Fill person name from userData
          person_email: userData.email || "", // Fill person email from userData
          phone_number: userData.phone || "", // Fill person phone from userData
          person_designation: userData.designation || "", // Fill person designation
        }));
      }
    }
    // Cleanup the event listener
    return () => window.removeEventListener("resize", handleResize);

    // setFormData();
  }, []);

  return (
    <div className="bg-white">
      <RedirectToSignin />
      <RedirectToHome />
      {!isMobile ? <SecondaryHeader /> : <PrimaryMobileHeader />}
      <div className="container">
        <div className="tendorsPage mt-4 pb-5">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-7">
              <img src={banner} alt="Banner" className="img-fluid mb-5" />
              {formSubmitted ? (
                <SuccessForm
                  refId={refId}
                  resetForm={() => {
                    setFormSubmitted(false);
                  }}
                />
              ) : (
                <form onSubmit={handleSubmit}>
                  <div className="card rounded-10 bg-white border-0 mb-3">
                    <div className="card-body p-5">
                      <h2 className="mb-4  text-center">{t("tender")}</h2>
                      <div className="row g-3 mb-4 text-start">
                        <div className="col-12 col-sm-6">
                          <InputField
                            placeholder={`${t("company_name")}*`}
                            type="text"
                            name="company_name"
                            value={formData.company_name}
                            onChange={handleChange}
                            customClass={
                              errors.company_name ? "is-invalid" : ""
                            }
                          />
                          {errors.company_name && (
                            <div className="text-danger font-13">
                              {errors.company_name}
                            </div>
                          )}
                        </div>
                        <div className="col-12 col-sm-6">
                          <InputField
                            placeholder={`${t("person_name")}*`}
                            type="text"
                            name="person_name"
                            value={formData.person_name}
                            onChange={handleChange}
                            customClass={errors.person_name ? "is-invalid" : ""}
                          />
                          {errors.person_name && (
                            <div className="text-danger font-13">
                              {errors.person_name}
                            </div>
                          )}
                        </div>
                        <div className="col-12 col-sm-6">
                          <InputField
                            placeholder={`${t("person_email")}*`}
                            type="email"
                            name="person_email"
                            value={formData.person_email}
                            onChange={handleChange}
                            customClass={
                              errors.person_email ? "is-invalid" : ""
                            }
                          />
                          {errors.person_email && (
                            <div className="text-danger font-13">
                              {errors.person_email}
                            </div>
                          )}
                        </div>
                        <div className="col-12 col-sm-6">
                          <div className="text-start">
                            <PhoneInput
                              country={"ae"}
                              excludeCountries={["il"]}
                              name="phone_number"
                              value={formData.phone_number}
                              className="w-100"
                              onChange={handlePhoneChange}
                              inputClass={`form-control ${
                                errors.phone_number ? "is-invalid" : ""
                              }`}
                            />
                            {errors.phone_number && (
                              <div className="text-danger font-13">
                                {errors.phone_number}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-12 col-sm-6">
                          <InputField
                            placeholder={`${t("person_designation")}*`}
                            type="text"
                            name="person_designation"
                            value={formData.person_designation}
                            onChange={handleChange}
                            customClass={
                              errors.person_designation ? "is-invalid" : ""
                            }
                          />
                          {errors.person_designation && (
                            <div className="text-danger font-13">
                              {errors.person_designation}
                            </div>
                          )}
                        </div>
                        <div className="col-12 col-sm-6">
                          <InputField
                            placeholder="Company Address"
                            type="text"
                            name="company_address"
                            value={formData.company_address}
                            onChange={handleChange}
                            customClass={
                              errors.company_address ? "is-invalid" : ""
                            }
                          />
                          {errors.company_address && (
                            <div className="text-danger font-13">
                              {errors.company_address}
                            </div>
                          )}
                        </div>
                      </div>
                      <h5 className="text-start mb-3 d-flex justify-content-start align-items-center">
                        <i className="bi bi-paperclip me-2"></i>{" "}
                        <span>{t("attatchments")}</span>
                      </h5>
                      <div className="row g-3 mb-4 text-start">
                        <div className="col-12 col-sm-6">
                          {/* <Upload
                          name="file"
                          action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                          onChange={(info) =>
                            handleUploadChange(info, "tradeLicense")
                          }
                          className="custom-upload"
                        >
                          <Button icon={<UploadOutlined />}>
                            Company Trade License *
                          </Button>
                        </Upload> */}
                          <Upload
                            fileList={fileList.tradeLicense}
                            beforeUpload={beforeUpload}
                            onChange={(file) =>
                              handleFileChange(file, "tradeLicense")
                            }
                            className="custom-upload"
                          >
                            <Button icon={<UploadOutlined />}>
                              {`${t("cmp_trade_license")}*`}
                            </Button>
                          </Upload>
                          {errors.tradeLicense && (
                            <div className="text-danger font-13">
                              {errors.tradeLicense}
                            </div>
                          )}
                        </div>
                        <div className="col-12 col-sm-6">
                          {/* <Upload
                          name="file"
                          action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                          onChange={(info) =>
                            handleUploadChange(info, "vehicleSpecs")
                          }
                          className="custom-upload"
                        >
                          <Button icon={<UploadOutlined />}>
                            Vehicle Technical Specification Sheet *
                          </Button>
                        </Upload> */}
                          <Upload
                            fileList={fileList.vehicleSpecs}
                            beforeUpload={beforeUpload}
                            onChange={(file) =>
                              handleFileChange(file, "vehicleSpecs")
                            }
                            className="custom-upload"
                          >
                            <Button icon={<UploadOutlined />}>
                              {`${t("vtss")}*`}
                            </Button>
                          </Upload>
                          {errors.vehicleSpecs && (
                            <div className="text-danger font-13">
                              {errors.vehicleSpecs}
                            </div>
                          )}
                        </div>
                        <div className="col-12 col-sm-6">
                          {/* <Upload
                          name="file"
                          action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                          onChange={(info) =>
                            handleUploadChange(info, "purchaseOrder")
                          }
                          className="custom-upload"
                        >
                          <Button icon={<UploadOutlined />}>
                            Purchase Order
                          </Button>
                        </Upload> */}
                          <Upload
                            fileList={fileList.purchaseOrder}
                            beforeUpload={beforeUpload}
                            onChange={(file) =>
                              handleFileChange(file, "purchaseOrder")
                            }
                            className="custom-upload"
                          >
                            <Button icon={<UploadOutlined />}>
                              {`${t("purchase_order")}*`}
                            </Button>
                          </Upload>
                        </div>
                        <div className="col-12 col-sm-6">
                          {/* <Upload
                          name="file"
                          action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                          onChange={(info) =>
                            handleUploadChange(info, "letterOfIntent")
                          }
                          className="custom-upload"
                        >
                          <Button icon={<UploadOutlined />}>
                            Letter of Intention
                          </Button>
                        </Upload> */}
                          <Upload
                            fileList={fileList.letterOfIntent}
                            beforeUpload={beforeUpload}
                            onChange={(file) =>
                              handleFileChange(file, "letterOfIntent")
                            }
                            className="custom-upload"
                          >
                            <Button icon={<UploadOutlined />}>
                              {`${t("loi")}*`}
                            </Button>
                          </Upload>
                        </div>
                      </div>
                      <h5 className="text-start mb-3 d-flex justify-content-start align-items-center">
                        <i className="bi bi-chat me-2"></i>{" "}
                        <span>{t("comments")}</span>
                      </h5>
                      <div className="row g-3 mb-4 text-start">
                        <div className="col-12 col-sm-12">
                          <InputField
                            fieldType="textarea"
                            name="comments"
                            value={formData.comments}
                            placeholder={t("wrt_cmnts")}
                            onChange={handleChange}
                            rows={4}
                            customClass="custom-textarea-comments"
                          />
                          <small className="text-muted">
                            {charCount}/{charLimit} {t("characters")}
                          </small>
                        </div>
                      </div>
                      <div className="row g-3 text-center">
                        <div className="col-12 col-sm-12">
                          <button
                            type="submit"
                            className="btn btn-primary btn-lg ps-5 pe-5 font-15 mb-3"
                          >
                            {t("submit")}
                          </button>
                          <p className="m-0 text-muted font-15">
                            {t("respond_time")}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              )}
              {/* <!-- New Section: Tenders --> */}
              <div class="container-text">
                <div class="fade-container">
                  <div class="scrollable-section">
                    <div class="scrollable-content ms-2">
                      <div class="row">
                        {/* <!-- Tenders Section --> */}
                        <div class="col-12 pb-3">
                          {/* <h3 class="fw-bold">Tenders</h3> */}
                          <p>
                            Welcome to the future with Cruise Motors! Our new
                            online platform changes the old way of buying cars
                            for business into a simple, digital-first method. As
                            a top name globally in car imports and exports, we
                            make sure all your vehicle tender needs are met
                            quickly and effectively.
                          </p>
                          <p>
                            Our straightforward online form makes it easy to
                            submit your tenders. The platform lets you quickly
                            add detailed vehicle specs, your company
                            information, and any necessary documents like trade
                            licenses and technical sheets without any trouble.
                          </p>
                          <p>
                            Whether you’re looking for trucks, luxury cars, or
                            special vehicles, Cruise Motors has a vast inventory
                            and network to handle tenders of any size. We can
                            meet your buying needs anywhere in the world because
                            of our strong presence in the international car
                            market.
                          </p>
                          <p>
                            We are proud of our top-notch service. We respond to
                            tender questions in about 30–50 minutes, making sure
                            your buying process moves along fast and smoothly.
                            At Cruise Motors, making customers happy and keeping
                            things running efficiently is our priority.
                          </p>
                          <p>
                            Use our top-tier tender management system. From when
                            you submit to when we fulfill your order, our
                            platform ensures a clear, straightforward, and
                            efficient tender experience.
                          </p>

                          <div class="d-flex align-items-start mb-4">
                            <i class="bi bi-file-earmark-text-fill fs-1 me-3"></i>
                            <div>
                              <h5 class="fw-bold">Start Your Tender</h5>
                              <p>
                                Just fill out the form below to get started. Our
                                system handles all information securely,
                                ensuring quick and accurate processing of your
                                submission.
                              </p>
                            </div>
                          </div>

                          <div class="d-flex align-items-start mb-4">
                            <i class="bi bi-gear fs-1 me-3"></i>
                            <div>
                              <h5 class="fw-bold">See the Benefits</h5>
                              <p>
                                Our process doesn’t just save time; it also
                                makes your car buying more accurate and
                                effective.
                              </p>
                            </div>
                          </div>

                          <p>
                            Join many happy clients who rely on Cruise Motors
                            for their car buying needs. Our modern platform is
                            your answer to updating how you handle tenders,
                            giving you speed, efficiency, and unmatched quality
                            in the automotive industry.
                          </p>
                          <p>
                            Submit your tender today and see the difference with
                            Cruise Motors. Start here and move your buying
                            strategy forward with success.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TendorsPage;
