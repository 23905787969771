import './SignInPage.css';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { signInAction } from '../redux/auth/authActions';
import { Navigate, Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LanguageSelect from '../components/LanguageSelect';
import i18n from '../i18n'; // Import your i18n configuration
import logo from '../assets/images/blue-logo.png'; // Import the image file
// import Flag from 'react-flags-select';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import RedirectToHome from '../components/RedirectToHome';

// List of country codes with flags
const countryCodes = [
    { code: '+1', name: 'United States', flag: 'US' },
    { code: '+44', name: 'United Kingdom', flag: 'GB' },
    { code: '+91', name: 'India', flag: 'IN' },
    { code: '+81', name: 'Japan', flag: 'JP' },
    { code: '+971', name: 'United Arab Emirates', flag: 'AE' },
    // Add more countries here
];

const SignUpAdvanceIndividualPage = () => {
    const [checked, setChecked] = React.useState(false);
    const [checkboxError, setCheckboxError] = useState('');
    const { t } = useTranslation();

    const [file, setFile] = useState(null);
    const [fileError, setFileError] = useState('');
    const [description, setDescription] = useState('');
    const [passport, setPassport] = useState('');
    const [website, setWebsite] = useState('');

    // Error
    const [descriptionError, setDescriptionError] = useState('');
    const [passportError, setPassportError] = useState('');

    const [language, setLanguage] = useState(localStorage.getItem('selectedLanguage') || navigator.language.split('-')[0] || 'en');
    const dispatch = useDispatch();
    const { isAuthenticated, error } = useSelector((state) => state.auth);
    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1);  // Navigate to the previous page
    };

    useEffect(() => {
        // Detect and set the default language based on the browser or localStorage
        const detectedLanguage = localStorage.getItem('selectedLanguage') || navigator.language.split('-')[0] || 'en';
        i18n.changeLanguage(detectedLanguage);
        document.documentElement.lang = detectedLanguage;
    }, []);

    const handleCheckboxChange = (event) => {
        setChecked(event.target.checked);
        if (event.target.checked) {
            setCheckboxError('');
        }
    };

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        
        let valid = true;

        if (!description) {
            setDescriptionError(t('description_required'));
            valid = false;
        } else {
            setDescriptionError('');
        }

        if (!passport) {
            setPassportError(t('pasport_required'));
            valid = false;
        } else {
            setPassportError('');
        }

        if (!checked) {
            setCheckboxError(t('accept_terms_required'));
            valid = false;
        }
        
        if (valid) {
            // const fullMobileNumber = `${selectedCountryCode}${mobile}`;
            // dispatch(signInAction({ email, password, mobile: fullMobileNumber }));
        }
    };

    const handleLanguageChange = (lang) => {
        i18n.changeLanguage(lang);
        localStorage.setItem('selectedLanguage', lang);
        document.documentElement.lang = lang;
        setLanguage(lang);
    };

    const handleDescriptionChange = (e) => {
        setDescription(e.target.value);
        if (e.target.value) {
            setDescriptionError('');
        }
    };
    
    const handlePassportChange = (e) => {
        setPassport(e.target.value);
        if (e.target.value) {
            setPassport('');
        }
    };

    const handleWebsiteChange = (e) => {
        setWebsite(e.target.value);
        if (e.target.value) {
            setWebsite('');
        }
    };

    return (
        <>
        <RedirectToHome />
        
        <div className="login-bg">
            <div className="container d-flex justify-content-center align-items-center pt-5 pb-5">
                <div className="login-register">
                    <div className='text-center mb-5'>
                        <img src={logo} alt='Logo' width='200' />
                    </div>
                    <div className="card text-center rounded-20 bg-white">
                        <div className="card-body p-5">
                            <h2 className='mb-3 fw-bold theme-color font-24'>{t('advance_title')}</h2>
                            <form onSubmit={handleSubmit} style={{ width: '100%' }}>

                                <div className="mb-3 text-start">
                                    <label htmlFor="description" className="form-label">{t('description')} <span className='text-danger'>*</span></label>
                                    <input
                                        type="description"
                                        placeholder={t('description_placeholder')}
                                        className={`form-control ${descriptionError ? 'is-invalid' : ''}`}
                                        id="description"
                                        value={description}
                                        onChange={handleDescriptionChange}
                                    />
                                    {descriptionError && <div className="invalid-feedback">{descriptionError}</div>}
                                </div>

                                <div className="mb-3 text-start">
                                    <label htmlFor="passport" className="form-label">
                                        {t('passport')} <span className="text-danger">*</span>
                                    </label>
                                    <input
                                        type="file"
                                        placeholder={t('passport_placeholder')}
                                        className={`form-control ${passportError ? 'is-invalid' : ''}`}
                                        id="passport"
                                        value={passport}
                                        onChange={handlePassportChange}
                                    />
                                    {passportError && <div className="invalid-feedback">{passportError}</div>}
                                </div>

                                <div className="mb-3 text-start">
                                    <label htmlFor="website" className="form-label">{t('website')}</label>
                                    <input
                                        type="website"
                                        placeholder={t('website_placeholder')}
                                        className={`form-control`}
                                        id="website"
                                        value={website}
                                        onChange={handleWebsiteChange}
                                    />
                                </div>
                                <button 
                                    type="submit" 
                                    style={{ height: '45px' }}
                                    className="btn btn-primary w-100 mt-2 rounded-15"
                                >
                                    {t('register_btn')}
                                </button>
                                {error && (
                                    <div className="text-danger mt-2">
                                        {typeof error === 'string' ? error : t('sign_in_failed')}
                                    </div>
                                )}
                                <div className='d-flex justify-content-between align-items-center mt-3'>
                                    <div class="form-check">
                                        <input
                                            type="checkbox"
                                            checked={checked}
                                            className='form-check-input'
                                            id='termsconditions'
                                            onChange={handleCheckboxChange}
                                        />
                                        <label class="form-check-label font-13" for="termsconditions">
                                            Accept Terms & Conditions
                                        </label>
                                    </div>
                                    <span className='font-13'>{t('already_account')} <Link className='theme-color fw-bold text-capitalize text-decoration-none' to="/signin">{t('login_txt')}</Link></span>
                                </div>
                                {checkboxError && <div className="text-danger font-12 mt-1 text-uppercase">{checkboxError}</div>}
                                <div className='d-flex justify-content-between align-items-center mt-3 text-center'>
                                    <div className='text-center'>
                                        <Link className='theme-color font-14  text-capitalize' onClick={handleGoBack}>Back</Link>
                                    </div>
                                    <div>
                                        <LanguageSelect
                                            selectedLanguage={language}
                                            onLanguageChange={handleLanguageChange}
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
};

export default SignUpAdvanceIndividualPage;