// import './ServicePage.css';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { signOutAction } from '../redux/auth/authActions';
import { Navigate, Link } from 'react-router-dom';
import SecondaryHeader from '../components/SecondaryHeader';
import Footer from '../components/Footer';
import banner from '../assets/images/cash.png';
import youtubeBanner from '../assets/images/youtube-video.png';
import RedirectToHome from '../components/RedirectToHome';
import RedirectToSignin from '../components/RedirectToSignin';
import PrimaryMobileHeader from '../components/PrimaryMobileHeader';

const Cash = () => {
    const dispatch = useDispatch();
    const [isMobile, setIsMobile] = useState(false);
    const { isAuthenticated } = useSelector((state) => state.auth);

    const handleSignOut = () => {
        dispatch(signOutAction());
    };

    useEffect(() => {
        const handleResize = () => {
          setIsMobile(window.innerWidth <= 600); // Define the breakpoint for mobile
        };
    
        // Set initial value
        handleResize();
    
        // Add event listener
        window.addEventListener('resize', handleResize);
    
        // Cleanup the event listener
        return () => window.removeEventListener('resize', handleResize);
      }, []);

    return (
        <div className="bg-white">
            <RedirectToSignin />
            <RedirectToHome />
            {!isMobile ? (
                <SecondaryHeader />
            ) : (
                <PrimaryMobileHeader />
            )}
            <div className='container'>
                <div className='servicesPageList mt-4 pb-5'>
                    <div className='row justify-content-center'>
                        <div className='col-12 col-sm-7'>
                            <img src={banner} alt='Banner' className='img-fluid mb-5' />
                            <h2 className='mb-4 text-center'>Cash</h2>
                            <img src={youtubeBanner} alt='Banner' className='img-fluid mb-5' />
                        
                            <p className='text-muted'>A Letter of Credit (LC) is a financial instrument commonly used in car transactions, particularly for international purchases, to ensure that the payment process is secure and that both the buyer and seller are protected. When a car is purchased from a foreign dealer or manufacturer, the Letter of Credit serves as a guarantee from the buyer's bank that payment will be made to the seller, provided that the terms and conditions agreed upon are met. This arrangement gives the seller confidence that they will receive payment, even in a cross-border transaction, while offering the buyer the assurance that their funds are securely held until the vehicle is delivered as specified.</p>

                            <p className='text-muted'>In the car industry, Letters of Credit are often used in large-scale purchases or when dealing with high-value vehicles. The buyer's bank issues the LC, committing to pay the seller once the necessary documents, such as the bill of lading, inspection certificates, and other shipping documents, are presented. These documents serve as proof that the car has been shipped and that the transaction is proceeding according to the contract. This process not only facilitates smooth transactions but also mitigates the risks associated with international trade, such as non-payment or non-delivery.</p>

                            <p className='text-muted'>Once the seller meets the conditions specified in the Letter of Credit, such as timely shipment and correct documentation, the bank releases the payment. This system ensures that the seller receives payment only when the agreed-upon terms have been fulfilled, offering a high level of security to both parties. For example, a car dealer selling a vehicle internationally would only be paid after providing proof that the car has been shipped, thus protecting the buyer from paying for a car that has not been dispatched.</p>
                            
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Cash;
