import React, { useState, useEffect } from "react";
import SecondaryHeader from "../components/SecondaryHeader";
import PrimaryMobileHeader from "../components/PrimaryMobileHeader";
import Footer from "../components/Footer";
import RedirectToHome from "../components/RedirectToHome";
import RedirectToSignin from "../components/RedirectToSignin";
import { RotatingLines } from "react-loader-spinner";
import loaderLogo from "../assets/images/CM LOGO DARK BLUE.png";
import { API_URL } from "../api/authApi";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
import { useDispatch, useSelector } from "react-redux";
import carImage from "../assets/images/modification-car.png";
import accessoriesImg from "../assets/images/modification/accessories-img.png";
import servicekitImg from "../assets/images/modification/servicekit-img.png";
import maintenancekitImg from "../assets/images/modification/maintenancekit-img.png";
import sparePartsImg from "../assets/images/modification/sparepartsImg.png";
import "../styles/Modifications.css";
import parkingSensor from "../assets/images/website icons/parking sensors.png";
import reversingCamera from "../assets/images/website icons/reversing camera.png";
import rearEntertainmentScreens from "../assets/images/website icons/rear entertrainment screens.png";
import leatherSeats from "../assets/images/website icons/leather seats.png";
import pushStart from "../assets/images/website icons/push start.png";
import powerSeats from "../assets/images/website icons/power seats.png";
import handsFreeLiftgate from "../assets/images/website icons/hands free liftage.png";
import heatedCooledSeats from "../assets/images/website icons/heated cooled seats.png";
import blindSpotMonitor from "../assets/images/website icons/blind spot monitor.png";
import fogLights from "../assets/images/website icons/fog lights.png";
import birdsEyeViewCamera from "../assets/images/website icons/birds eye.png";
import headsUpDisplay from "../assets/images/website icons/headsup display.png";
import backDvd from "../assets/images/website icons/back DVD.png";
import frontDvd from "../assets/images/website icons/front DVD.png";
import digitalDisplay from "../assets/images/website icons/digital display.png";
import alloyWheel from "../assets/images/website icons/alloy wheel 17.png";
import { getCarBySlug, bookCar } from "../redux/auth/authActions";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { message } from "antd";
import SearchFilter from "../components/SearchFilter";
import {
  signOutAction,
  getCarMake,
  getCarModel,
  getCarsByBrand,
} from "../redux/auth/authActions";
import ProductCard from "../components/ProductCard";
import useScrollAnimation from "../components/useScrollAnimation";
const Modification = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const api_url = API_URL.replace("/api", "");
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [slug, setSlug] = useState(searchParams?.get("car"));
  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [isMobile, setIsMobile] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const elementsRef = useScrollAnimation();
  const reduxState = useSelector((state) => state.auth);
  const [selectedMake, setSelectedMake] = useState("");
  const [selectedModel, setSelectedModel] = useState("");
  const [vin, setVin] = useState("");
  const [carMake, setCarMake] = useState([]);
  const [carModel, setCarModel] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const handleProductSelect = (product, isSelected) => {
    console.log("Product Selected:", product);
    setSlug(product?.slug);
    setSelectedProducts(isSelected ? [product] : []);
  };
  const [cars, setCars] = useState([]);
  const fetchCarMake = async () => {
    const response = await dispatch(getCarMake());
    setCarMake(response?.data);
  };

  const fetchCarModel = async (id) => {
    const response = await dispatch(getCarModel(id));
    setCarModel(response?.data);
  };
  const onChange = (id) => {
    setSelectedMake(id); // Set selected make
    const selectedCarMak = carMake?.find(
      (item) => parseInt(item.id) === parseInt(id)
    );
    fetchCarModel(selectedCarMak?.slug);
  };

  const handleModelChange = (id) => {
    setSelectedModel(id); // Set selected model
  };

  const gotoBrandModels = () => {
    const makeSlug = carMake.find(
      (make) => make?.id === parseInt(selectedMake)
    )?.slug;
    const modelSlug = carModel.find(
      (model) => model?.id === parseInt(selectedModel)
    )?.slug;
    const queryParams = new URLSearchParams();
    if (makeSlug) queryParams.append("make", makeSlug);
    if (modelSlug) queryParams.append("model", modelSlug);
    if (vin) queryParams.append("vin", vin);
    navigate({
      pathname: `/car/listing`,
      search: `?${queryParams.toString()}`,
      state: {
        id: selectedMake, // Pass id (Make ID)
        //   modelId: selectedModel, // Pass model id
      },
    });
  };

  const handleSearch = async () => {
    setIsLoading(true);
    if (selectedMake || vin) {
      const makeSlug = carMake.find(
        (make) => make.id === parseInt(selectedMake)
      )?.slug;
      const modelSlug = carModel.find(
        (model) => model.id === parseInt(selectedModel)
      )?.slug;
      const response = await dispatch(
        getCarsByBrand({
          make: makeSlug,
          model: modelSlug,
          vin: vin,
        })
      );
      setIsLoading(false);
      setCars(response?.data?.result?.data);
      console.log("Cars:", response?.data?.result?.data);

      // navigate({
      //   pathname: `/car/listing`,
      //   search: `?${queryParams.toString()}`,
      //   state: {
      //     id: selectedMake, // Pass id (Make ID)
      //     //   modelId: selectedModel, // Pass model id
      //   },
      // });
    } else {
      // Add some error handling logic if fields are not properly filled
      console.log("Please select a make, model, and enter VIN");
    }
  };

  const features = [
    { name: "Parking Sensors", value: "Parking Sensors", image: parkingSensor },
    {
      name: "Reversing Camera",
      value: "Reversing Camera",
      image: reversingCamera,
    },
    {
      name: "Rear Screens",
      value: "Rear Screens",
      image: rearEntertainmentScreens,
    },
    { name: "Leather Seats", value: "Leather Seats", image: leatherSeats },
    { name: "Push Start", value: "Push Start", image: pushStart },
    { name: "Power Seats", value: "Power Seats", image: powerSeats },
    {
      name: "Hands Free Liftgate",
      value: "Hands Free Liftgate",
      image: handsFreeLiftgate,
    },
    {
      name: "Heated-cooled Seats",
      value: "Heated-cooled Seats",
      image: heatedCooledSeats,
    },
    {
      name: "Blind Spot Monitor",
      value: "Blind Spot Monitor",
      image: blindSpotMonitor,
    },
    { name: "Fog Lights", value: "Fog Lights", image: fogLights },
    {
      name: "Birds View",
      value: "Birds View",
      image: birdsEyeViewCamera,
    },
    {
      name: "Alloy wheel",
      value: "Alloy wheel",
      image: alloyWheel,
    },
    {
      name: "Headsup display",
      value: "Headsup display",
      image: headsUpDisplay,
    },
    {
      name: "Back DVD",
      value: "Back DVD",
      image: backDvd,
    },
    {
      name: "Front DVD",
      value: "Front DVD",
      image: frontDvd,
    },
    {
      name: "Digital display",
      value: "Digital display",
      image: digitalDisplay,
    },
  ];
  const [car, setCar] = useState([]);
  const [buttonLoading, setButtonLoading] = useState(false);
  const fetchCarBySlug = async (slug) => {
    const response = await dispatch(getCarBySlug(slug));
    setCar(response?.data);
    console.log("Car Data:", response?.data?.car_booking?.modifications);

    setSelectedFeatures(response?.data?.car_booking?.modifications || []);
    //for testing purpose only
    //  setPdfUrl("/prado%20spec%20sheet-compressed%201.pdf");
  };

  const handlePrevImage = () => {
    setCurrentImageIndex(
      (prevIndex) => (prevIndex - 1 + car?.images?.length) % car?.images?.length
    );
  };

  const handleNextImage = () => {
    console.log("Current Image Index:", currentImageIndex);

    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % car?.images?.length);
  };

  const handleFeatureClick = (featureValue) => {
    console.log(
      "llllll:",
      !slug,
      selectedProducts?.length === 0,
      !slug || selectedProducts?.length === 0
    );

    if (!slug && selectedProducts?.length === 0) return;
    setSelectedFeatures((prevFeatures) => {
      if (!Array.isArray(prevFeatures)) {
        // Ensure prevFeatures is an array
        prevFeatures = [];
      }

      return prevFeatures.includes(featureValue)
        ? prevFeatures.filter((feature) => feature !== featureValue) // Remove if selected
        : [...prevFeatures, featureValue]; // Add if not selected
    });
  };

  useEffect(() => {
    console.log("cars:", cars);
  }, [cars]);

  // Log the selected features when they change
  useEffect(() => {
    console.log("Selected features:", selectedFeatures);
  }, [selectedFeatures, searchParams]);

  const handleBookCar = async () => {
    console.log("handleBookCar");
    setButtonLoading(true);
    setButtonLoading(true);
    const response = await dispatch(bookCar(car?.id, selectedFeatures));
    setButtonLoading(false);
    if (response?.code === 200) {
      message.success("Car modification request send successfully!");
      navigate("/");
    }
  };

  useEffect(() => {
    if (slug) {
      fetchCarBySlug(slug); // Fetch car data whenever the slug changes
    }
  }, [slug]);

  useEffect(() => {
    fetchCarBySlug(slug);
    reduxState?.carMake ? setCarMake(reduxState?.carMake) : fetchCarMake();
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600); // Define the breakpoint for mobile
    };
    const detectedLanguage =
      localStorage.getItem("selectedLanguage") ||
      navigator.language.split("-")[0] ||
      "en";
    i18n.changeLanguage(detectedLanguage);
    document.documentElement.lang = detectedLanguage;
    // Set initial value
    handleResize();

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const cards = [
    {
      image: servicekitImg,
      title: "Service Kit",
      description:
        "Maintain peak performance with genuine service kits designed ...",
      link: "/service-kits",
    },
    {
      image: maintenancekitImg,
      title: "Maintenance Kit",
      description:
        "Choose genuine maintenance kits for reliable, high-quality parts ...",
      link: "#",
    },
    {
      image: accessoriesImg,
      title: "Accessories",
      description:
        "Upgrade your drive with premium car accessories designed for style ...",
      link: "/accessories",
    },
    {
      image: sparePartsImg,
      title: "Spare Parts",
      description: "Ensure quality and durability with genuine spare parts.",
      link: "/spare-parts",
    },
  ];

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <div>
      <RedirectToSignin />
      <RedirectToHome />
      {!isMobile ? <SecondaryHeader /> : <PrimaryMobileHeader />}
      <div
        classNameName="modifications-page"
        style={{ backgroundColor: "white" }}
      >
        {/* <div className="header-menu d-flex bg-white col-12">
          <nav className="navbar navbar-expand-lg w-100">
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="nav col-12 d-flex justify-content-between">
                <li className="nav-item col-lg-2 col-md-4 col-sm-12 d-flex align-items-center">
                  <a className="nav-link active" href="#">
                    MODEL
                  </a>
                </li>
                <li className="nav-item col-lg-2 col-md-4 col-sm-12">
                  <a className="nav-link" href="#">
                    WHEELS
                  </a>
                </li>
                <li className="nav-item col-lg-2 col-md-4 col-sm-12">
                  <a className="nav-link" href="#">
                    STYLING & AERODYNAMICS
                  </a>
                </li>
                <li className="nav-item col-lg-2 col-md-4 col-sm-12">
                  <a className="nav-link" href="#">
                    INTERIOR
                  </a>
                </li>
                <li className="nav-item col-lg-2 col-md-4 col-sm-12">
                  <a className="nav-link" href="#">
                    PERFORMANCE
                  </a>
                </li>
                <li className="nav-item col-lg-2 col-md-4 col-sm-12">
                  <a className="nav-link" href="#">
                    COMPILE CONFIGURATION
                  </a>
                </li>
              </ul>
            </div>
          </nav>
        </div> */}
        <div className="container">
          <div class="row d-flex">
            {slug && (
              <div class="action-bar d-flex justify-content-between align-items-center">
                <div class="action-item d-flex align-items-center">
                  <i class="bi bi-eye-slash"></i>
                  <span class="ms-2">COMPARE BASEMODEL</span>
                </div>
                <div class="action-item d-flex align-items-center">
                  <i class="bi bi-camera-video"></i>
                  <i
                    class="bi bi-chevron-left ms-2 "
                    onClick={handlePrevImage}
                  ></i>
                  <span class="ms-2">
                    {car?.images?.[currentImageIndex]?.name}
                  </span>
                  <i
                    class="bi bi-chevron-right ms-2"
                    onClick={handleNextImage}
                  ></i>
                </div>
                <div class="action-item d-flex align-items-center">
                  <i class="bi bi-camera"></i>
                  <span class="ms-2">SCREENSHOT</span>
                </div>
              </div>
            )}
            {slug ? (
              <div class="col-7 col-lg-8 d-flex justify-content-center align-items-center car-image-section mb-3">
                <img
                  src={`${api_url}/${car?.images?.[currentImageIndex]?.image?.path}`}
                  alt="Car Image"
                  class="car-image-modification"
                />
              </div>
            ) : cars?.length > 0 ? (
              <>
                <div class="col-12 col-lg-8 d-flex flex-column align-items-center car-image-section mb-5 mt-5">
                  {/* Card section */}
                  <div class="row w-100 justify-content-center">
                    {cars?.slice(0, 3).map((item, index) => (
                      <div
                        className="col-12 col-sm-6 col-lg-4 mb-3 pe-3"
                        key={`first-row-${index}`}
                      >
                        <ProductCard
                          productData={item}
                          fromPage={"modifications"}
                          onSelectProduct={handleProductSelect}
                          selectedProduct={selectedProducts[0]}
                        />
                      </div>
                    ))}
                  </div>

                  {/* Button below the card section but in the same parent div */}
                  {cars?.length > 3 && (
                    <div className="w-100 d-flex justify-content-center">
                      <button
                        className=" mt-3 d-flex justify-content-center"
                        onClick={gotoBrandModels}
                        style={{
                          backgroundColor: "#000740",
                          color: "#fff",
                          width: "200px",
                          borderRadius: "20px",
                        }}
                      >
                        View All
                        <i className="bi bi-arrow-right ms-2"></i>
                      </button>
                    </div>
                  )}
                </div>
              </>
            ) : (
              <>
                <div class="col-12 col-lg-8 d-flex justify-content-center car-image-section mb-3 mt-5">
                  <div className="col-10">
                    <div
                      style={{ color: "#000740" }}
                      className="col-12 col-lg-8 mb-5 mt-5"
                    >
                      <h1 style={{ fontWeight: "700", fontSize: "3.5rem" }}>
                        Modify Your Car
                      </h1>
                      <p style={{ fontSize: "18px", fontFamily: "Inter" }}>
                        {t("modification_text")}
                      </p>
                    </div>
                    <div className="card rounded-10 search-filter-header bg-search">
                      <div className="card-body p-2">
                        <div className="row">
                          <div className="col-12 col-sm-9">
                            <div className="row">
                              {/* Make Dropdown */}
                              <div className="col-4 border-end">
                                <select
                                  className="form-select w-100"
                                  onChange={(e) => onChange(e.target.value)}
                                  style={{
                                    backgroundColor: "rgba(204, 204, 204, 0.0)",
                                  }}
                                >
                                  <option selected disabled>
                                    {t("make")}
                                  </option>
                                  {carMake?.map((make) => (
                                    <option key={make?.id} value={make?.id}>
                                      {make?.name}
                                    </option>
                                  ))}
                                </select>
                              </div>

                              {/* Model Dropdown */}
                              <div className="col-4 border-end">
                                <select
                                  className="form-select w-100"
                                  style={{
                                    backgroundColor: "rgba(204, 204, 204, 0.0)",
                                  }}
                                  onChange={(e) =>
                                    handleModelChange(e.target.value)
                                  }
                                >
                                  <option selected disabled>
                                    {t("model")}
                                  </option>
                                  {carModel?.map((model) => (
                                    <option key={model?.id} value={model?.id}>
                                      {model?.name}
                                    </option>
                                  ))}
                                </select>
                              </div>

                              {/* VIN Input */}
                              <div className="col-4">
                                <input
                                  type="text"
                                  className="form-control border-0 w-100"
                                  placeholder="VIN"
                                  value={vin}
                                  onChange={(e) => setVin(e.target.value)}
                                  style={{
                                    backgroundColor: "rgba(204, 204, 204, 0.0)",
                                  }}
                                />
                              </div>
                            </div>
                          </div>

                          {/* Search Button */}
                          <div className="col-12 col-sm-3 mt-3 mt-sm-0">
                            <button
                              className="btn btn-primary w-100 rounded-10"
                              onClick={handleSearch}
                              disabled={!selectedMake || !selectedModel} // Disable button if either is not selected
                            >
                              <i className="bi bi-search me-1 font-13"></i>{" "}
                              {t("search")}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            <div class="col-12 col-lg-4 feature-selection mt-5 mb-5">
              <div class="d-flex justify-content-center col-12">
                <button
                  className=" mt-3 mb-5 d-flex justify-content-center align-items-center"
                  style={{
                    backgroundColor: "#000740",
                    color: "#fff",
                    width: "221px",
                    borderRadius: "10px",
                    height: "40px",
                  }}
                >
                  Modifications Available
                </button>
              </div>
              <div className="row feature-grid">
                {features?.map((feature, index) => (
                  <div className="col-3 col-md-3 col-lg-3 mb-3" key={index}>
                    <div
                      className="feature-item position-relative"
                      onClick={() => handleFeatureClick(feature.value)} // Handle click to toggle feature
                    >
                      <img
                        src={feature.image}
                        alt={feature.name}
                        className="feature-image"
                      />
                      <span className="feature-label">{feature.name}</span>
                      <input
                        type="checkbox"
                        name="feature"
                        value={feature.value}
                        checked={selectedFeatures?.includes(feature?.value)} // Reflect selection state
                        onChange={() => {}}
                        className="feature-checkbox"
                        id={feature.value}
                      />
                    </div>
                  </div>
                ))}
              </div>
              {(slug || selectedProducts?.length > 0) && (
                <div className="d-flex">
                  <div class="d-flex justify-content-center w-50 mt-4 mb-4">
                    <button
                      class="btn btn-primary continue-btn"
                      onClick={handleBookCar}
                      disabled={buttonLoading || selectedFeatures.length === 0}
                    >
                      {buttonLoading ? (
                        <RotatingLines
                          strokeColor="#FFF" // Adjust the stroke color to match the button style
                          strokeWidth="4"
                          animationDuration="0.75"
                          width="24" // Adjust the size to fit inside the button
                          visible={true}
                        />
                      ) : (
                        "Continue"
                      )}
                    </button>
                  </div>

                  <div class="d-flex justify-content-center w-50 mt-4 mb-4">
                    <button
                      class="btn btn-primary continue-btn"
                      onClick={() => {
                        navigate("/");
                      }}
                    >
                      Skip
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="container my-5">
        <div className="row g-4">
          {cards.map((card, index) => (
            <div className="col-12 col-md-6" key={index}>
              <div
                className="card  shadow-sm border-0 bg-white"
                style={{
                  borderRadius: "15px",
                  cursor: "pointer",
                }}
                onClick={() => handleNavigation(card.link)}
              >
                <div className="row g-0 d-flex align-items-center">
                  {/* Left side image */}
                  <div className="col-md-4 d-flex align-items-center justify-content-center">
                    <img
                      src={card.image}
                      className="img-fluid p-3"
                      alt={card.title}
                      style={{
                        objectFit: "contain",
                        width: "270px",
                        height: "218px",
                      }}
                    />
                  </div>
                  {/* Right side content */}
                  <div className="col-md-8">
                    <div className="card-body">
                      <h5 className="card-title mod-card-title">
                        {card.title}
                      </h5>
                      <p className="card-text mod-card-text">
                        {card.description}
                      </p>
                      <button className="btn btn-primary d-flex align-items-center gap-2">
                        Add <i className="bi bi-cart"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Modification;
