import "./SignInPage.css";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  signInAction,
  setCurrentLanguage,
  submitResetPassword,
  submitVerifyOtp,
  submitRequestOtp,
} from "../redux/auth/authActions";
import { Navigate, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LanguageSelect from "../components/LanguageSelect";
import i18n from "../i18n"; // Import your i18n configuration
import logo from "../assets/images/blue-logo.png"; // Import the image file
import Swal from "sweetalert2";
import { Button, message, Space } from "antd";
import RedirectToHome from "../components/RedirectToHome";
import PhoneInput from "react-phone-input-2";
import { forgotPassword } from "../api/authApi";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
// import { height } from '@mui/system';

const OtpRegistration = () => {
  const location = useLocation();
  const { email, mobile } = location.state || {};
  const [mobileOtp, setMobileOtp] = useState("");
  const [emailOtp, setEmailOtp] = useState("");
  const [mobileOtpError, setMobileOtpError] = useState("");
  const [emailOtpError, setEmailOtpError] = useState("");
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [timer, setTimer] = useState(90); // Countdown timer in seconds
  const [loading, setLoading] = useState(false);
  const [language, setLanguage] = useState(
    localStorage.getItem("selectedLanguage") ||
      navigator.language.split("-")[0] ||
      "en"
  );

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isAuthenticated, error } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  useEffect(() => {
    const detectedLanguage =
      localStorage.getItem("selectedLanguage") ||
      navigator.language.split("-")[0] ||
      "en";
    i18n.changeLanguage(detectedLanguage);
    document.documentElement.lang = detectedLanguage;
  }, []);

  useEffect(() => {
    if (timer === 0) {
      setIsOtpSent(false);
    }
    let interval = null;
    if (isOtpSent && timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (!isOtpSent) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isOtpSent, timer]);

  const sendOtp = async () => {
    const otpRegex = /^[0-9]{6}$/;
    // if (!otp) {
    //   setOtpError(t("otp_required"));
    //   return;
    // } else if (!otpRegex.test(otp)) {
    //   setOtpError(t("invalid_otp"));
    //   return;
    // }
    const response = await dispatch(
      submitVerifyOtp({ email: email, email_otp_code: emailOtp })
    );
    if (response?.code === 200) {
      //   setOtpError("");
      //   setIsOtpVerified(true);
      navigate("/register-success", {
        state: { referral_code: response?.referral_code },
      });
      message.success("OTP verified successfully!");
    } else {
      message.error("OTP verification failed!");
    }
  };
  return (
    <>
      <RedirectToHome />
      <div className="login-bg">
        <div className="container d-flex justify-content-center align-items-center pt-5 pb-5">
          <div className="login-register vh-100">
            <div className="text-center mb-5">
              <img src={logo} alt="Logo" width="200" />
            </div>
            <div className="card text-center bg-white rounded-20">
              <div className="card-body p-5">
                <h2 className="mb-0 fw-bold theme-color font-26">
                  {t("enter_otp")}
                </h2>
                <p className="fw-bold theme-color font-12">
                  "Please enter the otp recieved on your registered email"
                </p>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    sendOtp();
                  }}
                  style={{ width: "100%" }}
                >
                  {/* <div className="mb-3 text-start">
                    <label htmlFor="otp" className="form-label">
                      {t("mobile")} <span className="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      placeholder={t("otp_placeholder")}
                      className={`form-control ${
                        mobileOtpError ? "is-invalid" : ""
                      }`}
                      id="otp"
                      value={mobileOtp}
                      onChange={(e) => setMobileOtp(e.target.value)}
                    />
                    {mobileOtpError && (
                      <div className="invalid-feedback">{mobileOtpError}</div>
                    )}
                  </div> */}
                  <div className="mb-3 text-start">
                    <label htmlFor="otp" className="form-label">
                      {t("signup_email")} <span className="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      placeholder={t("otp_placeholder")}
                      className={`form-control ${
                        emailOtpError ? "is-invalid" : ""
                      }`}
                      id="otp"
                      value={emailOtp}
                      onChange={(e) => setEmailOtp(e.target.value)}
                    />
                    {emailOtpError && (
                      <div className="invalid-feedback">{emailOtpError}</div>
                    )}
                  </div>

                  <div className="mb-3">
                    <button type="submit" className="btn btn-primary w-100">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OtpRegistration;
