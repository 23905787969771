import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

const AuthGuard = ({ children }) => {
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    // if (!isAuthenticated) {
    //     return <Navigate to="/signin" />;
    // }

    return children;
};

export default AuthGuard;
