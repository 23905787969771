import React from "react";
import imgPlaceholder from "../assets/images/img-placeholder.png";
import { Badge, Card } from "antd";
import { useNavigate } from "react-router-dom";
import "./ItemCard.css"; // Create this CSS file for additional custom styling
import { API_URL } from "../api/authApi";
import { cartUpdate, priceEnquiry } from "../redux/auth/authActions";
import { useDispatch, useSelector } from "react-redux";
const { Meta } = Card;

const ItemCard = ({ productData, btnText, fromPage, FromWishlist }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const api_url = API_URL.replace("/api", "");
  const updateCart = async (id, method) => {
    const data = {
      product_id: id,
      quantity: "1",
      action: method, // Either "add" or "remove"
    };
    const response = await dispatch(cartUpdate(data));
  };
  const addToWishlist = (id) => {};

  const goToProductDetails = () => {
    if (fromPage === "Home") {
      const queryParams = new URLSearchParams();
      queryParams.append("car", productData?.slug);
      const queryString = queryParams.toString();
      navigate(`/car-specification?${queryString}`);
    } else {
      navigate(`/product-details/${productData?.id}`, {
        state: { fromPage, id: productData?.id }, // Pass the fromPage as state
      });
    }
  };
  const inquirePrice = async (id) => {
    const response = await dispatch(
      priceEnquiry({ product_id: id, message: "price" })
    );
  };
  const setNameCar = () => {
    const {
      steering_type,
      name,
      model,
      engine_size,
      fuel_type,
      transmission,
      ext_color,
      production_year,
    } = productData || {};
    const transmissionLabel =
      productData?.transmission === "Automatic" ? "AT" : "ML";
    const carName = `${steering_type ? `(${steering_type})` : ""} ${
      name ? name : ""
    } ${model ? model : ""} ${engine_size ? engine_size : ""} ${
      fuel_type ? fuel_type : ""
    } ${transmissionLabel ? transmissionLabel : ""} ${
      ext_color ? ext_color : ""
    } - ${production_year ? `MY${production_year}` : ""}`;
    return carName.trim();
  };

  return (
    <div className="product-card" style={{ position: "relative" }}>
      <Card
        className="whole-card"
        hoverable
        onClick={goToProductDetails}
        cover={
          <img
            className="product-image"
            alt={productData?.name}
            src={
              productData?.images && productData?.images?.length > 0
                ? `${api_url}/${productData?.images?.[0]?.image?.path}` // Use the first image's path with the base URL
                : imgPlaceholder // Fallback to the placeholder if no images are present
            }
          />
        }
      >
        <Meta
          title={
            <>
              {fromPage === "Home" ? (
                <div className="product-title">{setNameCar()}</div>
              ) : (
                <div className="product-title">{productData?.name} </div>
              )}
              {fromPage !== "Home" && (
                <div className="product-description">
                  {productData?.short_description}
                </div>
              )}
            </>
          }
        />
        {/* This condition is added here bcs the same component is using in newArrivals */}
        {fromPage !== "Home" && (
          <>
            <hr />
            <div className="item-card-footer">
              {productData?.price && (
                <div className="product-price">{productData?.price} AED</div>
              )}
              <div className="add-to-cart">
                {productData?.price ? (
                  <button
                    type="button"
                    className="btn btn-primary w-100"
                    onClick={(e) => {
                      e.stopPropagation();
                      if (productData?.is_in_cart) {
                        updateCart(productData?.id, "remove"); // Call the remove from cart function
                      } else {
                        updateCart(productData?.id, "add"); // Call the add to cart function
                      }
                    }}
                  >
                    {productData?.is_in_cart
                      ? "Remove From Cart"
                      : "Add To Cart"}
                  </button>
                ) : productData?.inquiry_details ? (
                  <button type="button" className="btn btn-primary w-100">
                    Enquired
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-primary w-100"
                    onClick={(e) => {
                      e.stopPropagation();
                      inquirePrice(productData?.id); // Call the inquire price function
                    }}
                  >
                    Enquire Price
                  </button>
                )}
              </div>
            </div>
          </>
        )}
      </Card>
      {/* This condition is added here bcs the same component is using in newArrivals */}
      {/* Wishlist button */}
      {fromPage !== "Home" && (
        <button
          type="button"
          className="wishlist-btn"
          onClick={() => FromWishlist(productData?.id)} // Handle wishlist action
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            backgroundColor: "transparent",
            border: "none",
            cursor: "pointer",
          }}
        >
          {productData?.is_in_wishlist ? (
            <i
              className="bi bi-heart-fill"
              style={{ fontSize: "1.5rem", color: "red" }}
            ></i> // Filled heart if in wishlist
          ) : (
            <i
              className="bi bi-heart"
              style={{ fontSize: "1.5rem", color: "#282c34" }}
            ></i> // Outline heart if not in wishlist
          )}
        </button>
      )}
    </div>
  );
};

export default ItemCard;
