import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import echo from "./echo";
import { API_URL } from "../api/authApi";
import "../styles/ChatBox.css";
import { getMessagesByChatroom, sendMessage } from "../redux/auth/authActions";
import { RotatingLines } from "react-loader-spinner"; // Import the loader
import ProfileAvatar from "../assets/images/Profile_avatar_placeholder_large-circle-300x300.png";
import moment from "moment";
import EmojiPicker from "emoji-picker-react";
const ChatWindow = ({
  initialMessages,
  inquiryMessage,
  department,
  chatRoomId,
  close,
}) => {
  const messagesEndRef = useRef(null);
  const reduxState = useSelector((state) => state.auth);
  const currentUserId = reduxState?.user?.data?.user?.id;
  const api_url = API_URL.replace("/api", "");
  const dispatch = useDispatch();

  const [messages, setMessages] = useState(initialMessages);
  const [newMessage, setNewMessage] = useState("");
  const [loading, setLoading] = useState(true); // State for loader
  const [groupedMessages, setGroupedMessages] = useState({});
  const [file, setFile] = useState(null); // State to hold the uploaded file
  const fileInputRef = useRef(null); // Ref for the hidden file input
  const [attachments, setAttachments] = useState([]);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  // Send message
  const send = async () => {
    const formData = new FormData();
    formData.append("message", newMessage);
    formData.append("chat_room_id", chatRoomId);
    attachments.forEach((file, index) => {
      formData.append(`attachments[${index}]`, file); // Append files to formData
    });
    console.log("FormData:", [...formData.entries()]);

    const message = formData.get("message");
    const hasAttachments = [...formData.entries()].some(([key]) =>
      key.startsWith("attachments")
    );

    if ((!message || message.trim() === "") && !hasAttachments) {
      console.log("Both message and attachments are empty. Returning early.");
      return; // Exit the function early
    }
    const response = await dispatch(sendMessage(formData));
    setNewMessage("");
    if (response?.code === 200) {
      setAttachments([]);
      setNewMessage("");
      setShowEmojiPicker(false);
      fetchMessages();
    }
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  // Fetch messages
  const fetchMessages = async () => {
    const response = await dispatch(getMessagesByChatroom(chatRoomId));
    setMessages(response?.data);
    setLoading(false); // Hide loader after fetching messages
  };

  // Communication channel
  const communicationChanell = () => {
    const channel = echo
      .private(`chat.room.${chatRoomId}`)
      .listen("MessageSent", (event) => {
        setMessages((prevMessages) => [
          ...prevMessages,
          { text: event.message, from: event.from },
        ]);
      });

    // Clean up listener when component unmounts
    return () => {
      channel.stopListening("MessageSent");
      echo.leaveChannel(`private-chat.room.${chatRoomId}`);
    };
  };

  const groupMessagesByDate = (messages) => {
    const grouped = {};
    messages.forEach((message) => {
      const messageDate = moment(message.created_at);
      let formattedDate;
      if (messageDate.isSame(moment(), "day")) {
        formattedDate = "Today";
      } else if (messageDate.isSame(moment().subtract(1, "days"), "day")) {
        formattedDate = "Yesterday";
      } else if (messageDate.isAfter(moment().subtract(7, "days"))) {
        formattedDate = messageDate.format("dddd"); // Example: Monday, Tuesday
      } else {
        formattedDate = messageDate.format("MMMM DD, YYYY"); // Example: October 17, 2024
      }
      if (!grouped[formattedDate]) {
        grouped[formattedDate] = [];
      }
      grouped[formattedDate].push(message);
    });
    return grouped;
  };

  const handleFileUpload = (event) => {
    const selectedFiles = Array.from(event.target.files); // Convert FileList to array
    setAttachments((prevFiles) => [...prevFiles, ...selectedFiles]); // Add new files to attachments array
  };

  const handleFileButtonClick = () => {
    console.log("File upload button clicked. Please select a file to upload.");

    if (fileInputRef.current) {
      fileInputRef.current.click(); // Trigger the hidden file input's click
    }
  };
  const removeFile = (index) => {
    setAttachments((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const onEmojiClick = (event, emojiObject) => {
    console.log("emoji clicked:", emojiObject, event);

    setNewMessage((prevMessage) => (prevMessage || "") + event?.emoji); // Append emoji to the message, handling undefined
  };

  useEffect(() => {
    fetchMessages();
    communicationChanell();
    scrollBottom();
    const intervalId = setInterval(() => {
      fetchMessages();
    }, 3000); // 3 seconds delay

    // Cleanup interval when component unmounts
    return () => clearInterval(intervalId);
  }, []);
  const scrollBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  useEffect(() => {
    scrollBottom();
  }, [newMessage]);

  useEffect(() => {
    if (messages?.length > 0) {
      setGroupedMessages(groupMessagesByDate(messages));
    }
  }, [messages]);

  return (
    <>
      <div
        className="chat-header d-flex align-items-center justify-content-between p-3 text-white"
        style={{
          backgroundColor: "#000740",
          minHeight: "100px",
          position: "relative",
        }}
      >
        <h5 className="mb-0">Chat with us</h5>
        <button
          className="btn p-0"
          onClick={close} // Ensure this function is defined
          style={{
            background: "transparent",
            border: "none",
            fontSize: "20px",
            color: "#fff",
          }}
        >
          <i className="bi bi-x-lg"></i>
        </button>
      </div>
      <div className="chat-messages flex-grow-1 overflow-auto p-3 bg-light card m-3">
        {/* Conditionally render the loader when loading */}
        {loading && (
          <div className="d-flex justify-content-center align-items-center">
            <RotatingLines
              strokeColor="#000740"
              strokeWidth="5"
              animationDuration="0.75"
              width="50"
              visible={true}
            />
          </div>
        )}

        {/* Render the messages */}
        {!loading &&
          Object.entries(groupedMessages)?.map(([date, messages], index) => (
            <div key={index} className="grouped-messages">
              <div className="date-separator text-center mb-3">
                <span className="badge bg-secondary">{date}</span>
              </div>
              {messages?.map((message, msgIndex) => (
                <div
                  key={msgIndex}
                  className={`d-flex mb-3 ${
                    message.from_user_id === currentUserId
                      ? "justify-content-end"
                      : "justify-content-start"
                  }`}
                >
                  <div>
                    <img
                      src={ProfileAvatar} // Replace with actual avatar URL or variable
                      alt="User Avatar"
                      className="rounded-circle me-2"
                      style={{ width: "40px", height: "40px" }}
                    />
                  </div>
                  <div style={{ maxWidth: "90%" }}>
                    <p
                      className="mb-1 fw-bold"
                      style={{ color: "#000740", fontSize: "14px" }}
                    >
                      {message.from_user_id === currentUserId
                        ? "You"
                        : "Assistant"}
                    </p>
                    <div
                      className={`message-bubble p-1 position-relative ${
                        message.from_user_id === currentUserId
                          ? "text-dark"
                          : "text-dark"
                      }`}
                      style={{
                        backgroundColor:
                          message.from_user_id === currentUserId
                            ? "#F1F7FF"
                            : "#E8E8E8",
                        color:
                          message.from_user_id === currentUserId ? "white" : "",
                        borderRadius: "10px",
                        // message.from_user_id !== currentUserId
                        //   ? "20px 20px 20px 0"
                        //   : "20px 20px 0px 20px",
                        minWidth: "80px",
                      }}
                    >
                      <p
                        className="mt-1 ps-2 mb-1"
                        style={{ fontSize: "14px" }}
                      >
                        {message.message}
                      </p>
                      {message.attachments?.length > 0 && (
                        <div className="attachments">
                          {message.attachments.map(
                            (attachment, attachIndex) => (
                              <div
                                key={attachIndex}
                                className="attachment-item mt-1 mb-1"
                                style={{
                                  backgroundColor: "", // Lighter background color for attachment container
                                  padding: "1px",
                                }}
                              >
                                {attachment.file.extension === "pdf" ? (
                                  // If it's a PDF, display a link
                                  <a
                                    href={`${api_url}/${attachment.file.path}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="d-flex align-items-center"
                                    style={{
                                      color: "#000740", // Custom color for the link
                                      backgroundColor: "#e8e8e8",
                                      textDecoration: "none",
                                      fontWeight: "500",
                                      borderRadius: "10px",
                                      border: "1px solid #ddd", // Slightly bolder text for the filename
                                    }}
                                  >
                                    <i
                                      className="bi bi-file-earmark-pdf"
                                      style={{
                                        fontSize: "24px",
                                        marginRight: "10px",
                                        color: "#000740", // Match the icon color to the text
                                      }}
                                    ></i>
                                    {attachment.file.original_name}
                                  </a>
                                ) : (
                                  // If it's an image, display a preview
                                  <img
                                    src={`${api_url}/${attachment.file.path}`}
                                    alt={attachment.file.original_name}
                                    style={{
                                      maxWidth: "150px",
                                      borderRadius: "10px",
                                      border: "2px solid #000740",
                                      marginTop: "5px",
                                    }}
                                  />
                                )}
                              </div>
                            )
                          )}
                        </div>
                      )}
                    </div>
                    <div
                      className={`small text-muted mt-1 ${
                        message.from_user_id === currentUserId
                          ? "text-end"
                          : "text-start"
                      }`}
                    >
                      {new Date(message.sent_at).toLocaleString("en-US", {
                        hour: "numeric",
                        minute: "numeric",
                        hour12: true,
                      })}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ))}
        <div ref={messagesEndRef} />
      </div>
      {/* {msg.from_user_id === currentUserId && (
                <img
                  src={
                    msg.from_user?.profile_image
                      ? `${api_url}/${msg.from_user.profile_image.path}`
                      : ProfileAvatar
                  }
                  alt="User Avatar"
                  className="rounded-circle ms-2"
                  width="40"
                  height="40"
                />
              )} */}
      <div className="chat-input p-2 bg-white border-top">
        <div className="d-flex align-items-center">
          {/* Emoji Icon */}
          {!attachments?.length > 0 && (
            <button
              className="btn p-0 me-2"
              onClick={() => setShowEmojiPicker(!showEmojiPicker)} // Toggle emoji picker
              style={{ background: "transparent", border: "none" }}
            >
              <i
                className="bi bi-emoji-smile"
                style={{ color: "#000740", fontSize: "24px" }}
              ></i>
            </button>
          )}

          {/* Input Field */}
          {attachments?.length > 0 ? (
            <div
              className="file-preview-wrapper d-flex flex-wrap align-items-center"
              style={{
                maxWidth: "70%",
                maxHeight: "100px", // Limit the height of the preview area
                overflowY: "auto", // Enable vertical scrolling
                overflowX: "auto", // Enable horizontal scrolling if needed
                padding: "10px",
                border: "1px solid #ccc",
                borderRadius: "10px",
                backgroundColor: "#f8f9fa", // Light background to distinguish
              }}
            >
              {attachments?.map((file, index) => (
                <div
                  key={index}
                  className="file-preview-item me-2 position-relative"
                  style={{
                    display: "inline-block",
                    marginRight: "10px",
                    maxWidth: "80px", // Limit width of each item
                  }}
                >
                  <img
                    src={file?.previewUrl || "/path/to/default-icon.png"} // Use a preview URL or a placeholder
                    alt={file?.name}
                    className="file-icon"
                    style={{
                      border: "2px solid #000740",
                      borderRadius: "10px",
                      padding: "5px",
                      width: "50px", // Adjust the thumbnail size
                      height: "50px",
                      objectFit: "cover",
                    }}
                  />
                  {/* Close Button to remove file */}
                  <button
                    className="btn-close-file"
                    onClick={() => removeFile(index)}
                    style={{
                      position: "absolute",
                      top: "-5px",
                      right: "-5px",
                      background: "#ff4d4d", // Red background for delete
                      border: "none",
                      color: "white",
                      borderRadius: "50%",
                      width: "20px",
                      height: "20px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    &times;
                  </button>
                </div>
              ))}
              {/* Plus Icon to add more files */}
              <button
                className="btn p-0"
                onClick={handleFileButtonClick}
                style={{
                  border: "2px solid #ccc",
                  borderRadius: "10px",
                  padding: "5px",
                  width: "50px",
                  height: "50px",
                  background: "transparent",
                }}
              >
                <i
                  className="bi bi-plus"
                  style={{ color: "#000740", fontSize: "24px" }}
                ></i>
              </button>
              {/* Hidden file input */}
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileUpload}
                style={{ display: "none" }}
                multiple
              />
            </div>
          ) : (
            <div className="flex-grow-1">
              <div className="input-group">
                {/* Input Field */}
                <input
                  type="text"
                  className="form-control rounded-pill"
                  value={newMessage || ""}
                  onChange={(e) => setNewMessage(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      send();
                    }
                  }}
                  placeholder="Type your message here ..."
                  style={{
                    border: "none",
                    backgroundColor: "white",
                    borderRadius: "30px",
                    paddingLeft: "15px",
                    paddingRight: "50px",
                    fontSize: "14px",
                    color: "#6c757d",
                  }}
                />
                {/* Plus Icon triggers file input click */}
                <button
                  className="btn p-0"
                  onClick={handleFileButtonClick}
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    background: "transparent",
                    border: "none",
                  }}
                >
                  <i
                    className="bi bi-image"
                    style={{ color: "#000740", fontSize: "24px" }}
                  ></i>
                </button>
                {/* Hidden file input */}
                <input
                  type="file"
                  ref={fileInputRef}
                  onChange={handleFileUpload}
                  style={{ display: "none" }}
                  multiple
                />
              </div>
            </div>
          )}

          {/* Thumbs Up Button */}
          {/* <button
            className="btn p-0 ms-2"
            onClick={() => {
              console.log("Send button clicked!");
              send();
            }}
            style={{ background: "transparent", border: "none" }}
          >
            <i
              className="bi bi-send send-icon ms-2"
              style={{ color: "#000740", fontSize: "24px" }}
            ></i>
          </button> */}
          <div className="send-button-container" style={{ flexShrink: 0 }}>
            <button
              className="btn p-0 ms-2 "
              onClick={send}
              style={{
                background: "#000740",

                color: "#000740",
                fontSize: "21px",
                width: "30px",
                height: "30px",
                borderRadius: "50%",
              }}
            >
              <i
                className="bi bi-chevron-right"
                style={{
                  fontSize: "20px",
                  color: "white",
                }}
              ></i>
            </button>
          </div>
          {/* Emoji Picker */}
          {showEmojiPicker && (
            <div style={{ position: "absolute", bottom: "50px", left: "10px" }}>
              <EmojiPicker onEmojiClick={onEmojiClick} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ChatWindow;
