import Echo from "laravel-echo";
import Pusher from "pusher-js";

window.Pusher = Pusher;

const echo = new Echo({
  broadcaster: "reverb", 
  key: "dmqfuvafabsl4iw4mgbh", 
  wsHost: "dev-api.cruisemotors.co", 
  wsPort: 8080, 
  wssPort: 8080, 
  forceTLS: true, 
  enabledTransports: [ "wss"], 
  auth: {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("authToken")}`, 
    },
  },
});
export default echo;
