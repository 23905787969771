import React from "react";
import Select from "react-select";
const InputField = ({
  label,
  type = "text", // Default input type is 'text'
  value,
  placeholder,
  name,
  onChange,
  className = "form-control font-13 p-2 ps-3 pe-3 form-control", // Default Bootstrap class for input/select/textarea
  customClass = "", // Custom class prop
  fieldType = "input", // New fieldType prop to differentiate between input, select, textarea
  options = [], // Options for select field
  rows = 3, // Default rows for textarea
  selectOption = "-- Select --", // Default Select Option for regular select
  isMulti = false, // React-select specific prop for multiple selections
  defaultValue = null, // React-select specific default value
  disabled = false
}) => {
  return (
    <div>
      {label && <label className="form-label">{label}</label>}

      {fieldType === "input" && (
        <input
          type={type}
          className={`${className} ${customClass}`} // Combine Bootstrap and custom class
          value={value}
          placeholder={placeholder}
          name={name}
          onChange={onChange}
          disabled={disabled}
        />
      )}

      {fieldType === "select" && (
        <select
          className={`${className} ${customClass}`} // Combine Bootstrap and custom class for select
          value={value}
          name={name}
          onChange={onChange}
        >
          <option value="" selected disabled>
            {selectOption}
          </option>
          {options.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      )}

      {fieldType === "textarea" && (
        <textarea
          className={`${className} ${customClass}`} // Combine Bootstrap and custom class for textarea
          value={value}
          placeholder={placeholder}
          name={name}
          onChange={onChange}
          rows={rows} // Dynamic rows prop for textarea
        />
      )}

      {fieldType === "react-select" && (
        <Select
          options={options} // Options for react-select
          isMulti={isMulti} // Enable multi-select if isMulti is true
          defaultValue={defaultValue} // Default value for react-select
          className={`${customClass}`} // Custom class for react-select
          onChange={onChange} // onChange handler for react-select
          placeholder={placeholder} // Placeholder for react-select
        />
      )}
    </div>
  );
};

export default InputField;
