import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

const RedirectToHome = () => {
    
    const { isAuthenticated } = useSelector((state) => state.auth);

    if (isAuthenticated) {
        // return <Navigate to="/" />; 
        // return <Navigate to="/coming-soon" />;
    }

    return (
        <>
        </>
    );
};

export default RedirectToHome;
