import React, { useState, useEffect } from "react";
import ChatBox from "./ChatBox";
import "../styles/FloatingButton.css";
import { FaWhatsapp } from "react-icons/fa";
const FloatingWhatsapp = () => {
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [unRead, setUnRead] = useState(false);
  const toggleWhatsapp = () => {
    const phoneNumber = '971561331109'; // Phone number in international format (without + or spaces)
    const whatsappLink = `https://wa.me/${phoneNumber}`; // Generates a WhatsApp link for the specified number
    console.log(whatsappLink);
    
    window.open(whatsappLink, "_blank"); // Opens the link in a new tab or window
  };
  //   useEffect(() => {
  //     setUnRead(unread);
  //   }, [unRead, unread]);

  return (
    <>
      <FaWhatsapp
        size={32}
        onClick={toggleWhatsapp}
        style={{
          position: "fixed",
          bottom: "20px",
          left: "20px",
          backgroundColor: "#25D366", // WhatsApp green color
          color: "#fff", // White color for the icon
          borderRadius: "50%",
          width: "45px",
          height: "45px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          cursor: "pointer",
          padding: "5px",
        }}
      />
    </>
  );
};

export default FloatingWhatsapp;
