import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import "../styles/FilterBar.css"; // You can adjust or add styles here
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
import serviceImg from "../assets/images/website icons/service.png";
const FilterBar = () => {
  const location = useLocation(); // Get the current location to apply active styling
  const { t } = useTranslation();
  useEffect(() => {
    const detectedLanguage =
      localStorage.getItem("selectedLanguage") ||
      navigator.language.split("-")[0] ||
      "en";
    i18n.changeLanguage(detectedLanguage);
    document.documentElement.lang = detectedLanguage;
  }, []);
  return (
    <div className="filter-bar mt-3">
      <Link to="/service-kits" className="filter-link w-25">
        <div
          className={`filter-item ${
            location.pathname === "/service-kits" ? "active" : ""
          }`}
        >
          <i className="filter-icon bi bi-tools"></i>
          <span>{t("service_kits")}</span>
        </div>
      </Link>
      <Link to="/spare-parts" className="filter-link w-25">
        <div
          className={`filter-item ${
            location.pathname === "/spare-parts" ? "active" : ""
          }`}
        >
          <i className="filter-icon bi bi-gear"></i>
          <span>{t("spare_parts")}</span>
        </div>
      </Link>
      <Link to="/accessories" className="filter-link w-25">
        <div
          className={`filter-item ${
            location.pathname === "/accessories" ? "active" : ""
          }`}
        >
          <i className="filter-icon bi bi-key"></i>
          <span>{t("accessories")}</span>
        </div>
      </Link>
    </div>
  );
};

export default FilterBar;
