export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
export const SIGN_IN_FAILURE = 'SIGN_IN_FAILURE';
export const SIGN_OUT = 'SIGN_OUT';
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS';
export const SIGN_UP_FAILURE = 'SIGN_UP_FAILURE';
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const TENDOR_SUBMIT = 'TENDOR_SUBMIT';
export const SUPPLY_CONTRACT_SUBMIT = 'SUPPLY_CONTRACT_SUBMIT';
export const REQUEST_QUOTATION_SUBMIT = 'REQUEST_QUOTATION_SUBMIT';
export const MAKE_A_DEAL_SUBMIT = 'MAKE_A_DEAL_SUBMIT';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE';
export const VERIFY_OTP = 'VERIFY_OTP';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const ADVANCE_SIGN_UP_SUCCESS = 'ADVANCE_SIGN_UP_SUCCESS';
export const COMPANY_SIGN_UP_SUCCESS = 'COMPANY_SIGN_UP_SUCCESS';
export const COMPANY_ADVANCE_SIGN_UP_SUCCESS = 'COMPANY_ADVANCE_SIGN_UP_SUCCESS';
export const OFFER_VEHICLE = 'OFFER_VEHICLE';
export const ORDER_VEHICLE = 'ORDER_VEHICLE';
export const GET_ACCESSORIES ='GET_ACCESSORIES';
export const GET_SERVICE_KITS ='GET_SERVICE_KITS';
export const GET_SPARE_PARTS ='GET_SPARE_PARTS';
export const GET_CATEGORIES_API = 'GET_CATEGORIES_API';
export const GET_ALL_BRAND_LIST = 'GET_ALL_BRAND_LIST';
export const SET_WISH_LIST = 'SET_WISH_LIST';
export const GET_CAR_MAKE = 'GET_CAR_MAKE';
export const GET_CAR_MODEL = 'GET_CAR_MODEL';
export const GET_FEATURED_BRAND_LIST = 'GET_FEATURED_BRAND_LIST';
export const GET_ALL_COUNTRY = 'GET_ALL_COUNTRY';
export const GET_FEATURED_BRAND_LIST_WITH_CARS = 'GET_FEATURED_BRAND_LIST_WITH_CARS';
export const SET_USERDATA = "SET_USERDATA";
export const SET_CART_ITEMS = "SET_CART_ITEMS";
export const SET_INSTAGRAM_POSTS = "SET_INSTAGRAM_POSTS";