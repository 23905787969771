import React, { useEffect, useState } from "react";
import companyLogo from "../assets/images/bg-logos/cruise x toyota 7.png";
import alternateLogo from "../assets/images/website icons/wings.png";
import luxuryLogo from "../assets/images/website icons/wingsblack.png";
import benz from "../assets/images/website icons/benz.png";
const BgForBrandModels = ({ url, isBlack }) => {
  const [pageWidth, setPageWidth] = useState(
    document.documentElement.scrollWidth
  );
  const [pageHeight, setPageHeight] = useState(document.body.scrollHeight);
  console.log("jnvjldncvjnd");

  useEffect(() => {
    const handleResize = () => {
      setPageWidth(document.body.scrollWidth); // Total scrollable width
      // setPageHeight(document.documentElement.scrollHeight); // Total scrollable height
      setPageHeight(document.body.scrollHeight);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const isValidUrl = (url) => {
    return url && !url.includes("undefined") && url !== "";
  };

  useEffect(() => {
    // Initialize dimensions after the DOM loads
    const updateDimensions = () => {
      setPageWidth(document.documentElement.scrollWidth); // Set total scrollable width
      setPageHeight(document.body.scrollHeight); // Set total scrollable height
    };

    updateDimensions(); // Call it immediately after component mounts

    window.addEventListener("resize", updateDimensions); // Listen for resize events
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const numRows = Math.ceil(pageHeight / 25); // Number of rows based on scrollable height
  const numColumns = Math.ceil(pageWidth / 30); // Number of columns based on scrollable width

  return (
    <div
      style={{
        width: pageWidth,
        height: pageHeight,
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: -1,
        backgroundColor: "white",
      }}
      className={`${isBlack && "is-luxury-background"}`}
    >
      {Array.from({ length: numRows }).map((_, rowIndex) => (
        <div
          key={rowIndex}
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {Array.from({ length: numColumns }).map((_, colIndex) => {
            const isCompanyLogo =
              (rowIndex % 2 === 0 && colIndex % 2 === 0) || // Even rows, even columns
              (rowIndex % 2 !== 0 && colIndex % 2 !== 0); // Odd rows, odd columns

            return (
              <div
                className="bg-logo-container"
                style={{
                  display: "flex",
                  alignItems: "center",
                  height: "25px",
                  margin: "0px 2px 0px 3px",
                }}
              >
                {!isBlack ? (
                  <img
                    key={`${rowIndex}-${colIndex}`}
                    src={isCompanyLogo && isValidUrl(url) ? url : alternateLogo}
                    alt="pattern"
                    
                    style={{
                      ...(isCompanyLogo
                        ? {
                            width: "29px",
                            height: "18px",
                            objectFit: "contain",
                          } // Style for companyLogo
                        : {
                            width: "33px",
                            height: "25px",
                            objectFit: "cover",
                          }), // Style for alternateLogo
                    }}
                  />
                ) : (
                  <img
                    key={`${rowIndex}-${colIndex}`}
                    src={isCompanyLogo && isValidUrl(url) ? url : luxuryLogo}
                    alt="pattern"
                    className="bg-logo"
                    style={{
                      ...(isCompanyLogo
                        ? {
                          width: "29px",
                          height: "18px",
                          objectFit: "contain",
                          } // Style for companyLogo
                        : {
                          width: "33px",
                          height: "25px",
                          objectFit: "contain",
                          }), // Style for alternateLogo
                    }}
                  />
                )}
              </div>
            );
          })}
        </div>
      ))}
    </div>
  );
};

export default BgForBrandModels;
