// import './ServicePage.css';
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { signOutAction } from "../redux/auth/authActions";
import { Navigate, Link } from "react-router-dom";
import SecondaryHeader from "../components/SecondaryHeader";
import Footer from "../components/Footer";
import banner from "../assets/images/cross-trade.png";
import log1 from "../assets/images/trade1.png";
import log2 from "../assets/images/trade2.png";
import RedirectToHome from "../components/RedirectToHome";
import RedirectToSignin from "../components/RedirectToSignin";
import PrimaryMobileHeader from "../components/PrimaryMobileHeader";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
const CrossTrade = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isMobile, setIsMobile] = useState(false);
  const { isAuthenticated } = useSelector((state) => state.auth);

  const handleSignOut = () => {
    dispatch(signOutAction());
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600); // Define the breakpoint for mobile
    };
    const detectedLanguage =
      localStorage.getItem("selectedLanguage") ||
      navigator.language.split("-")[0] ||
      "en";
    i18n.changeLanguage(detectedLanguage);
    document.documentElement.lang = detectedLanguage;
    // Set initial value
    handleResize();

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="bg-white">
      <RedirectToSignin />
      <RedirectToHome />
      {!isMobile ? <SecondaryHeader /> : <PrimaryMobileHeader />}
      <div className="container">
        <div className="servicesPageList mt-4 pb-5">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-7">
              <img src={banner} alt="Banner" className="img-fluid mb-5" />
              <h2 className="mb-4 text-center">{t("cross_trade")}</h2>
              <div className="row g-4 mb-5 justify-content-center">
                <div className="col-12 col-sm-6">
                  <div className="card rounded-15 border-0">
                    <Link
                      to="/order-vehicle"
                      className="text-decoration-none text-dark"
                    >
                      <div className="card-body p-0">
                        <div className="imgCol position-relative hoverDiv">
                          <img
                            src={log1}
                            className="img-fluid rounded-10"
                            alt="Service 6"
                          />
                          <span className="short-btn rounded-pill bg-white position-absolute rounded-pill bg-white position-absolute p-2 ps-3 pe-3">
                            {t("order_vehicle")}
                          </span>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="col-12 col-sm-6">
                  <div className="card rounded-15 border-0">
                    <Link
                      to="/offer-vehicle"
                      className="text-decoration-none text-dark"
                    >
                      <div className="card-body p-0">
                        <div className="imgCol  position-relative hoverDiv">
                          <img
                            src={log2}
                            className="img-fluid rounded-10 "
                            alt="Service 6"
                          />
                          <span className="short-btn rounded-pill bg-white position-absolute rounded-pill bg-white position-absolute p-2 ps-3 pe-3">
                            {t("offer_vehicle")}
                          </span>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
              <div class="container-text">
                <div class="fade-container">
                  <div class="scrollable-section">
                    <div class="scrollable-content ms-2">
                      <div class="row">
                        {/* <!-- Cross Trade Section --> */}
                        <div class="col-12 pb-3">
                          <h3 class="fw-bold">
                            Cross Trade: Global Vehicle Exchange
                          </h3>
                          <p>
                            Welcome to our Cross Trade platform, where you have
                            the flexibility to either Order Vehicles or Offer
                            Vehicles. Whether you’re looking to expand your
                            fleet or sell your existing stock, we make it
                            simple, secure, and efficient to conduct vehicle
                            trade worldwide.
                          </p>

                          <h4 class="fw-bold">Two Easy Ways to Trade:</h4>
                          <h5 class="fw-bold">1. Order Vehicles:</h5>
                          <p>
                            Looking for the perfect vehicle to meet your
                            business needs? Our Order Vehicles option lets you
                            browse our extensive inventory or place custom
                            orders to get exactly what you’re looking for.
                            Whether it’s a single car or an entire fleet, you
                            can trust us to source high-quality vehicles,
                            tailored to your requirements.
                          </p>
                          <ul>
                            <li>
                              <strong>Custom Orders:</strong> Choose your
                              vehicle type, make, model, and any custom
                              specifications you need.
                            </li>
                            <li>
                              <strong>Competitive Pricing:</strong> We work
                              within your budget to deliver the best value
                              without compromising on quality.
                            </li>
                            <li>
                              <strong>Global Shipping:</strong> Select from a
                              range of shipping options, and let us handle the
                              logistics ensuring safe, on-time delivery.
                            </li>
                          </ul>
                          <p>
                            <strong>
                              Ready to place an order?{" "}
                              <a href="/order-vehicle">[Click here to order your vehicle]</a>{" "}
                              and we’ll handle the rest.
                            </strong>
                          </p>

                          <h5 class="fw-bold">2. Offer Vehicles:</h5>
                          <p>
                            Have vehicles to sell or a business looking to
                            offload your inventory? With our Offer Vehicles
                            option, you can quickly and easily submit your
                            vehicle details, and we’ll provide a fair market
                            offer. Whether you’re selling one vehicle or
                            several, we’ll make sure you get the best possible
                            deal.
                          </p>
                          <ul>
                            <li>
                              <strong>Quick & Simple Form:</strong> Just provide
                              the details about your vehicle(s), and we’ll take
                              it from there.
                            </li>
                            <li>
                              <strong>Fair Market Value:</strong> We assess
                              every offer based on current market trends,
                              ensuring you get a competitive price.
                            </li>
                            <li>
                              <strong>Flexible Partnerships:</strong> Whether
                              you’re looking for a one-time sale or ongoing
                              collaboration, we’re here to work with you.
                            </li>
                          </ul>
                          <p>
                            <strong>
                              Looking to sell?{" "}
                              <a href="offer-vehicle">[Click here to offer your vehicle]</a>{" "}
                              and let’s start the process!
                            </strong>
                          </p>

                          <h4 class="fw-bold">Why Choose Cross Trade?</h4>
                          <ul>
                            <li>
                              <strong>Easy Transactions:</strong> Whether
                              ordering or offering, our processes make it easy
                              for you to focus on what matters—getting the best
                              deal.
                            </li>
                            <li>
                              <strong>Expert Support:</strong> Our team of
                              vehicle specialists is ready to guide you through
                              every step, ensuring a smooth and hassle-free
                              experience.
                            </li>
                            <li>
                              <strong>Global Reach:</strong> With our extensive
                              network, you have the power to trade vehicles
                              across borders, giving you access to markets
                              worldwide.
                            </li>
                          </ul>

                          <h4 class="fw-bold">Ready to Get Started?</h4>
                          <p>
                            Whether you’re looking to order your next vehicle or
                            offer one for sale, our Cross Trade platform is here
                            to help. Take the first step by choosing one of the
                            options above, and let’s make your vehicle trade
                            easier than ever.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CrossTrade;
