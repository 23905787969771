import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  signOutAction,
  submitMakeADealForm,
  getCarMake,
  getCarModel,
} from "../redux/auth/authActions";
import { Navigate, Link } from "react-router-dom";
import SecondaryHeader from "../components/SecondaryHeader";
import Footer from "../components/Footer";
import { Button, message, Upload } from "antd";
import { CarOutlined } from "@ant-design/icons";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import banner from "../assets/images/make-a-deal.png";
import InputField from "../components/InputField";
import RedirectToSignin from "../components/RedirectToSignin";
import RedirectToHome from "../components/RedirectToHome";
import PrimaryMobileHeader from "../components/PrimaryMobileHeader";
import SuccessForm from "./SuccessForm";

import { useTranslation } from "react-i18next";
import i18n from "../i18n";
import CustomSelect from "../components/CustomSelect";
const MakeADeal = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [refId, setRefId] = useState("");
  const [isMobile, setIsMobile] = useState(false);
  const [carMake, setCarMake] = useState([]);
  const [carModel, setCarModel] = useState([]);
  const reduxState = useSelector((state) => state.auth);
  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const userData = user?.data?.user || {};

  const [formData, setFormData] = useState({
    agent_name: "",
    agent_phone: "",
    client_name: "",
    client_phone: "",
    client_address: "",
    car_make_id: "",
    car_model_id: "",
    year: "",
    color: "",
    quantity: "",
    destination: "",
    agent_targeted_amount: "",
    agent_commission_amount: "",
  });

  const [errors, setErrors] = useState({});
  const [charCount, setCharCount] = useState(0);
  const charLimit = 200;
  const [colors, setColors] = useState([]);

  const validate = () => {
    let formErrors = {};

    if (!formData.agent_name)
      formErrors.agent_name = `${t("errors.agent_name")}`;
    if (!formData.agent_phone)
      formErrors.agent_phone = `${t("errors.agent_phone")}`;
    if (!formData.client_name)
      formErrors.client_name = `${t("errors.client_name")}`;
    if (!formData.client_phone)
      formErrors.client_phone = `${t("errors.client_phone")}`;
    if (!formData.client_address)
      formErrors.client_address = `${t("errors.client_address")}`;
    if (!formData.car_make_id)
      formErrors.car_make_id = `${t("errors.car_make_id")}`;
    if (!formData.car_model_id)
      formErrors.car_model_id = `${t("errors.car_model_id")}`;
    if (!formData.year) formErrors.year = `${t("errors.year_id")}`;
    if (!formData.color) formErrors.color = `${t("errors.color")}`;
    if (!formData.quantity) formErrors.quantity = `${t("errors.quantity")}`;
    if (!formData.destination)
      formErrors.destination = `${t("errors.destination")}`;
    if (!formData.agent_targeted_amount)
      formErrors.agent_targeted_amount = `${t("errors.agent_targeted_amount")}`;
    if (!formData.agent_commission_amount)
      formErrors.agent_commission_amount = `${t(
        "errors.agent_commission_amount"
      )}`;

    return formErrors;
  };
  const fetchColors = async () => {
    try {
      const response = await fetch("https://www.csscolorsapi.com/api/colors");

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setColors(data?.colors);
    } catch (error) {
      console.error("Error fetching colors:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "comments" && value?.length > charLimit) {
      return; // Prevent exceeding character limit
    }
    if (name === "car_make_id") {
      const selectedBrand = carMake?.find(
        (brand) => brand?.id === parseInt(value)
      );
      if (selectedBrand) {
        const brandSlug = selectedBrand.slug;
        fetchCarModel(brandSlug); // Call fetchCarModel with the selected brand id
      } else {
        console.error("Brand not found for the given id");
      }
    }

    setFormData({
      ...formData,
      [name]: value,
    });

    if (name === "comments") {
      setCharCount(value.length); // Update character count for comments
    }
  };

  const handleAgentPhoneChange = (value) => {
    setFormData({
      ...formData,
      agent_phone: "+" + value,
    });
  };
  const handleClientPhoneChange = (value) => {
    setFormData({
      ...formData,
      client_phone: value,
    });
  };

  const fetchCarMake = async () => {
    const response = await dispatch(getCarMake());
    setCarMake(response?.data);
  };

  const fetchCarModel = async (id) => {
    const response = await dispatch(getCarModel(id));
    setCarModel(response?.data);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formErrors = validate();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }
    if (formData.person_email) {
      delete formData.person_email; // Remove person_email if it exists
    }
    if (formData.person_designation) {
      delete formData.person_designation; // Remove person_email if it exists
    }

    const response = await dispatch(submitMakeADealForm(formData));
    if (response?.code == 200) {
      message.success("Submitted successfully!");
      setRefId(response?.data?.code);
      setFormSubmitted(true);
    } else {
      message.error(response?.message || "Submission failed!");
    }
  };

  const handleSignOut = () => {
    dispatch(signOutAction());
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600); // Define the breakpoint for mobile
    };
    fetchColors();
    const detectedLanguage =
      localStorage.getItem("selectedLanguage") ||
      navigator.language.split("-")[0] ||
      "en";
    i18n.changeLanguage(detectedLanguage);
    document.documentElement.lang = detectedLanguage;
    if (userData) {
      // If user is a company, fill both user and company details
      if (userData.company) {
        setFormData((prevData) => ({
          ...prevData,
          company_name: userData.company.name || "", // Fill company name
          company_address: userData.company.address || "", // Fill company address
          agent_name: userData.name || "", // Fill person name from userData
          agent_phone: userData.phone || "", // Fill person phone from userData
        }));
      } else {
        // If user is not a company, fill only personal details
        setFormData((prevData) => ({
          ...prevData,
          agent_name: userData.name || "", // Fill person name from userData
          agent_phone: userData.phone || "", // Fill person phone from userData
        }));
      }
    }

    // Set initial value
    handleResize();
    reduxState?.carMake ? setCarMake(reduxState?.carMake) : fetchCarMake();

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="bg-white">
      <RedirectToSignin />
      <RedirectToHome />
      {!isMobile ? <SecondaryHeader /> : <PrimaryMobileHeader />}
      <div className="container">
        <div className="tendorsPage mt-4 pb-5">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-7">
              <img src={banner} alt="Banner" className="img-fluid mb-5" />
              {formSubmitted ? (
                <SuccessForm
                  refId={refId}
                  resetForm={() => {
                    setFormSubmitted(false);
                  }}
                />
              ) : (
                <form onSubmit={handleSubmit}>
                  <div className="card rounded-10 bg-white border-0 mb-5">
                    <div className="card-body p-5">
                      <h2 className="mb-4 text-center">
                        {t("make_a_deal_heading")}
                      </h2>

                      <h5 className="text-start mb-3 d-flex justify-content-start align-items-center">
                        <i className="bi bi-person me-2"></i>{" "}
                        <span>{t("agent_details")}</span>
                      </h5>
                      <div className="row g-3 mb-4 text-start">
                        <div className="col-12 col-sm-6">
                          <InputField
                            name="agent_name"
                            value={formData.agent_name}
                            onChange={handleChange}
                            placeholder={`${t("agent_name")}*`}
                            customClass={errors.agent_name ? "is-invalid" : ""}
                          />
                          {errors.agent_name && (
                            <div className="text-danger font-13">
                              {errors.agent_name}
                            </div>
                          )}
                        </div>
                        <div className="col-12 col-sm-6">
                          <div className="text-start">
                            <PhoneInput
                              country={"ae"}
                              excludeCountries={["il"]}
                              name="agent_phone"
                              value={formData.agent_phone}
                              className="w-100"
                              onChange={handleAgentPhoneChange}
                              inputClass={`form-control ${
                                errors.agent_phone ? "is-invalid" : ""
                              }`}
                              masks={{ ae: ".. ... ...." }}
                              enableSearch={true}
                            />
                            {errors.agent_phone && (
                              <div className="text-danger font-13">
                                {errors.agent_phone}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <h5 className="text-start mb-3 d-flex justify-content-start align-items-center">
                        <i className="bi bi-person me-2"></i>{" "}
                        <span>{t("client_details")}</span>
                      </h5>
                      <div className="row g-3 mb-4 text-start">
                        <div className="col-12 col-sm-6">
                          <InputField
                            name="client_name"
                            value={formData.client_name}
                            onChange={handleChange}
                            placeholder={`${t("client_name")}*`}
                            customClass={errors.client_name ? "is-invalid" : ""}
                          />
                          {errors.client_name && (
                            <div className="text-danger font-13">
                              {errors.client_name}
                            </div>
                          )}
                        </div>
                        <div className="col-12 col-sm-6">
                          <div className="text-start">
                            <PhoneInput
                              country={"ae"}
                              excludeCountries={["il"]}
                              name="client_phone"
                              value={formData.client_phone}
                              className="w-100"
                              onChange={handleClientPhoneChange}
                              inputClass={`form-control ${
                                errors.client_phone ? "is-invalid" : ""
                              }`}
                            />
                            {errors.client_phone && (
                              <div className="text-danger font-13">
                                {errors.client_phone}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-12 col-sm-12">
                          <InputField
                            name="client_address"
                            value={formData.client_address}
                            onChange={handleChange}
                            placeholder={`${t("location")}*`}
                            customClass={
                              errors.client_address ? "is-invalid" : ""
                            }
                          />
                          {errors.client_address && (
                            <div className="text-danger font-13">
                              {errors.client_address}
                            </div>
                          )}
                        </div>
                      </div>

                      <h5 className="text-start mb-3 d-flex justify-content-start align-items-center">
                        <CarOutlined className="me-2" />{" "}
                        <span>{t("car_details")}</span>
                      </h5>
                      <div className="row g-3 mb-4 text-start">
                        {/* <div className="col-12 col-sm-6">
                          <InputField
                            fieldType="select"
                            selectOption={`${t("make")}*`}
                            name="car_make_id"
                            value={formData.car_make_id}
                            onChange={handleChange}
                            options={carMake?.map((make) => ({
                              value: make.id, // Using the car make ID as the value
                              label: make.name, // Using the car make name as the label
                            }))}
                            customClass={
                              errors.car_make_id
                                ? "form-select is-invalid"
                                : "form-select"
                            }
                          />
                          {errors.car_make_id && (
                            <div className="text-danger font-13">
                              {errors.car_make_id}
                            </div>
                          )}
                        </div>
                        <div className="col-12 col-sm-6">
                          <InputField
                            fieldType="select"
                            selectOption={`${t("model")}*`}
                            name="car_model_id"
                            value={formData.car_model_id}
                            onChange={handleChange}
                            options={carModel?.map((make) => ({
                              value: make.id, // Using the car make ID as the value
                              label: make.name, // Using the car make name as the label
                            }))}
                            customClass={
                              errors.car_model_id
                                ? "form-select is-invalid"
                                : "form-select"
                            }
                          />
                          {errors.car_model_id && (
                            <div className="text-danger font-13">
                              {errors.car_model_id}
                            </div>
                          )}
                        </div>
                        <div className="col-12 col-sm-6">
                          <InputField
                            fieldType="select"
                            selectOption={`${t("year")}*`}
                            name="year"
                            value={formData.year}
                            onChange={handleChange}
                            options={[
                              { value: "2023", label: "2023" },
                              { value: "2024", label: "2024" },
                            ]}
                            customClass={
                              errors.color
                                ? "form-select is-invalid"
                                : "form-select"
                            }
                          />
                          {errors.year && (
                            <div className="text-danger font-13">
                              {errors.year}
                            </div>
                          )}
                        </div> */}
                        <div className="col-12 col-sm-6">
                          <CustomSelect
                            name="car_make_id"
                            options={carMake?.map((make) => ({
                              value: make.id, // Using the car make ID as the value
                              label: make.name, // Using the car make name as the label
                            }))}
                            value={formData.car_make_id} // Pass the selected value
                            onChange={handleChange} // Handle change for make
                            placeholder={`${t("make")}*`} // Placeholder text for the select input
                            customClass={
                              errors.car_make_id
                                ? "is-invalid custom-select"
                                : "custom-select"
                            }
                          />
                          {errors.car_make_id && (
                            <div className="text-danger font-13">
                              {errors.car_make_id}
                            </div>
                          )}
                        </div>

                        <div className="col-12 col-sm-6">
                          <CustomSelect
                            name="car_model_id"
                            options={carModel?.map((make) => ({
                              value: make.id, // Using the car model ID as the value
                              label: make.name, // Using the car model name as the label
                            }))}
                            value={formData.car_model_id} // Pass the selected value
                            onChange={handleChange} // Handle change for model
                            placeholder={`${t("model")}*`} // Placeholder text for the select input
                            customClass={
                              errors.car_model_id
                                ? "is-invalid custom-select"
                                : "custom-select"
                            }
                          />
                          {errors.car_model_id && (
                            <div className="text-danger font-13">
                              {errors.car_model_id}
                            </div>
                          )}
                        </div>

                        <div className="col-12 col-sm-6">
                          <CustomSelect
                            name="year"
                            options={[
                              { value: "2024", label: "2024" },
                              { value: "2023", label: "2023" },
                              { value: "2022", label: "2022" },
                              { value: "2021", label: "2021" },
                            ]}
                            value={formData.year} // Pass the selected value
                            onChange={handleChange} // Handle change for year
                            placeholder={`${t("year")}*`} // Placeholder text for the select input
                            customClass={
                              errors.year
                                ? "is-invalid custom-select"
                                : "custom-select"
                            }
                          />
                          {errors.year && (
                            <div className="text-danger font-13">
                              {errors.year}
                            </div>
                          )}
                        </div>
                        <div className="col-12 col-sm-6">
                          <CustomSelect
                            name="color"
                            options={colors?.map((color) => ({
                              value: color?.name, // Using the color name as the value
                              label: color?.name, // Using the color name as the label
                            }))}
                            value={formData.color} // Pass the selected color value
                            onChange={handleChange} // Handle change for color
                            placeholder={`${t("color")}*`} // Placeholder text for the select input
                            customClass={
                              errors.color
                                ? "is-invalid custom-select"
                                : "custom-select"
                            }
                          />
                          {errors.color && (
                            <div className="text-danger font-13">
                              {errors.color}
                            </div>
                          )}
                        </div>
                        {/* <div className="col-12 col-sm-6">
                          <InputField
                            fieldType="select"
                            selectOption={`${t("color")}*`}
                            name="color"
                            value={formData.color}
                            onChange={handleChange}
                            options={colors?.map((color) => ({
                              value: color?.name, // Using the car make ID as the value
                              label: color?.name, // Using the car make name as the label
                            }))}
                            customClass={
                              errors.color
                                ? "form-select is-invalid"
                                : "form-select"
                            }
                          />
                          {errors.color && (
                            <div className="text-danger font-13">
                              {errors.color}
                            </div>
                          )}
                        </div> */}
                        <div className="col-12 col-sm-6">
                          <InputField
                            name="quantity"
                            value={formData.quantity}
                            onChange={handleChange}
                            placeholder={`${t("quantity")}*`}
                            customClass={errors.quantity ? "is-invalid" : ""}
                          />
                          {errors.color && (
                            <div className="text-danger font-13">
                              {errors.color}
                            </div>
                          )}
                        </div>
                        <div className="col-12 col-sm-6">
                          <InputField
                            name="destination"
                            value={formData.destination}
                            onChange={handleChange}
                            placeholder={`${t("destination")}*`}
                            customClass={errors.destination ? "is-invalid" : ""}
                          />
                          {errors.destination && (
                            <div className="text-danger font-13">
                              {errors.destination}
                            </div>
                          )}
                        </div>
                        <div className="col-12 col-sm-6">
                          <InputField
                            name="agent_targeted_amount"
                            value={formData.agent_targeted_amount}
                            onChange={handleChange}
                            placeholder={`${t("agent_targeted_amount")}*`}
                            customClass={
                              errors.agent_targeted_amount ? "is-invalid" : ""
                            }
                          />
                          {errors.agent_targeted_amount && (
                            <div className="text-danger font-13">
                              {errors.agent_targeted_amount}
                            </div>
                          )}
                        </div>
                        <div className="col-12 col-sm-6">
                          <InputField
                            name="agent_commission_amount"
                            value={formData.agent_commission_amount}
                            onChange={handleChange}
                            placeholder="Agent’s Commission Amount *"
                            customClass={
                              errors.agent_commission_amount ? "is-invalid" : ""
                            }
                          />
                          {errors.agent_commission_amount && (
                            <div className="text-danger font-13">
                              {errors.agent_commission_amount}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="row g-3 mb-4 text-center">
                        <div className="col-12 col-sm-12">
                          <button
                            type="submit"
                            className="btn btn-primary btn-lg ps-5 pe-5 font-15 mb-3"
                          >
                            {t("submit")}
                          </button>
                          <p className="m-0 text-muted font-15">
                            {t("respond_time")}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default MakeADeal;
