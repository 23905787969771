import Select from "react-select";
import React from "react";

const customStyles = {
  control: (base) => ({
    ...base,
    backgroundColor: "#efefef",
    borderRadius: "8px",
    border: "1px solid #ced4da",
    boxShadow: "none",
    padding: "4px 10px",
    minHeight: "40px",
    lineHeight: 0.4,
    "&:hover": {
      borderColor: "#ced4da",
    },
  }),
  placeholder: (base) => ({
    ...base,
    fontSize: "14px",
    color: "#6c757d",
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: "#6c757d",
    padding: "4px",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  singleValue: (base) => ({
    ...base,
    lineHeight: "1.5",
    fontSize: "14px",
    color: "#495057",
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isFocused ? "#f1f3f4" : "#fff",
    color: "#495057",
    padding: "10px",
    fontSize: "14px",
  }),
};

const CustomSelect = ({
  name,
  options,
  value,
  placeholder,
  onChange,
  customClass,
}) => (
  <Select
    options={options}
    value={options?.find((option) => option.value === value)}
    onChange={(selectedOption) => {
      onChange({
        target: {
          name,
          value: selectedOption?.value,
        },
      });
    }}
    styles={customStyles}
    placeholder={placeholder}
    className={customClass}
  />
);

export default CustomSelect;
