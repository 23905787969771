// src/i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

// EN
import enSignIn from "./locales/en/signIn.json";
import enSignUpIndividual from "./locales/en/signUpIndividual.json";
import enSignUpAdvanceIndividual from "./locales/en/signUpAdvanceIndividual.json";
import enSignUpAsCompany from "./locales/en/signUpAsCompany.json";
import enSignUpAsAdvanceCompany from "./locales/en/signUpAsAdvanceCompany.json";
import enHomePage from "./locales/en/HomePage.json";
// ES
import esSignIn from "./locales/es/signIn.json";
import esSignUpIndividual from "./locales/es/signUpIndividual.json";
import esSignUpAdvanceIndividual from "./locales/es/signUpAdvanceIndividual.json";
import esSignUpAsCompany from "./locales/es/signUpAsCompany.json";
import esSignUpAsAdvanceCompany from "./locales/es/signUpAsAdvanceCompany.json";
import esHomePage from "./locales/es/HomePage.json";

import frHomePage from "./locales/fr/HomePage.json";
import jaHomePage from "./locales/ja/HomePage.json";
import chHomePage from "./locales/ch/HomePage.json";
import deHomePage from "./locales/de/HomePage.json";
import ptHomePage from "./locales/pt/HomePage.json";
import ruHomePage from "./locales/ru/HomePage.json";
import trHomePage from "./locales/tr/HomePage.json";
import arHomePage from "./locales/ar/HomePage.json";
// Combine translations for each language
const resources = {
  en: {
    translation: {
      ...enHomePage,
      // ...enPrimaryHeader,
      // ...enPrimaryMobileHeader,
      // ...enServicePage.cross_trade,
      // ...enLogistics
    },
  },
  es: {
    translation: {
      ...esSignIn,
      ...esSignUpIndividual,
      ...esSignUpAdvanceIndividual,
      ...esSignUpAsCompany,
      ...esSignUpAsAdvanceCompany,
      ...esHomePage,
      // ...esPrimaryHeader,
      // ...esPrimaryMobileHeader,
      // ...esServicePage,
      // ...esLogistics
    },
  },
  fr: {
    translation: {
      ...frHomePage,
    },
  },
  ja: {
    translation: {
      ...jaHomePage,
    },
  },
  ch: {
    translation: {
      ...chHomePage,
    },
  },
  de: {
    translation: {
      ...deHomePage,
    },
  },
  pt: {
    translation: {
      ...ptHomePage,
    },
  },
  ru: {
    translation: {
      ...ruHomePage,
    },
  },
  tr: {
    translation: {
      ...trHomePage,
    },
  },
  ar: {
    translation: {
      ...arHomePage,
    },
  },
  // Add other languages here
};

i18n
  .use(LanguageDetector) // Add language detector
  .use(initReactI18next)
  .init({
    resources,
    lng: localStorage.getItem("selectedLanguage") || "en", // Default language from localStorage or fallback
    fallbackLng: "en", // Default fallback language
    interpolation: {
      escapeValue: false, // React already does escaping
    },
    detection: {
      order: ["localStorage", "navigator"], // Order of language detection
      caches: ["localStorage"], // Cache selected language in localStorage
    },
  });

// Detect and set the language initially
const initialLanguage =
  localStorage.getItem("selectedLanguage") || i18n.language || "en";
i18n.changeLanguage(initialLanguage);

export default i18n;
