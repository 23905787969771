import React, { useEffect, useState, useRef } from "react";
import ProductCard from "./ProductCard";
import SearchFilter from "./SearchFilter";
import { RotatingLines } from "react-loader-spinner";
import loaderLogo from "../assets/images/CM LOGO DARK BLUE.png";
import { Button } from "react-bootstrap";
import { BsGrid, BsList, BsLayoutTextWindowReverse } from "react-icons/bs";
import ProductListView from "./ProductListView";
const ProductGrid = ({
  products,
  btnText,
  car_make_slug,
  car_model_slug,
  vin,
  interiorColor,
  exteriorColor,
  steeringProp,
  fromPage,
  fetchCars,
  isLuxury,
}) => {
  const [viewMode, setViewMode] = useState("grid");
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [sortDropdownVisible, setSortDropdownVisible] = useState(false);
  const perPageOptions = [50, 100, 150, 200, 250];
  const [selectedPerPage, setSelectedPerPage] = useState(50);
  const [selectedSort, setSelectedSort] = useState("new");
  const sortOptions = [
    { id: 1, label: "Newest Items First", value: "new" },
    { id: 6, label: "From A to Z", value: "a-z" },
    { id: 2, label: "From Z to A", value: "z-a" },
    { id: 3, label: "Price: Low to High", value: "l-h" },
    { id: 4, label: "Price: High to Low", value: "h-l" },
    // { id: 5, label: "Popular First", value: "new" },
  ];
  const [dropdownPosition, setDropdownPosition] = useState({ left: 0, top: 0 });
  const dropdownRef = useRef();
  const handleDropdownPosition = (ref) => {
    if (ref && ref.current) {
      const rect = ref.current.getBoundingClientRect();
      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;

      // Adjust left and top to keep dropdown inside the screen
      const left =
        rect.right > viewportWidth ? viewportWidth - rect.width : rect.left;
      const top =
        rect.bottom > viewportHeight ? rect.top - rect.height : rect.bottom;

      setDropdownPosition({ left, top });
    }
  };
  useEffect(() => {
    if (dropdownVisible && dropdownRef.current) {
      handleDropdownPosition(dropdownRef);
    }
  }, [dropdownVisible]);
  const handlePerPageChange = (limit) => {
    setDropdownVisible(false);
    setSelectedPerPage(limit); // Update state
    fetchCars(1, limit, selectedSort); // Call the parent function with updated params
  };

  const handleSortChange = (order_by) => {
    setSelectedSort(order_by); // Update state
    setSortDropdownVisible(false);
    fetchCars(1, selectedPerPage, order_by); // Call the parent function with updated params
  };
  // const handleScroll = () => {
  //   const { scrollTop, scrollHeight, clientHeight } = document.documentElement;

  //   if (scrollTop + clientHeight >= scrollHeight - 50) {
  //     loadMoreCars();
  //   }
  // };

  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 800); // Define the breakpoint for mobile
    };

    // Set initial value
    handleResize();

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      {fromPage !== "Sales" && (
        <>
          <SearchFilter
            car_make_slug={car_make_slug}
            car_model_slug={car_model_slug}
            vin={vin}
            interiorColorFromProps={interiorColor}
            exteriorColorFromProps={exteriorColor}
            steeringProp={steeringProp}
            isLuxury={isLuxury}
          />
          <div className="view-options d-flex justify-content-end align-items-center gap-2 mb-2">
            <Button
              variant="outline-secondary"
              className={`icon-button-view ${
                viewMode === "grid" ? "active" : ""
              } ${isLuxury ? "luxury-icon-button-view" : "bg-white"}`}
              onClick={() => setViewMode("grid")}
            >
              <BsGrid />
            </Button>
            <Button
              variant="outline-secondary"
              className={`icon-button-view ${
                viewMode === "list" ? "active" : ""
              } ${isLuxury ? "luxury-icon-button-view" : "bg-white"}`}
              onClick={() => setViewMode("list")}
            >
              <BsList />
            </Button>
            {!isMobile && (
              <Button
                variant="outline-secondary"
                onClick={() => setViewMode("list-split")}
                className={`icon-button-view ${
                  viewMode === "list-split" ? "active" : ""
                } ${isLuxury ? "luxury-icon-button-view" : "bg-white"}`}
              >
                <BsLayoutTextWindowReverse />
              </Button>
            )}

            <div
              style={{ position: "relative", display: "inline-block" }}
              // onMouseEnter={() => setDropdownVisible(true)}
              onMouseEnter={() => {
                setDropdownVisible(true);
                handleDropdownPosition(dropdownRef);
              }}
              onMouseLeave={() => setDropdownVisible(false)}
            >
              {/* Button */}
              <Button variant="outline-secondary" className={isLuxury ? "luxury-icon-button-view icon-button-view" : "icon-button-view bg-white"}>
                Show
              </Button>

              {/* Dropdown Card */}
              {dropdownVisible && (
                <div
                  ref={dropdownRef}
                  className="dropdown-card shadow-sm"
                  style={{
                    position: "absolute",
                    top: "100%", // Position below the button
                    left: "0",
                    background: "#fff",
                    borderRadius: "10px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    zIndex: 999,
                    minWidth: "150px",
                    padding: "10px",
                  }}
                >
                  <div
                    className="d-flex justify-content-between align-items-center"
                    style={{
                      paddingBottom: "5px",
                      borderBottom: "1px solid #ddd",
                      marginBottom: "10px",
                    }}
                  >
                    <span className="fw-bold">{selectedPerPage} Per Page</span>
                    <button
                      className="btn p-0"
                      onClick={() => setDropdownVisible(false)}
                      style={{
                        background: "transparent",
                        border: "none",
                        fontSize: "16px",
                        cursor: "pointer",
                      }}
                    >
                      <i className="bi bi-x-lg"></i>{" "}
                      {/* Bootstrap close icon */}
                    </button>
                  </div>
                  {/* Dropdown Items */}
                  <ul
                    style={{
                      listStyle: "none",
                      padding: 0,
                      margin: 0,
                    }}
                  >
                    {perPageOptions.map((option, index) => (
                      <li
                        key={index}
                        style={{
                          padding: "8px 10px",
                          cursor: "pointer",
                          borderRadius: "5px",
                        }}
                        className="dropdown-item"
                        onClick={() => handlePerPageChange(option)}
                        // onClick={() => {
                        //   setSelectedPerPage(option); // Update the selected number
                        //   setDropdownVisible(false); // Close the dropdown
                        // }}

                        onMouseEnter={(e) => (
                          (e.target.style.backgroundColor = "#000740"),
                          (e.target.style.color = "white")
                        )}
                        onMouseLeave={(e) => (
                          (e.target.style.backgroundColor = "transparent"),
                          (e.target.style.color = "black")
                        )}
                      >
                        {option} Per Page
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
            <div
              style={{ position: "relative", display: "inline-block" }}
              onMouseEnter={() => setSortDropdownVisible(true)}
              onMouseLeave={() => setSortDropdownVisible(false)}
            >
              {/* Button */}
              <Button variant="outline-secondary" className={isLuxury ? "luxury-icon-button-view icon-button-view" : "icon-button-view bg-white"}>
                Sort
              </Button>

              {/* Dropdown Card */}
              {sortDropdownVisible && (
                <div
                  className="dropdown-card shadow-sm"
                  style={{
                    position: "absolute",
                    top: "100%", // Position below the button
                    right: "10%",
                    background: "#fff",
                    borderRadius: "10px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    zIndex: 999,
                    minWidth: "150px",
                    padding: "10px",
                  }}
                >
                  <div
                    className="d-flex justify-content-between align-items-center"
                    style={{
                      paddingBottom: "5px",
                      borderBottom: "1px solid #ddd",
                      marginBottom: "10px",
                    }}
                  >
                    <span className="fw-bold">Sort by</span>
                    <button
                      className="btn p-0"
                      onClick={() => setSortDropdownVisible(false)}
                      style={{
                        background: "transparent",
                        border: "none",
                        fontSize: "16px",
                        cursor: "pointer",
                      }}
                    >
                      <i className="bi bi-x-lg"></i>{" "}
                      {/* Bootstrap close icon */}
                    </button>
                  </div>
                  {/* Dropdown Items */}
                  <ul
                    style={{
                      listStyle: "none",
                      padding: 0,
                      margin: 0,
                    }}
                  >
                    {sortOptions.map((option, index) => (
                      <li
                        key={index}
                        style={{
                          padding: "8px 10px",
                          cursor: "pointer",
                          borderRadius: "5px",
                        }}
                        className="dropdown-item"
                        onClick={() => handleSortChange(option?.value)}
                        // onClick={() => {
                        //   setSelectedSort(option); // Update the selected number
                        //   setSortDropdownVisible(false); // Close the dropdown
                        // }}
                        onMouseEnter={(e) => (
                          (e.target.style.backgroundColor = "#000740"),
                          (e.target.style.color = "white")
                        )}
                        onMouseLeave={(e) => (
                          (e.target.style.backgroundColor = "transparent"),
                          (e.target.style.color = "black")
                        )}
                      >
                        {option?.label}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
            {/* <Button variant="outline-secondary" className="icon-button-view">
              Sort By
            </Button> */}
          </div>
        </>
      )}
      {products?.length === 0 ? (
        <div className="d-flex justify-content-center align-items-center vh-100">
          <h3>No Cars Found</h3>
        </div>
      ) : (
        <div
          className={`${viewMode === "grid" ? "row g-2 g-sm-3 mb-5" : ""} ${
            isLuxury ? "luxury-product" : ""
          }`}
        >
          {/* {products?.map((product) => (
            <div
              className={
                viewMode === "grid" ? "col-12 col-sm-4 col-lg-3" : "col-12"
              }
              key={product.id}
            >
              {viewMode === "grid" ? (
                <ProductCard productData={product} btnText={btnText} />
              ) : viewMode === "list" ? (
                <ProductListView productData={product} btnText={btnText} />
              ) : (
                <div className="col-6">
                  <ProductListView productData={product} btnText={btnText} />
                </div>
              )}
            </div>
          ))} */}
          {viewMode === "list-split"
            ? products
                .reduce((rows, product, index) => {
                  // Group every two items
                  if (index % 2 === 0) {
                    rows.push([product]);
                  } else {
                    rows[rows.length - 1].push(product);
                  }
                  return rows;
                }, [])
                .map((row, rowIndex) => (
                  <div className="row mb-3" key={rowIndex}>
                    {row.map((product) => (
                      <div
                        className="col-6 single-product-list-view"
                        key={product.id}
                      >
                        <ProductListView
                          productData={product}
                          btnText={btnText}
                        />
                      </div>
                    ))}
                  </div>
                ))
            : products.map((product) => (
                <div
                  className={
                    viewMode === "grid" ? "col-12 col-sm-4 col-lg-3" : "col-12"
                  }
                  key={product.id}
                >
                  {viewMode === "grid" ? (
                    <ProductCard productData={product} btnText={btnText} />
                  ) : (
                    <ProductListView productData={product} btnText={btnText} />
                  )}
                </div>
              ))}
        </div>
      )}
    </>
  );
};

export default ProductGrid;
