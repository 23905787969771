import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { Badge, Card } from "antd";
import { bookCar } from "../redux/auth/authActions";
import { API_URL } from "../api/authApi";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
import imgPlaceholder from "../assets/images/img-placeholder.png";
import "./ProductListView.css";

const { Meta } = Card;

const ProductListView = ({ productData, btnText }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const api_url = API_URL.replace("/api", "");
  const thumbnailImage = productData?.images?.find((img) => img?.is_thumbnail === 1);
  const thumbUrl = thumbnailImage ? `${api_url}/${thumbnailImage?.image?.path}` : imgPlaceholder;

  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const userData = user?.data?.user || {};

  // Set Car Name
  const setNameCar = () => {
    const {
      steering_type,
      name,
      model,
      engine_size,
      fuel_type,
      transmission,
      ext_color,
      production_year,
    } = productData || {};
    const transmissionLabel = transmission === "Automatic" ? "AT" : "ML";
    return `${steering_type ? `(${steering_type})` : ""} ${name || ""} ${model || ""} ${
      engine_size || ""
    } ${fuel_type || ""} ${transmissionLabel || ""} ${ext_color || ""} - ${
      production_year ? `MY${production_year}` : ""
    }`.trim();
  };

  // Detect and Set Language
  useEffect(() => {
    const detectedLanguage = localStorage.getItem("selectedLanguage") || navigator.language.split("-")[0] || "en";
    i18n.changeLanguage(detectedLanguage);
    document.documentElement.lang = detectedLanguage;
  }, []);

  // Handle Book Car
  const handleBookCar = (e, id) => {
    e.stopPropagation();
    Swal.fire({
      title: t("Ready to Book Your Car?"),
      text: t("We will reserve the car for you once you confirm."),
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#4e3DBD",
      cancelButtonColor: "#d33",
      confirmButtonText: t("Yes, Book it!"),
      cancelButtonText: t("No, Cancel"),
      background: "#f7f9fc",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await dispatch(bookCar(id));
        if (response?.code === 200) {
          const refId = response?.data?.code;
          const email = userData?.email;

          Swal.fire({
            title: t("booked"),
            html: `<p>${t("booking_pop_1")}<strong>${email}</strong>.</p>
                   <p>${t("booking_pop_2")}<strong>${refId}</strong> ${t("booking_pop_3")}</p>
                   <p>${t("booking_pop_4")}</p>
                   <p><strong>${t("booking_pop_5")}</strong></p>`,
            icon: "success",
            confirmButtonText: t("Go To Modification"),
            confirmButtonColor: "#000740",
            timer: 5000,
            timerProgressBar: true,
            willClose: () => {
              navigate(`/modifications?car=${productData?.slug}`, { state: { slug: productData?.slug } });
            },
          }).then((result) => {
            if (result.isConfirmed) {
              navigate(`/modifications?car=${productData?.slug}`, { state: { slug: productData?.slug } });
            }
          });
        }
      }
    });
  };

  // Handle Card Click to Go to Product Detail
  const handleCardClick = () => {
    navigate(`/car-specification?car=${productData?.slug}`);
  };

  // Download PDF
  const downloadPdf = (e, data) => {
    e.stopPropagation();
    const fileUrl = `${api_url}/${data?.[0]?.file?.path}`;
    window.open(fileUrl, "_blank");
  };

  return (
    <div className="product-list-view-container d-flex align-items-center p-3 mb-2">
      <Badge.Ribbon text="New" placement="start" color="#1677ff">
      <Card
          className="w-100 h-100 text-center rounded-3 border-0 shadow col-12 list-view-card"
          hoverable
          onClick={handleCardClick}
          cover={
            <img
              alt={productData?.name || "Product Image"}
              src={thumbUrl}
              className="product-list-view-image"
            
            />
          }
          // actions={[
          //   <SettingOutlined key="setting" />,
          //   <EditOutlined key="edit" />,
          //   <EllipsisOutlined key="ellipsis" />,
          // ]}
        >
            </Card>
      </Badge.Ribbon>
      <div className="product-info ms-1">
        <h5 className="product-title">{productData?.name}</h5>
        <div className="product-details d-flex text-muted custom-border">
          <span className="pe-2 custom-border-item">{productData?.steering_type }</span>
          <span className="pe-2 custom-border-item">{productData?.production_year }</span>
          <span className="custom-border-item">{productData?.ext_color || "White"}</span>
        </div>
        <p className="product-description mt-2 mb-4">
          {productData.description }
        </p>
        <button
          type="button"
          className="btn btn-primary product-list-view-button"
          style={{ backgroundColor: "#000740", width: "200px" }}
          onClick={(e) => handleBookCar(e, productData?.id)}
        >
          {btnText || t("book")}
        </button>
      </div>
    </div>
  );
};

export default ProductListView;
