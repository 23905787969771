import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  signOutAction,
  orderVehicleForm,
  getPaymentMethod,
  getCarMake,
  getCarModel,
  getCountry,
  getState,
  getCity,
  getPort,
} from "../redux/auth/authActions";
import { Navigate, Link } from "react-router-dom";
import SecondaryHeader from "../components/SecondaryHeader";
import Footer from "../components/Footer";
import banner from "../assets/images/logistics.png";
import log1 from "../assets/images/log1.png";
import log2 from "../assets/images/log2.png";
import { Button, InputGroup, FormControl } from "react-bootstrap";
import { message, Upload } from "antd";
import InputField from "../components/InputField";
import RedirectToSignin from "../components/RedirectToSignin";
import RedirectToHome from "../components/RedirectToHome";
import PhoneInput from "react-phone-input-2";
import DatePicker from "react-datepicker";
import PrimaryMobileHeader from "../components/PrimaryMobileHeader";
import SuccessForm from "./SuccessForm";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
import CustomSelect from "../components/CustomSelect";
const OrderVehicle = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isMobile, setIsMobile] = useState(false);
  const { isAuthenticated } = useSelector((state) => state.auth);
  const reduxState = useSelector((state) => state.auth);
  const [rows, setRows] = useState([
    { quantity: 1, car_make_id: "", car_model_id: "", year: "" },
  ]);
  const [formData, setFormData] = useState({
    client_name: "",
    client_phone: "",
    designation: "",
    // location: "",
    country_id: "",
    state_id: "",
    city_id: "",
    port_id: "",
    company_name: "",
    interior_color: "",
    exterior_color: "",
    product_date_time: "",
    engine_size: "",
    fuel_type: "",
    steering: "",
    delivery_date_time: "",
    payment_method_id: "",
  });
  const initialFormData = {
    client_name: "",
    client_phone: "",
    designation: "",
    // location: "",
    country_id: "",
    state_id: "",
    city_id: "",
    port_id: "",
    company_name: "",
    interior_color: "",
    exterior_color: "",
    production_date: "",
    engine_size: "",
    fuel_type: "",
    steering: "",
    delivery_date_time: "",
    payment_method_id: "",
  };
  const [errors, setErrors] = useState({});
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [refId, setRefId] = useState("");
  const [carMake, setCarMake] = useState([]);
  const [carModel, setCarModel] = useState([]);
  const [country, setCountry] = useState([]);
  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);
  const [ports, setPort] = useState([]);
  const validate = () => {
    let formErrors = {};
    let rowErrors = [];

    if (!formData.client_name) formErrors.client_name = t("errors.client_name");
    if (!formData.client_phone)
      formErrors.client_phone = t("errors.client_phone");
    if (!formData.country_id) formErrors.country_id = t("errors.country");
    if (!formData.state_id) formErrors.state_id = t("errors.state");
    if (!formData.city_id) formErrors.city_id = t("errors.city");
    // if (!formData.port_id) formErrors.port_id = "Enter Port";
    if (!formData.company_name)
      formErrors.company_name = t("errors.company_name");

    // Validate rows
    rows.forEach((row, index) => {
      let rowError = {};
      if (!row.car_make_id) rowError.car_make_id = t("errors.make");
      if (!row.car_make_id) rowError.car_model_id = t("errors.model");
      if (!row.year) rowError.year = t("errors.year");
      if (Object.keys(rowError).length > 0) {
        rowErrors[index] = rowError;
      }
    });

    return { ...formErrors, rowErrors };
  };
  const fetchCarMake = async () => {
    const response = await dispatch(getCarMake());
    setCarMake(response?.data);
  };
  const fetchCarModel = async (id) => {
    const response = await dispatch(getCarModel(id));
    setCarModel(response?.data);
  };
  const fetchCountry = async () => {
    const response = await dispatch(getCountry());
    setCountry(response?.data);
  };

  const fetchState = async (id) => {
    const response = await dispatch(getState(id));
    setState(response?.data);
  };
  const fetchCity = async (id) => {
    const url_id = formData.country_id ? formData.country_id + "/" + id : id;

    const response = await dispatch(getCity(url_id));
    setCity(response?.data);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "country_id") {
      // Check if states is null or empt
      fetchState(value);
    } else if (name === "state_id") {
      fetchCity(value);
    }
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleRowChange = (index, e) => {
    const { name, value } = e.target;

    if (name === "car_make_id") {
      const selectedBrand = carMake?.find(
        (brand) => brand?.id === parseInt(value)
      );
      if (selectedBrand) {
        const brandSlug = selectedBrand.slug;
        fetchCarModel(brandSlug); // Call fetchCarModel with the selected brand id
      } else {
        console.error("Brand not found for the given id");
      }
    }
    const updatedRows = [...rows];
    updatedRows[index] = { ...updatedRows[index], [name]: value };
    setRows(updatedRows);
  };

  const handleSignOut = () => {
    dispatch(signOutAction());
  };

  const addRow = () => {
    setRows([
      ...rows,
      { quantity: 1, car_make_id: "", car_model_id: "", year: "" },
    ]);
  };

  const removeRow = (index) => {
    const updatedRows = rows.filter((_, i) => i !== index);
    setRows(updatedRows);
  };

  const increment = (index) => {
    const updatedRows = [...rows];
    updatedRows[index].quantity += 1;
    setRows(updatedRows);
  };

  const decrement = (index) => {
    const updatedRows = [...rows];
    if (updatedRows[index].quantity > 1) {
      updatedRows[index].quantity -= 1;
    }
    setRows(updatedRows);
  };

  const handlePhoneChange = (value) => {
    setFormData({
      ...formData,
      client_phone: value,
    });
  };

  const handleDateChange = (date) => {
    setFormData({
      ...formData,
      delivery_date_time: date,
    });
  };

  const handleProductionDateChange = (date) => {
    setFormData({
      ...formData,
      product_date_time: date,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formErrors = validate();
    // if (Object.keys(formErrors).length > 0) {
    //     setErrors(formErrors);
    //     return;
    // }
    if (Object.keys(formErrors).length > 1) {
      setErrors(formErrors);
      return;
    }
    if (formErrors?.rowErrors?.length > 0) {
      setErrors(formErrors);
      return;
    }

    // Combine form data with row data
    const combinedData = {
      ...formData,
      order_vehicle_items: rows,
    };

    const response = await dispatch(orderVehicleForm(combinedData));
    if (response?.code == 200) {
      message.success("Submitted successfully!");
      setRefId(response?.data?.code);
      setFormSubmitted(true);
      setFormData(initialFormData);
    } else {
      message.error(response?.message || "Submission failed!");
    }
  };
  const fetchPaymentMethod = async () => {
    const response = await dispatch(getPaymentMethod());
    setPaymentMethods(response?.data);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600); // Define the breakpoint for mobile
    };
    const detectedLanguage =
      localStorage.getItem("selectedLanguage") ||
      navigator.language.split("-")[0] ||
      "en";
    i18n.changeLanguage(detectedLanguage);
    document.documentElement.lang = detectedLanguage;
    reduxState?.carMake ? setCarMake(reduxState?.carMake) : fetchCarMake();
    reduxState?.countries ? setCountry(reduxState?.countries) : fetchCountry();

    // Set initial value
    handleResize();
    fetchPaymentMethod();
    // Add event listener
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="bg-white">
      <RedirectToSignin />
      <RedirectToHome />
      {!isMobile ? <SecondaryHeader /> : <PrimaryMobileHeader />}
      <div className="container">
        <div className="servicesPageList mt-4 pb-5">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-7">
              <img src={banner} alt="Banner" className="img-fluid mb-5" />
              <div className="card rounded-10 bg-white border-0 mb-3">
                <div className="card-body p-5">
                  <h2 className="mb-4 text-center">{t("order_vehicle")}</h2>
                  {formSubmitted ? (
                    <SuccessForm
                      refId={refId}
                      resetForm={() => {
                        setFormSubmitted(false);
                      }}
                    />
                  ) : (
                    <form onSubmit={handleSubmit}>
                      <h5 className="text-start mb-3 d-flex justify-content-start align-items-center">
                        <i className="bi bi-person me-2"></i>{" "}
                        <span>{t("client_details")}</span>
                      </h5>
                      <div className="row g-3 mb-4 text-start">
                        <div className="col-12 col-sm-6">
                          <InputField
                            placeholder={`${t("client_name")}*`}
                            type="text"
                            name="client_name"
                            value={formData.client_name}
                            onChange={handleChange}
                            customClass={errors.client_name ? "is-invalid" : ""}
                          />
                          {errors.client_name && (
                            <div className="text-danger font-13">
                              {errors.client_name}
                            </div>
                          )}
                        </div>
                        <div className="col-12 col-sm-6">
                          <div className="text-start">
                            <PhoneInput
                              country={"ae"}
                              excludeCountries={["il"]}
                              name="client_phone"
                              value={formData.client_phone}
                              className="w-100"
                              onChange={handlePhoneChange}
                              inputClass={`w-100 ${
                                errors.client_phone ? "is-invalid" : ""
                              }`}
                            />
                            {errors.client_phone && (
                              <div className="text-danger font-13">
                                {errors.client_phone}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-12 col-sm-6">
                          <InputField
                            placeholder={t("designation")}
                            type="text"
                            name="designation"
                            value={formData.designation}
                            onChange={handleChange}
                            customClass={errors.designation ? "is-invalid" : ""}
                          />
                          {errors.designation && (
                            <div className="text-danger font-13">
                              {errors.designation}
                            </div>
                          )}
                        </div>

                        <div className="col-12 col-sm-6">
                          <InputField
                            placeholder={`${t("company_name")}*`}
                            type="text"
                            name="company_name"
                            value={formData.company_name}
                            onChange={handleChange}
                            customClass={
                              errors.company_name ? "is-invalid" : ""
                            }
                          />
                          {errors.company_name && (
                            <div className="text-danger font-13">
                              {errors.company_name}
                            </div>
                          )}
                        </div>
                        <div className="row g-3">
                          <h5 className="text-start mb-2 d-flex justify-content-start align-items-center mt-3">
                            <i className="bi bi-geo-alt-fill me-2"></i>{" "}
                            <span>{t("location_details")}</span>
                          </h5>
                          {/* <div className="col-12 col-sm-3">
                            <InputField
                              fieldType="select"
                              selectOption={`${t("country")}*`}
                              name="country_id"
                              value={formData.country_id}
                              onChange={handleChange}
                              options={country?.map((make) => ({
                                value: make.id, // Using the car make ID as the value
                                label: make.name, // Using the car make name as the label
                              }))}
                              customClass={
                                errors?.country_id
                                  ? "form-select is-invalid"
                                  : "form-select"
                              }
                            />
                            {errors.country_id && (
                              <div className="text-danger font-13">
                                {errors.country_id}
                              </div>
                            )}
                          </div>
                          <div className="col-12 col-sm-3">
                            <InputField
                              fieldType="select"
                              selectOption={`${t("state")}*`}
                              name="state_id"
                              value={formData.state_id}
                              onChange={handleChange}
                              options={state?.map((make) => ({
                                value: make.id, // Using the car make ID as the value
                                label: make.name, // Using the car make name as the label
                              }))}
                              customClass={
                                errors?.state_id
                                  ? "form-select is-invalid"
                                  : "form-select"
                              }
                            />
                            {errors.state_id && (
                              <div className="text-danger font-13">
                                {errors.state_id}
                              </div>
                            )}
                          </div>
                          <div className="col-12 col-sm-3">
                            <InputField
                              fieldType="select"
                              selectOption={`${t("city")}*`}
                              name="city_id"
                              value={formData.city_id}
                              onChange={handleChange}
                              options={city?.map((make) => ({
                                value: make.id, // Using the car make ID as the value
                                label: make.name, // Using the car make name as the label
                              }))}
                              customClass={
                                errors?.city_id
                                  ? "form-select is-invalid"
                                  : "form-select"
                              }
                            />
                            {errors.city_id && (
                              <div className="text-danger font-13">
                                {errors.city_id}
                              </div>
                            )}
                          </div> */}
                          <div className="col-12 col-sm-3">
                            <CustomSelect
                              name="country_id"
                              options={country?.map((make) => ({
                                value: make.id, // Use the country ID as the value
                                label: make.name, // Use the country name as the label
                              }))}
                              value={formData.country_id} // Pass the selected value
                              onChange={handleChange} // Handle change for the country select
                              placeholder={`${t("country")}*`} // Placeholder text for the select input
                              customClass={
                                errors?.country_id
                                  ? "is-invalid custom-select" // Add custom class for error handling
                                  : "custom-select"
                              }
                            />
                            {errors.country_id && (
                              <div className="text-danger font-13">
                                {errors.country_id}
                              </div>
                            )}
                          </div>

                          <div className="col-12 col-sm-3">
                            <CustomSelect
                              name="state_id"
                              options={state?.map((make) => ({
                                value: make.id, // Use the state ID as the value
                                label: make.name, // Use the state name as the label
                              }))}
                              value={formData.state_id} // Pass the selected value
                              onChange={handleChange} // Handle change for the state select
                              placeholder={`${t("state")}*`} // Placeholder text for the select input
                              customClass={
                                errors?.state_id
                                  ? "is-invalid custom-select" // Add custom class for error handling
                                  : "custom-select"
                              }
                            />
                            {errors.state_id && (
                              <div className="text-danger font-13">
                                {errors.state_id}
                              </div>
                            )}
                          </div>

                          <div className="col-12 col-sm-3">
                            <CustomSelect
                              name="city_id"
                              options={city?.map((make) => ({
                                value: make.id, // Use the city ID as the value
                                label: make.name, // Use the city name as the label
                              }))}
                              value={formData.city_id} // Pass the selected value
                              onChange={handleChange} // Handle change for the city select
                              placeholder={`${t("city")}*`} // Placeholder text for the select input
                              customClass={
                                errors?.city_id
                                  ? "is-invalid custom-select" // Add custom class for error handling
                                  : "custom-select"
                              }
                            />
                            {errors.city_id && (
                              <div className="text-danger font-13">
                                {errors.city_id}
                              </div>
                            )}
                          </div>
                          <div className="col-12 col-sm-3">
                            <CustomSelect
                              name="port_id"
                              options={ports?.map((make) => ({
                                value: make.id, // Use the city ID as the value
                                label: make.name, // Use the city name as the label
                              }))}
                              value={formData.port_id} // Pass the selected value
                              onChange={handleChange} // Handle change for the city select
                              placeholder={`${t("port")}*`} // Placeholder text for the select input
                              customClass={
                                errors?.port_id
                                  ? "is-invalid custom-select" // Add custom class for error handling
                                  : "custom-select"
                              }
                            />
                            {errors.port_id && (
                              <div className="text-danger font-13">
                                {errors.port_id}
                              </div>
                            )}
                          </div>

                          <div className="col-12 col-sm-4"></div>
                        </div>
                      </div>
                      <h5 className="text-start mb-3 d-flex justify-content-start align-items-center">
                        <i className="bi bi-car-front me-2"></i>{" "}
                        <span>{t("vehicle_details")}</span>
                      </h5>
                      {rows.map((row, index) => (
                        <div
                          className="d-flex flex-column flex-md-row  gap-3 mb-3"
                          key={index}
                        >
                          <div className="col-10 col-sm-11 col-md-11">
                            <div className="row g-3">
                              <div className="col-12 col-sm-3">
                                <InputGroup className="">
                                  <Button
                                    variant="outline-secondary"
                                    className="h-100"
                                    onClick={() => decrement(index)}
                                  >
                                    <i className="bi bi-dash"></i>
                                  </Button>
                                  <FormControl
                                    type="text"
                                    readOnly
                                    name="quantity"
                                    value={row.quantity}
                                    onChange={(e) => handleRowChange(index, e)}
                                    min="1"
                                    className="font-13 text-center"
                                  />
                                  <Button
                                    variant="outline-secondary"
                                    onClick={() => increment(index)}
                                  >
                                    <i className="bi bi-plus"></i>
                                  </Button>
                                </InputGroup>
                              </div>
                              {/* <div className="col-12 col-sm-3">
                                <InputField
                                  fieldType="select"
                                  selectOption={`${t("make")}*`}
                                  name="car_make_id"
                                  value={row.car_make_id}
                                  onChange={(e) => handleRowChange(index, e)}
                                  options={carMake?.map((make) => ({
                                    value: make.id, // Using the car make ID as the value
                                    label: make.name, // Using the car make name as the label
                                  }))}
                                  customClass={
                                    errors?.rowErrors &&
                                    errors.rowErrors[index]?.car_make_id
                                      ? "form-select is-invalid"
                                      : "form-select"
                                  }
                                />
                                {errors.rowErrors &&
                                  errors.rowErrors[index] &&
                                  errors.rowErrors[index].car_make_id && (
                                    <div className="text-danger font-13">
                                      {errors.rowErrors[index].car_make_id}
                                    </div>
                                  )}
                              </div>
                              <div className="col-12 col-sm-3">
                                <InputField
                                  fieldType="select"
                                  selectOption={`${t("model")}*`}
                                  name="car_model_id"
                                  value={row.car_model_id}
                                  onChange={(e) => handleRowChange(index, e)}
                                  options={carModel?.map((make) => ({
                                    value: make.id, // Using the car make ID as the value
                                    label: make.name, // Using the car make name as the label
                                  }))}
                                  customClass={
                                    errors?.rowErrors &&
                                    errors.rowErrors[index]?.car_model_id
                                      ? "form-select is-invalid"
                                      : "form-select"
                                  }
                                />
                                {errors.rowErrors &&
                                  errors.rowErrors[index] &&
                                  errors.rowErrors[index].car_model_id && (
                                    <div className="text-danger font-13">
                                      {errors.rowErrors[index].car_model_id}
                                    </div>
                                  )}
                              </div>
                              <div className="col-12 col-sm-3">
                                <InputField
                                  fieldType="select"
                                  selectOption={`${t("year")}*`}
                                  name="year"
                                  value={row.year}
                                  onChange={(e) => handleRowChange(index, e)}
                                  options={[
                                    { value: "2020", label: "2020" },
                                    { value: "2021", label: "2021" },
                                    { value: "2022", label: "2022" },
                                    { value: "2023", label: "2023" },
                                    { value: "2024", label: "2024" },
                                  ]}
                                  customClass={
                                    errors?.rowErrors &&
                                    errors.rowErrors[index]?.year
                                      ? "form-select is-invalid"
                                      : "form-select"
                                  }
                                />
                                {errors.rowErrors &&
                                  errors.rowErrors[index] &&
                                  errors.rowErrors[index].year && (
                                    <div className="text-danger font-13">
                                      {errors.rowErrors[index].year}
                                    </div>
                                  )}
                              </div> */}
                              <div className="col-12 col-sm-3">
                                <CustomSelect
                                  name="car_make_id"
                                  options={carMake?.map((make) => ({
                                    value: make.id, // Using the car make ID as the value
                                    label: make.name, // Using the car make name as the label
                                  }))}
                                  value={row.car_make_id} // Pass the selected value
                                  onChange={(e) => handleRowChange(index, e)}
                                  placeholder={`${t("make")}*`} // Placeholder text for the select input
                                  customClass={
                                    errors?.rowErrors &&
                                    errors.rowErrors[index]?.car_make_id
                                      ? "is-invalid custom-select"
                                      : "custom-select"
                                  }
                                />
                                {errors.rowErrors &&
                                  errors.rowErrors[index]?.car_make_id && (
                                    <div className="text-danger font-13">
                                      {errors.rowErrors[index].car_make_id}
                                    </div>
                                  )}
                              </div>

                              <div className="col-12 col-sm-3">
                                <CustomSelect
                                  name="car_model_id"
                                  options={carModel?.map((make) => ({
                                    value: make.id, // Using the car model ID as the value
                                    label: make.name, // Using the car model name as the label
                                  }))}
                                  value={row.car_model_id} // Pass the selected value
                                  onChange={(e) => handleRowChange(index, e)}
                                  placeholder={`${t("model")}*`} // Placeholder text for the select input
                                  customClass={
                                    errors?.rowErrors &&
                                    errors.rowErrors[index]?.car_model_id
                                      ? "is-invalid custom-select"
                                      : "custom-select"
                                  }
                                />
                                {errors.rowErrors &&
                                  errors.rowErrors[index]?.car_model_id && (
                                    <div className="text-danger font-13">
                                      {errors.rowErrors[index].car_model_id}
                                    </div>
                                  )}
                              </div>

                              <div className="col-12 col-sm-3">
                                <CustomSelect
                                  name="year"
                                  options={[
                                    { value: "2020", label: "2020" },
                                    { value: "2021", label: "2021" },
                                    { value: "2022", label: "2022" },
                                    { value: "2023", label: "2023" },
                                    { value: "2024", label: "2024" },
                                  ]}
                                  value={row.year} // Pass the selected value
                                  onChange={(e) => handleRowChange(index, e)}
                                  placeholder={`${t("year")}*`} // Placeholder text for the select input
                                  customClass={
                                    errors?.rowErrors &&
                                    errors.rowErrors[index]?.year
                                      ? "is-invalid custom-select"
                                      : "custom-select"
                                  }
                                />
                                {errors.rowErrors &&
                                  errors.rowErrors[index]?.year && (
                                    <div className="text-danger font-13">
                                      {errors.rowErrors[index].year}
                                    </div>
                                  )}
                              </div>
                            </div>
                          </div>
                          {rows.length > 1 && (
                            <div className="col-2 col-sm-1 col-md-1 d-flex align-items-end">
                              <button
                                type="button"
                                className="btn btn-danger w-100"
                                onClick={() => removeRow(index)}
                              >
                                <i className="bi bi-trash"></i>
                              </button>
                            </div>
                          )}
                        </div>
                      ))}

                      <div className="text-end mb-3">
                        <div className="row g-3">
                          <div className="col-10 col-sm-11 col-md-11"></div>
                          <div className="col-2 col-sm-1 col-md-1">
                            <button
                              type="button"
                              className="btn btn-primary w-100"
                              onClick={addRow}
                            >
                              <i className="bi bi-plus-lg"></i>
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className="row g-3 mb-4 text-start">
                        <div className="col-12 col-sm-6">
                          <InputField
                            name="interior_color"
                            value={formData.interior_color}
                            onChange={handleChange}
                            placeholder={t("interior_color")}
                          />
                        </div>
                        <div className="col-12 col-sm-6">
                          <InputField
                            name="exterior_color"
                            value={formData.exterior_color}
                            onChange={handleChange}
                            placeholder={t("exterior_color")}
                          />
                        </div>
                        <div className="col-12 col-sm-6">
                          <DatePicker
                            selected={formData.product_date_time}
                            onChange={handleProductionDateChange}
                            showTimeSelect
                            dateFormat="Pp"
                            className="form-control font-13 w-100 h-100"
                            placeholderText={t("prod_date")}
                          />
                        </div>
                        <div className="col-12 col-sm-6">
                          <InputField
                            name="engine_size"
                            value={formData.engine_size}
                            onChange={handleChange}
                            placeholder={t("eng_size")}
                          />
                        </div>
                        <div className="col-12 col-sm-6">
                          <InputField
                            fieldType="select"
                            name="fuel_type"
                            value={formData.fuel_type}
                            onChange={handleChange}
                            customClass="form-select"
                            selectOption={t("fuel_type")}
                            options={[
                              { value: "Petrol", label: "Petrol" },
                              { value: "Diesel", label: "Diesel" },
                              { value: "Electric", label: "Electric" },
                              { value: "Hybrid", label: "Hybrid" },
                            ]}
                          />
                        </div>
                        <div className="col-12 col-sm-6">
                          <InputField
                            fieldType="select"
                            name="steering"
                            value={formData.steering}
                            onChange={handleChange}
                            customClass="form-select"
                            selectOption={t("steering")}
                            options={[
                              { value: "LHD", label: "Left Hand Drive" },
                              { value: "RHD", label: "Right Hand Drive" },
                            ]}
                          />
                          {/* <InputField
                          name="steering"
                          value={formData.steering}
                          onChange={handleChange}
                          placeholder="Steering"
                        /> */}
                        </div>
                      </div>
                      <h5 className="text-start mb-3 d-flex justify-content-start align-items-center">
                        <i className="bi bi-cart me-2"></i>{" "}
                        <span>Order Details</span>
                      </h5>
                      <div className="row g-3 mb-4 text-start">
                        <div className="col-12 col-sm-6">
                          <DatePicker
                            selected={formData.delivery_date_time}
                            onChange={handleDateChange}
                            showTimeSelect
                            dateFormat="Pp"
                            className="form-control font-13 w-100 h-100"
                            placeholderText={t("delivery_date")}
                          />
                        </div>
                        <div className="col-12 col-sm-6">
                          <InputField
                            name="payment_method_id"
                            fieldType="select"
                            selectOption="Payment Method"
                            value={formData.payment_method_id}
                            onChange={handleChange}
                            placeholder={t("payment_method_heading")}
                            options={paymentMethods.map((method) => ({
                              value: method.id, // Using the car make ID as the value
                              label: method.name, // Using the car make name as the label
                            }))}
                          />
                        </div>
                      </div>
                      <div className="row g-3 text-center">
                        <div className="col-12 col-sm-12">
                          <button
                            type="submit"
                            className="btn btn-primary btn-lg ps-5 pe-5 font-15 mb-3"
                          >
                            {t("submit")}
                          </button>
                          <p className="m-0 text-muted font-15">
                            {t("respond_time")}
                          </p>
                        </div>
                      </div>
                    </form>
                  )}
                </div>
              </div>

              {/* <!-- New Section: Ready to Order Your Dream Vehicle? --> */}
              <div class="container-text">
                <div class="fade-container">
                  <div class="scrollable-section">
                    <div class="scrollable-content ms-2">
                      <div class="row">
                        {/* <!-- Ready to Order Section --> */}
                        <div class="col-12 pb-3">
                          <h3 class="fw-bold">
                            Ready to Order Your Dream Vehicle?
                          </h3>
                          <h4 class="fw-bold">It's Easier Than You Think.</h4>
                          <p>
                            Whether you're looking for a luxury sedan, a robust
                            SUV, or a fleet of compact cars, ordering your
                            perfect vehicle has never been simpler. With just a
                            few clicks, you can tell us exactly what you need,
                            and we'll handle the rest.
                          </p>

                          <h4 class="fw-bold">How It Works:</h4>
                          <h5 class="fw-bold">Step 1: Fill Out the Form</h5>
                          <p>
                            Start by providing some basic details about the
                            vehicle(s) you're looking for. Let us know your
                            preferred make, model, and any specific
                            customizations or features you’d like. Don’t forget
                            to fill in your contact details, so we can get back
                            to you with your personalized offer.
                          </p>

                          <h5 class="fw-bold">
                            Step 2: We Review and Respond Quickly
                          </h5>
                          <p>
                            Once we receive your inquiry, our team will get to
                            work immediately. We’ll review your requirements,
                            source the ideal vehicle(s), and put together a
                            detailed quote tailored just for you. Whether you’re
                            ordering one car or a whole fleet, we’re here to
                            make the process seamless.
                          </p>

                          <h5 class="fw-bold">Step 3: Review and Confirm</h5>
                          <p>
                            Take your time reviewing your personalized quote. If
                            you have any questions or need further adjustments,
                            we’re just an email or phone call away. When you’re
                            ready, simply confirm the details, and we’ll
                            finalize your order.
                          </p>

                          <h4 class="fw-bold">Why Place an Inquiry Today?</h4>
                          <ul>
                            <li>
                              <strong>Personalized Attention:</strong> No two
                              vehicle orders are the same, and we treat your
                              request with the care and detail it deserves. Our
                              team works closely with you to ensure the perfect
                              fit, every time.
                            </li>
                            <li>
                              <strong>Hassle-Free Process:</strong> We’ve
                              designed our inquiry form to be quick and easy.
                              With just a few key details, you’re on your way to
                              getting the vehicle that fits your exact needs.
                            </li>
                            <li>
                              <strong>Expert Guidance:</strong> Not sure exactly
                              what you’re looking for? No problem. Our vehicle
                              specialists are on hand to help you choose the
                              right make and model based on your preferences and
                              budget.
                            </li>
                            <li>
                              <strong>Competitive Pricing:</strong> By
                              submitting an inquiry, you’ll receive a tailored
                              quote with the best available rates. No
                              guesswork—just transparent, fair pricing.
                            </li>
                            <li>
                              <strong>Speed and Convenience:</strong> From the
                              moment you submit your inquiry, we’re on it. You
                              can expect a fast response, so you can move
                              forward with your vehicle plans without
                              unnecessary delays.
                            </li>
                          </ul>

                          <h4 class="fw-bold">Ready to Get Started?</h4>
                          <p>
                            Your ideal vehicle is just a few details away. Fill
                            out our Vehicle Inquiry Form today, and let’s get
                            your order moving! Whether it’s a one-time purchase
                            or a long-term partnership, we’re here to make the
                            process smooth, simple, and stress-free. Don’t
                            wait—submit your inquiry now and take the first step
                            towards driving your perfect vehicle.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default OrderVehicle;
