import React from 'react';
import { Select } from 'antd';
import Flag from 'react-flagkit';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

const languageOptions = [
    { value: 'en', label: 'EN', flag: 'US' },
    { value: 'es', label: 'ES', flag: 'ES' },
    { value: 'fr', label: 'FR', flag: 'FR' },
    { value: 'de', label: 'DE', flag: 'DE' },
    { value: 'ch', label: 'CH', flag: 'CN' },
    { value: 'ru', label: 'RU', flag: 'RU' },
    { value: 'ja', label: 'JA', flag: 'JP' },
    { value: 'pt', label: 'PT', flag: 'PT' },
    { value: 'tr', label: 'TR', flag: 'TR' },
    { value: 'ar', label: 'AR', flag: 'AE' },
];

const LanguageSelect = ({ selectedLanguage, onLanguageChange }) => {
    const { i18n } = useTranslation();

    const handleChange = (value) => {
        const lang = value;
        onLanguageChange(lang);
        i18n.changeLanguage(lang);
    };

    return (
        <Select
            className='languageOptions font-13 border-0'
            value={selectedLanguage}
            onChange={handleChange}
            style={{ width: '95px' }}
            dropdownRender={menu => (
                <>
                    {menu}
                </>
            )}
        >
            {languageOptions.map(option => (
                <Option key={option.value} value={option.value} style={{ display: 'flex', alignItems: 'center' }}>
                    <Flag country={option.flag} style={{ marginRight: 10 }} />
                    {option.label}
                </Option>
            ))}
        </Select>
    );
};

export default LanguageSelect;
