import styles from "./SearchFilter.module.css";
import React, { useState, useEffect } from "react";
import { getCarMake, getCarModel } from "../redux/auth/authActions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import ReactSelect from "react-select";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
const SearchFilter = ({
  car_make_slug,
  car_model_slug,
  vin,
  interiorColorFromProps,
  exteriorColorFromProps,
  isLuxury,
}) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location?.search);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [carModel, setCarModel] = useState([]);
  const [carMake, setCarMake] = useState([]);
  const [selectedMakeId, setSelectedMakeId] = useState("");
  const [selectedModelId, setSelectedModelId] = useState("");
  const [selectedModelSlug, setSelectedModelSlug] = useState("");
  const reduxState = useSelector((state) => state.auth);
  const [selectedMakeSlug, setSelectedMakeSlug] = useState("");
  const [interiorColor, setInteriorColor] = useState(
    searchParams?.get("interiorColor") || ""
  );
  const [exteriorColor, setExteriorColor] = useState(
    searchParams?.get("exteriorColor") || ""
  );
  const [colors, setColors] = useState([]);
  const [steering, setSteering] = useState("");
  const [vin_no, setVin] = useState("");
  const fetchCarModel = async (id) => {
    const response = await dispatch(getCarModel(id));
    setCarModel(response?.data);
  };
  const fetchCarMake = async () => {
    const response = await dispatch(getCarMake());
    setCarMake(response?.data);
  };
  const fetchColors = async () => {
    try {
      const response = await fetch("https://www.csscolorsapi.com/api/colors");

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setColors(data?.colors);
    } catch (error) {
      console.error("Error fetching colors:", error);
    }
  };
  const handleSearch = () => {
    const queryParams = new URLSearchParams();
    if (selectedMakeSlug) {
      queryParams.append("make", selectedMakeSlug);
      if (selectedModelSlug) queryParams.append("model", selectedModelSlug);
      if (interiorColor) queryParams.append("interiorColor", interiorColor);
      if (exteriorColor) queryParams.append("exteriorColor", exteriorColor);
      if (steering) queryParams.append("steering", steering);
      if (vin_no) queryParams.append("vin", vin_no);

      const queryString = queryParams.toString();
      if (queryString) {
        navigate(`/car/listing?${queryString}`);
      }
    } else {
      // Handle the case where no parameters are provided
      console.log("Please provide at least one search filter");
    }
  };
  useEffect(() => {
    const detectedLanguage =
      localStorage.getItem("selectedLanguage") ||
      navigator.language.split("-")[0] ||
      "en";
    i18n.changeLanguage(detectedLanguage);
    document.documentElement.lang = detectedLanguage;
    reduxState?.carMake ? setCarMake(reduxState?.carMake) : fetchCarMake();

    // If car_make_slug is present, fetch car models based on the car_make_slug (brand_slug)
    if (car_make_slug) {
      fetchCarModelByBrand(car_make_slug); // Call fetchCarModelByBrand with the brand_slug (car_make_slug)
    }

    // Set the vin, make slug, and model slug if they exist
    if (vin) setVin(vin);
    if (car_make_slug) setSelectedMakeSlug(car_make_slug);
    if (car_model_slug) setSelectedModelSlug(car_model_slug);

    // Fetch colors
    fetchColors();
  }, [car_make_slug, car_model_slug, vin]);

  // New effect to run when carModel is updated
  useEffect(() => {
    if (car_model_slug && carModel?.length > 0) {
      const model = carModel?.find((model) => model?.slug === car_model_slug);
      if (model) setSelectedModelSlug(model?.slug);
    }
  }, [carModel, car_model_slug]);

  useEffect(() => {
    if (car_make_slug && carMake?.length > 0) {
      const make = carMake?.find((make) => make.slug === car_make_slug);
      if (make) setSelectedMakeId(make.id);
    }
  }, [carMake, car_make_slug]);

  // This function fetches car models based on the brand slug (car_make_slug)
  const fetchCarModelByBrand = async (brand_slug) => {
    const response = await dispatch(getCarModel(brand_slug));
    setCarModel(response?.data);
  };

  // useEffect(() => {
  //   console.log('searchfilter', car_make_slug, car_model_slug);

  //   fetchCarMake();
  //   if (car_make_slug) fetchCarModel(car_make_slug);
  //   if (vin) setVin(vin);
  //   if (car_model_slug) setSelectedModelSlug(car_model_slug);
  //   if (car_make_slug) setSelectedMakeSlug(car_make_slug);
  //   if (car_model_slug && carModel?.length > 0) {
  //     console.log(
  //       "hhubho;ub:",
  //       carModel?.find((model) => model?.slug === car_model_slug)
  //     );

  //     const model = carModel?.find((model) => model?.slug === car_model_slug);
  //     if (model) setSelectedModelSlug(model?.slug);
  //   }
  //   if (car_make_slug && carMake.length > 0) {
  //     const make = carMake?.find((make) => make.slug === car_make_slug);
  //     if (make) setSelectedMakeId(make.id);
  //   }

  //   fetchColors();
  // }, []);
  return (
    <>
      <div className={`p-2  grey-search-filter ${styles.searchFilter} ${isLuxury && "luxury-search-bar"}`} >
        <div className="row g-2">
          <div className="col-12 col-sm-6 col-md-4 col-lg-2">
            <select
              className="form-select"
              value={selectedModelSlug}
              onChange={(e) => setSelectedModelSlug(e.target.value)}
            >
              <option value="" disabled>
                {t("model")}
              </option>
              {carModel?.map((make) => (
                <option key={make?.id} value={make?.slug}>
                  {make?.name}
                </option>
              ))}
            </select>
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-2">
            <select
              className="form-select"
              value={interiorColor || ""}
              onChange={(e) => setInteriorColor(e.target.value)}
            >
              <option value="" disabled>
                {t("interior_color")}
              </option>
              {colors?.map((color) => (
                <option key={color?.name} value={color?.name}>
                  {color?.name}
                </option>
              ))}
            </select>
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-2">
            <select
              className="form-select"
              value={exteriorColor}
              onChange={(e) => setExteriorColor(e.target.value)}
            >
              <option value="" disabled>
                {t("exterior_color")}
              </option>
              {colors?.map((color) => (
                <option key={color?.name} value={color?.name}>
                  {color?.name}
                </option>
              ))}
            </select>
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-2">
            <select
              className="form-select"
              value={steering}
              onChange={(e) => setSteering(e.target.value)}
            >
              <option value="" disabled>
                {t("steering")}
              </option>
              <option value={"LHD"}>{t("lhd")}</option>
              <option value={"RHD"}>{t("rhd")}</option>
            </select>
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-2">
            <input
              type="text"
              className="form-control border-0 w-100"
              placeholder="  VIN"
              value={vin_no}
              onChange={(e) => setVin(e.target.value)}
            />
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-2">
            <button
              type="button"
              className="btn btn-primary w-100 h-100 rounded-10"
              onClick={handleSearch}
            >
              <i class="bi bi-search"></i> {t("search")}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchFilter;
