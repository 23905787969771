import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../styles/RelatedItemsCarousel.css";
import Card from "./ItemCard";

const RelatedItemsCarousel = ({ items, fromPage }) => {
  // Settings for the slider
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4, // Number of items to show on desktop
    slidesToScroll: 1,
    autoplay: true, // Enable automatic sliding
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024, // Up to 1024px
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768, // Up to 768px
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600, // Up to 600px
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const btnText = "Add To Cart";
  return (
    <div className="related-items-carousel mb-4 mt-3">
      <Slider {...settings}>
        {items?.map((item) => (
          <div key={item?.id} className="related-item-card-wrapper">
            <Card
              productData={item}
              btnText={btnText}
              fromPage={fromPage}
            />

            {/* <div className="related-item-card">
              <img src={item.image} alt={item.title} className="related-item-image" />
              <p className="related-item-title">{item.title}</p>
              <div className="related-item-pricing">
                <span className="related-item-price">{item.price}</span>
                <span className="related-item-discount">{item.discount}</span>
                <span className="related-item-discount-percentage">{item.discountPercentage}</span>
              </div>
            </div> */}
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default RelatedItemsCarousel;
