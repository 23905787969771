import "./SignInPage.css";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { companySignUpAction } from "../redux/auth/authActions";
import { Navigate, Link, useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LanguageSelect from "../components/LanguageSelect";
import i18n from "../i18n"; // Import your i18n configuration
import logo from "../assets/images/blue-logo.png"; // Import the image file
// import Flag from 'react-flags-select';

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import RedirectToHome from "../components/RedirectToHome";
import { Modal, Button } from "react-bootstrap";
import { RotatingLines } from "react-loader-spinner";
// List of country codes with flags
const countryCodes = [
  { code: "+1", name: "United States", flag: "US" },
  { code: "+44", name: "United Kingdom", flag: "GB" },
  { code: "+91", name: "India", flag: "IN" },
  { code: "+81", name: "Japan", flag: "JP" },
  { code: "+971", name: "United Arab Emirates", flag: "AE" },
  // Add more countries here
];

const SignUpCompanyPage = () => {
  const location = useLocation();
  const [checked, setChecked] = React.useState(false);
  const [webchecked, setWebChecked] = React.useState(false);
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [companyname, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [selectedCountryCode, setSelectedCountryCode] = useState(
    countryCodes[0].code
  );
  const [mobile, setMobile] = useState("");
  const [designation, setDesignation] = useState("");
  const [companywebsite, setCompanyWebsite] = useState("");
  const [companyaddress, setCompanyAddress] = useState("");
  const [password, setPassword] = useState("");
  const [referral_code, setReferralCode] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false);
  // Error
  const [companyNameError, setCompanyNameError] = useState("");
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [designationError, setDesignationError] = useState("");
  const [companyWebsiteError, setCompanyWebsiteError] = useState("");
  const [companyAddressError, setCompanyAddressError] = useState("");
  const [checkboxError, setCheckboxError] = useState("");
  const [webCheckboxError, setWebCheckboxError] = useState("");
  const [showModal, setShowModal] = useState(false);

  const [passwordError, setPasswordError] = useState("");

  const [language, setLanguage] = useState(
    localStorage.getItem("selectedLanguage") ||
      navigator.language.split("-")[0] ||
      "en"
  );
  const dispatch = useDispatch();
  const { isAuthenticated, error } = useSelector((state) => state.auth);

  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1); // Navigate to the previous page
  };

  // Function to handle opening and closing the modal
  const handleModalClose = () => setShowModal(false);
  const handleModalShow = () => setShowModal(true);

  // Function to validate the URL
  const validateUrl = (value) => {
    const urlPattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-zA-Z0-9$_.+!%*,;:@&=~-]+)@)?[a-zA-Z0-9.-]+|([0-9]{1,3}\\.){3}[0-9]{1,3})" + // domain name or IP
        "(:[0-9]{1,5})?" + // port (optional)
        "(\\/[-a-zA-Z0-9%_@.&+=~]*)*" + // path (optional)
        "(\\?[;&a-zA-Z0-9%_@.&+=~-]*)?" + // query string (optional)
        "(#[a-zA-Z0-9!$&" +
        "'()*+,;=:@~]*)?$", // fragment (optional)
      "i"
    );
    return urlPattern.test(value);
  };

  useEffect(() => {
    // Detect and set the default language based on the browser or localStorage
    const detectedLanguage =
      localStorage.getItem("selectedLanguage") ||
      navigator.language.split("-")[0] ||
      "en";
    i18n.changeLanguage(detectedLanguage);
    document.documentElement.lang = detectedLanguage;
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get("referral_code");
  }, []);

  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);
    if (event.target.checked) {
      setCheckboxError("");
    }
  };

  const handleWebCheckboxChange = (event) => {
    setWebChecked(event.target.checked);
    if (event.target.checked) {
      setWebCheckboxError("");
    }
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let valid = true;

    if (!companyname) {
      setCompanyNameError(t("companyname_required"));
      valid = false;
    } else {
      setCompanyNameError("");
    }

    if (!name) {
      setNameError(t("name_required"));
      valid = false;
    } else {
      setNameError("");
    }

    if (!email) {
      setEmailError(t("email_required"));
      valid = false;
    } else if (email && !/\S+@\S+\.\S+/.test(email)) {
      setEmailError("Email is invalid");
      valid = false;
    } else {
      setEmailError("");
    }

    if (!mobile) {
      setMobileError(t("mobile_required"));
      valid = false;
    } else {
      setMobileError("");
    }

    if (!designation) {
      setDesignationError(t("designation_required"));
      valid = false;
    } else {
      setDesignationError("");
    }

    // if (!companywebsite) {
    //     setCompanyWebsiteError(t('companywebsite_required'));
    //     valid = false;
    // } else {
    //     setCompanyWebsiteError('');
    // }

    if (!companyaddress) {
      setCompanyAddressError(t("companyaddress_required"));
      valid = false;
    } else {
      setCompanyAddressError("");
    }

    if (!password) {
      setPasswordError(t("password_required"));
      valid = false;
    } else if (password.length < 6) {
      setPasswordError("The password must be at least 6 characters.");
      valid = false;
    } else {
      setPasswordError("");
    }

    if (!webchecked) {
      setWebCheckboxError(t("accept_web_terms_required"));
      valid = false;
    }

    // if (!checked) {
    //     setCheckboxError(t('accept_terms_required'));
    //     valid = false;
    // }

    if (valid) {
      setButtonLoading(true);
      // const fullMobileNumber = `${selectedCountryCode}${mobile}`;
      const fullMobileNumber = "+" + mobile;
      const response = await dispatch(
        companySignUpAction({
          company_name: companyname,
          name,
          email,
          phone: fullMobileNumber,
          designation,
          company_address: companyaddress,
          password,
          company_website: companywebsite,
          role: "company",
          referred_by_code: referral_code
        })
      );

      if (response?.code === 200) {
        setButtonLoading(false);
        navigate("/otp-verification", {
          state: {
            email: email,
            mobile: fullMobileNumber,
          },
        });
        // navigate("/register-success");
      }
      setButtonLoading(false);
    }
    setButtonLoading(false);
  };

  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang);
    localStorage.setItem("selectedLanguage", lang);
    document.documentElement.lang = lang;
    setLanguage(lang);
  };

  const handleCompanyAddressChange = (e) => {
    setCompanyAddress(e.target.value);
    if (e.target.value) {
      setCompanyAddressError("");
    }
  };

  const handleCompanyWebsiteChange = (e) => {
    setCompanyWebsite(e.target.value);
    if (e.target.value) {
      setCompanyWebsiteError("");
    }
  };

  const handleDesignationChange = (e) => {
    setDesignation(e.target.value);
    if (e.target.value) {
      setDesignationError("");
    }
  };

  const handleCompanyNameChange = (e) => {
    setCompanyName(e.target.value);
    if (e.target.value) {
      setCompanyNameError("");
    }
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
    if (e.target.value) {
      setNameError("");
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    if (e.target.value) {
      setEmailError("");
    }
  };

  const handleCountryCodeChange = (event) => {
    setSelectedCountryCode(event.target.value);
  };

  const handleMobileChange = (value) => {
    setMobile(value);
    if (value) {
      setMobileError("");
    }
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    if (e.target.value) {
      setPasswordError("");
    }
  };

  return (
    <>
      <RedirectToHome />
      <div className="login-bg">
        <div className="container d-flex justify-content-center align-items-center pt-5 pb-5">
          <div className="login-register">
            <div className="text-center mb-5">
              <img src={logo} alt="Logo" width="200" />
            </div>
            <div className="card text-center rounded-20 bg-white">
              <div className="card-body p-5">
                <h2 className="mb-3 fw-bold theme-color font-26">
                  {t("company_title")}
                </h2>
                <form onSubmit={handleSubmit} style={{ width: "100%" }}>
                  <div className="mb-3 text-start">
                    <label htmlFor="companyname" className="form-label">
                      {t("companyname")} <span className="text-danger">*</span>
                    </label>
                    <input
                      type="companyname"
                      placeholder={t("companyname_placeholder")}
                      className={`form-control ${
                        companyNameError ? "is-invalid" : ""
                      }`}
                      id="companyname"
                      value={companyname}
                      onChange={handleCompanyNameChange}
                    />
                    {companyNameError && (
                      <div className="invalid-feedback">{companyNameError}</div>
                    )}
                  </div>
                  <div className="mb-3 text-start">
                    <label htmlFor="name" className="form-label">
                      {t("name")} <span className="text-danger">*</span>
                    </label>
                    <input
                      type="name"
                      placeholder={t("name_placeholder")}
                      className={`form-control ${
                        nameError ? "is-invalid" : ""
                      }`}
                      id="name"
                      value={name}
                      onChange={handleNameChange}
                    />
                    {nameError && (
                      <div className="invalid-feedback">{nameError}</div>
                    )}
                  </div>
                  <div className="mb-3 text-start">
                    <label htmlFor="email" className="form-label">
                      {t("signup_email")} <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder={t("email_placeholder")}
                      className={`form-control ${
                        emailError ? "is-invalid" : ""
                      }`}
                      id="email"
                      value={email}
                      onChange={handleEmailChange}
                    />
                    {emailError && (
                      <div className="invalid-feedback">{emailError}</div>
                    )}
                  </div>

                  <div className="mb-3 text-start">
                    <label htmlFor="mobile" className="form-label">
                      {t("mobile")} <span className="text-danger">*</span>
                    </label>
                    <PhoneInput
                      country={"ae"}
                      value={mobile}
                      onChange={handleMobileChange}
                      inputClass={`form-control ${
                        mobileError ? "is-invalid" : ""
                      }`}
                      inputStyle={{
                        width: "100%",
                        height: "53px",
                        borderRadius: "15px",
                      }}
                      masks={{ ae: ".. ... ...." }}
                      enableSearch={true}
                    />

                    {mobileError && (
                      <div className="text-danger font-14 mt-1">
                        {mobileError}
                      </div>
                    )}
                  </div>
                  <div className="mb-3 text-start">
                    <label htmlFor="designation" className="form-label">
                      {t("designation")} <span className="text-danger">*</span>
                    </label>
                    <input
                      type="designation"
                      placeholder={t("designation_placeholder")}
                      className={`form-control ${
                        companyNameError ? "is-invalid" : ""
                      }`}
                      id="designation"
                      value={designation}
                      onChange={handleDesignationChange}
                    />
                    {designationError && (
                      <div className="invalid-feedback">{designationError}</div>
                    )}
                  </div>
                  <div className="mb-3 text-start">
                    <label htmlFor="companywebsite" className="form-label">
                      {t("companywebsite")}
                    </label>
                    <input
                      type="companywebsite"
                      placeholder={t("companywebsite_placeholder")}
                      className={`form-control ${
                        companyWebsiteError ? "is-invalid" : ""
                      }`}
                      id="companywebsite"
                      value={companywebsite}
                      onChange={handleCompanyWebsiteChange}
                    />
                    {/* {companyWebsiteError && <div className="invalid-feedback">{companyWebsiteError}</div>} */}
                  </div>
                  <div className="mb-3 text-start">
                    <label htmlFor="companyaddress" className="form-label">
                      {t("companyaddress")}{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="companyaddress"
                      placeholder={t("companyaddress_placeholder")}
                      className={`form-control ${
                        companyAddressError ? "is-invalid" : ""
                      }`}
                      id="companyaddress"
                      value={companyaddress}
                      onChange={handleCompanyAddressChange}
                    />
                    {companyAddressError && (
                      <div className="invalid-feedback">
                        {companyAddressError}
                      </div>
                    )}
                  </div>
                  <div className="mb-3 text-start">
                    <label htmlFor="password" className="form-label">
                      {t("password")} <span className="text-danger">*</span>
                    </label>
                    <input
                      type="password"
                      placeholder={t("password_placeholder")}
                      className={`form-control ${
                        passwordError ? "is-invalid" : ""
                      }`}
                      id="password"
                      value={password}
                      onChange={handlePasswordChange}
                    />
                    {passwordError && (
                      <div className="invalid-feedback">{passwordError}</div>
                    )}
                  </div>
                  <div className="mb-3 text-start">
                    <label htmlFor="referral_code" className="form-label">
                      {t("Referral Code")}
                    </label>
                    <input
                      type="text"
                      placeholder={t("Referral Code")}
                      className={"form-control"}
                      id="referral_code"
                      value={referral_code}
                      onChange={(e) => setReferralCode(e.target.value)}
                      // onChange={setReferralCode}
                    />
                  </div>
                  <div class="form-check text-start mb-3">
                    <input
                      type="checkbox"
                      checked={webchecked}
                      className="form-check-input"
                      id="webcheckbox"
                      onChange={handleWebCheckboxChange}
                    />
                    <label class="form-check-label font-13" for="webcheckbox">
                      {t("accept_website")}
                      <span
                        onClick={handleModalShow}
                        className="text-primary"
                        style={{ cursor: "pointer" }}
                      >
                        {t("trms_n_condtn")}
                      </span>
                    </label>
                    {webCheckboxError && (
                      <div className="text-danger font-12 mt-1 text-uppercase">
                        {webCheckboxError}
                      </div>
                    )}
                  </div>

                  <button
                    type="submit"
                    style={{ height: "45px" }}
                    className="btn btn-primary w-100 text-uppercase rounded-15"
                    disabled={buttonLoading} // Disable button when loading
                  >
                    {buttonLoading ? (
                      <RotatingLines
                        strokeColor="#FFF" // Adjust stroke color to match the button style
                        strokeWidth="4"
                        animationDuration="0.75"
                        width="24" // Adjust size to fit inside the button
                        visible={true}
                      />
                    ) : (
                      t("register_btn")
                    )}
                  </button>
                  {error && (
                    <div className="text-danger mt-2">
                      {typeof error === "string" ? error : t("sign_in_failed")}
                    </div>
                  )}
                  <div className="d-flex justify-content-between align-items-center mt-3">
                    {/* <div class="form-check">
                                        <input
                                            type="checkbox"
                                            checked={checked}
                                            className='form-check-input'
                                            id='termsconditions'
                                            onChange={handleCheckboxChange}
                                        />
                                        <label class="form-check-label font-13" for="termsconditions">
                                            Accept Terms & Conditions
                                        </label>
                                    </div> */}
                    <span className="font-13">
                      {t("already_account")}{" "}
                      <Link
                        className="theme-color fw-bold text-capitalize"
                        to="/signin"
                      >
                        {t("login_txt")}
                      </Link>
                    </span>
                    <div>
                      <LanguageSelect
                        selectedLanguage={language}
                        onLanguageChange={handleLanguageChange}
                      />
                    </div>
                  </div>
                  {/* {checkboxError && <div className="text-danger font-12 mt-1 text-uppercase">{checkboxError}</div>} */}

                  <div className="text-center mt-2">
                    <Link
                      className="theme-color font-14  text-capitalize"
                      onClick={handleGoBack}
                    >
                      {t("back")}
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Modal for Terms and Conditions */}
      <Modal show={showModal} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Terms & Conditions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Your terms and conditions content goes here...</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SignUpCompanyPage;
