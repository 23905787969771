import React, { useState, useEffect } from "react";
import ChatBox from "./ChatBox";
import "../styles/FloatingButton.css";
import chatIcon from "../assets/images/website icons/chat.png";
const FloatingButton = ({ unread = false }) => {
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [unRead, setUnRead] = useState(false);
  const toggleChat = () => {
    setIsChatOpen(!isChatOpen);
  };
  useEffect(() => {
    setUnRead(unread);
  }, [unRead, unread]);

  return (
    <>
      <div
        className="btn-primary position-fixed floating-button"
        onClick={toggleChat}
        style={{ position: "relative" }}
      >
       <img src={chatIcon} alt="Chat"  style={{width:"20px",height:"20px"}}/>
        {unRead && (
          <span
            className="badge bg-danger"
            style={{
              position: "absolute",
              top: "-4px",
              right: "0px",
              borderRadius: "50%",
              width: "15px",
              height: "15px",
              color: "red",
            }}
          >
            .
          </span>
        )}
      </div>
      {isChatOpen && (
        <div className={`chat-box-wrapper ${isChatOpen ? "chat-open" : ""}`}>
          <ChatBox closeChat={toggleChat} setUnRead={setUnRead} />
        </div>
      )}
    </>
  );
};

export default FloatingButton;
