import styles from "./SearchFilter.module.css";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCarMake, getCarModel } from "../redux/auth/authActions";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
const ShopSearchFilter = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [carModel, setCarModel] = useState([]);
  const [carMake, setCarMake] = useState([]);
  const reduxState = useSelector((state) => state.auth);
  const [vin_no, setVin] = useState("");
  const [year, setYear] = useState("");
  const [selectedMake, setSelectedMake] = useState("");
  const [selectedModel, setSelectedModel] = useState("");
  const fetchCarModel = async (id) => {
    const response = await dispatch(getCarModel(id));
    console.log("modelllll:", response?.data);

    setCarModel(response?.data);
  };
  const fetchCarMake = async () => {
    const response = await dispatch(getCarMake());
    setCarMake(response?.data);
  };
  const onChange = (id) => {
    setSelectedMake(id); // Set selected make
    const selectedCarMak = carMake?.find(
      (item) => parseInt(item.id) === parseInt(id)
    );
    console.log(selectedCarMak);
    fetchCarModel(selectedCarMak?.slug);
  };
  useEffect(() => {
    reduxState?.carMake ? setCarMake(reduxState?.carMake) : fetchCarMake();
    const detectedLanguage =
      localStorage.getItem("selectedLanguage") ||
      navigator.language.split("-")[0] ||
      "en";
    i18n.changeLanguage(detectedLanguage);
    document.documentElement.lang = detectedLanguage;
  }, []);
  return (
    <>
      <div className={`p-2 border mt-3 grey-search-filter ${styles.searchFilter}`}>
        <div className="row g-2">
          <div className="col-12 col-sm-6 col-md-4 col-lg-2">
            <select
              className="form-select"
              onChange={(e) => onChange(e.target.value)}
            >
              <option selected disabled>
                {t("make")}
              </option>
              {carMake?.map((make) => (
                <option key={make?.id} value={make?.id}>
                  {make?.name}
                </option>
              ))}
            </select>
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-2">
            <select className="form-select">
              <option selected disabled>
                {t("model")}
              </option>
              {carModel?.map((make) => (
                <option key={make?.id} value={make?.id}>
                  {make?.name}
                </option>
              ))}
            </select>
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-2">
            <select className="form-select">
              <option selected disabled>
                {t("year")}
              </option>
              <option value={2024}>2024</option>
              <option value={2023}>2023</option>
              <option value={2022}>2022</option>
              <option value={2021}>2021</option>
            </select>
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-2">
            <input
              type="text"
              className="form-input border-0 w-100 p-2 ms-3"
              placeholder="VIN"
              value={vin_no}
              onChange={(e) => setVin(e.target.value)}
              style={{backgroundColor:"#efefef"}}
            />
          </div>

          <div className="col-12 col-sm-6 col-md-4 col-lg-2"></div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-2">
            <button
              type="button"
              className="btn btn-primary w-100 h-100 rounded-10"
            >
              <i className="bi bi-search"></i> {t("search")}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShopSearchFilter;
