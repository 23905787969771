import "./SalesMen.css";

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { signOutAction, getAllCars } from "../redux/auth/authActions";
import { Navigate } from "react-router-dom";
import { ConfigProviderProps, RadioChangeEvent } from "antd";
import { Radio, Tabs, TabsProps, Pagination } from "antd";
import Footer from "../components/Footer";
import Services from "../components/Services";
import Brands from "../components/Brands";
import Testimonials from "../components/Testimonials";
import RedirectToHome from "../components/RedirectToHome";
import RedirectToSignin from "../components/RedirectToSignin";
import Header3 from "../components/Header3";
import ProductGrid from "../components/ProductGrid";
import productImg from "../assets/images/p-img.png";
import { RotatingLines } from "react-loader-spinner";
import loaderLogo from "../assets/images/CM LOGO DARK BLUE.png";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
const SalesMen = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isAuthenticated } = useSelector((state) => state.auth);
  const btnText = "Download PDF";
  const [isLoading, setIsLoading] = useState(true);
  const [tabs, setTabs] = useState([]);
  const [cars, setCars] = useState([]);
  const [metadata, setMetaData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const handleSignOut = () => {
    dispatch(signOutAction());
  };
  const fetchData = async (page = 1, search = "") => {
    try {
      setIsLoading(true);
      const pagination = true;
      const queryParams = new URLSearchParams({ page, search, pagination });
      const response = await dispatch(getAllCars(`?${queryParams.toString()}`));
      //   const carsData = response?.data?.data;
      const { data: carsData, ...metadata } = response?.data || {};
      setMetaData(metadata);
      if (carsData?.tabs && carsData?.tabs.length > 0) {
        setTabs(carsData.tabs);
      } else {
        // If there are no tabs, add the "New" tab with the cars data
        setTabs([
          {
            key: "1",
            label: "New",
            products: carsData,
          },
        ]);
      }
    } catch (error) {
      console.error("API Error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData(currentPage);
    const detectedLanguage =
      localStorage.getItem("selectedLanguage") ||
      navigator.language.split("-")[0] ||
      "en";
    i18n.changeLanguage(detectedLanguage);
    document.documentElement.lang = detectedLanguage;
  }, [currentPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page); // Set current page when the user interacts with pagination
  };

  const performSearch = () => {
    fetchData(1, searchTerm); // Fetch data with the search term
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      performSearch(); // Trigger search when Enter key is pressed
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value); // Update search term as user types
  };

  const productTabs = [
    {
      key: "1",
      label: "New",
      products: [
        {
          id: 1,
          title: "2024 Toyota Land Cruiser",
          image: productImg,
          kilometer: "0 KM",
          year: "2024",
          color: "White",
        },
        {
          id: 2,
          title: "2023 Nissan Patrol",
          image: productImg,
          kilometer: "10,000 KM",
          year: "2023",
          color: "Black",
        },
        {
          id: 3,
          title: "2024 Toyota Land Cruiser",
          image: productImg,
          kilometer: "0 KM",
          year: "2024",
          color: "White",
        },
      ],
    },
    {
      key: "2",
      label: "Modified",
      products: [
        {
          id: 3,
          title: "2021 Modified Jeep Wrangler",
          image: productImg,
          kilometer: "5,000 KM",
          year: "2021",
          color: "Green",
        },
        {
          id: 4,
          title: "2020 Modified Ford Mustang",
          image: productImg,
          kilometer: "12,000 KM",
          year: "2020",
          color: "Red",
        },
      ],
    },
    {
      key: "3",
      label: "Incoming",
      products: [
        {
          id: 5,
          title: "2025 Tesla Model X",
          image: productImg,
          kilometer: "Brand New",
          year: "2025",
          color: "Blue",
        },
        {
          id: 6,
          title: "2025 Mercedes-Benz S-Class",
          image: productImg,
          kilometer: "Brand New",
          year: "2025",
          color: "Silver",
        },
      ],
    },
  ];

  return (
    <div>
      <RedirectToSignin />
      <RedirectToHome />
      <Header3 />
      <div className="container">
        <Tabs defaultActiveKey="1" className="customTabs">
          {isLoading ? (
            <div className="d-flex justify-content-center align-items-center vh-100">
              {/* <ClipLoader size={50} color={"#123abc"} loading={isLoading} /> */}
              <RotatingLines
                strokeColor="#000740"
                strokeWidth="4"
                animationDuration="0.75"
                width="96"
                visible={true}
              />

              <img
                src={loaderLogo}
                style={{ width: "250px", height: "250px" }}
              ></img>
            </div>
          ) : (
            tabs?.map((tab) => (
              <Tabs.Tab tab={tab.label} key={tab.key}>
                <div className="shop-header mb-3 mt-3">
                  <div className="shop-search-bar">
                    <input
                      type="text"
                      placeholder={t("looking_for")}
                      value={searchTerm}
                      onChange={handleSearchChange}
                      onKeyDown={handleKeyPress}
                    />
                    <i
                      className="bi bi-search shop-search-icon"
                      onClick={performSearch}
                    ></i>
                  </div>
                </div>
                <ProductGrid
                  products={tab.products}
                  btnText={btnText}
                  fromPage={"Sales"}
                />
              </Tabs.Tab>
            ))
          )}
        </Tabs>
        <div className="d-flex justify-content-center my-4">
          <Pagination
            current={metadata.current_page || 1}
            total={metadata.total || 0}
            pageSize={metadata.per_page || 12}
            onChange={handlePageChange}
            showSizeChanger={false}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default SalesMen;
