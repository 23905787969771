import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../components/CartDetails.css";
import { Flex } from "antd";
import Link from "antd/es/typography/Link";
import { API_URL } from "../api/authApi";
const CardDetails = ({ cart, updateCart }) => {
  const [isMobile, setIsMobile] = useState(false);
  const api_url = API_URL.replace("/api", "");
  const [items, setItems] = useState([]);
  // const handleRemoveItem = (id) => {
  //   setItems((prevItems) => prevItems.filter((item) => item.id !== id));
  // };
  const totalPrice = items.reduce((total, item) => total + item.price, 0); // Including shipping
  const subtotal = items.reduce((total, item) => total + item.price, 0);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    setItems(cart); // Update items when cart is updated

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [cart]);
  const handleQuantityChange = (product_id, quantity) => {
    // Trigger updateCart when quantity changes
    updateCart({
      product_id,
      quantity,
      action: "add", // default action for quantity change
    });
  };
  const handleAddItem = (product_id, currentQuantity) => {
    // Increase the quantity by 1 and trigger updateCart
    const newQuantity = currentQuantity + 1;
    updateCart({
      product_id,
      quantity: newQuantity,
      action: "add",
    });
  };
  const handleCheckout = () => {
    window.location.href = "/checkout";
  };
  useEffect(() => {}, [items]);

  const handleRemoveItem = (product_id, currentQuantity) => {
    // Decrease the quantity by 1 and trigger updateCart
    const newQuantity = currentQuantity > 1 ? currentQuantity - 1 : 1; // Ensure at least 1
    updateCart({
      product_id,
      quantity: newQuantity,
      action: "remove",
    });
    setItems(
      (prevItems) =>
        prevItems
          .map((item) => {
            if (item.product_id === product_id) {
              return {
                ...item,
                quantity: newQuantity,
              };
            }
            return item;
          })
          .filter((item) => item.quantity > 0) // Filter out items with quantity 0
    );
  };
  return (
    <div className="container" style={{ marginBottom: "30px" }}>
      <div
        className="line"
        style={{ height: "1px", backgroundColor: "#ccc", margin: "20px 0" }}
      />

      <div
        style={{
          display: "flex",
          gap: "10px",
          fontSize: "12.5px",
        }}
      >
        <span style={{ opacity: "0.3" }}>Home</span>
        <span>/</span>
        <span>Cart</span>
      </div>

      <div
        className=" d-flex"
        style={{
          marginTop: "24px",
          maxWidth: "1170px",
          margin: "auto",
          height: "696px",
        }}
      >
        <div className="col-8">
          <div>
            <table
              className="bg-white"
              style={{
                width: "100%",
                borderCollapse: "separate",
                borderSpacing: "0 32px",
              }}
            >
              <thead
                className="shadow-sm p-3 mb-5 bg-white rounded"
                style={{
                  top: "10px",
                  backgroundColor: "#F3F3F3",
                  height: "72px",
                }}
              >
                <tr
                  style={{
                    justifyContent: "center",
                    marginTop: "24px",
                    width: "1091px",
                    height: "24px",
                    left: "40px",
                    fontSize: "16px",
                  }}
                >
                  <td
                    className="px-4 py-2"
                    style={{ alignItems: "center", alignContent: "center" }}
                  >
                    <b>Product </b>
                  </td>
                  <td
                    className="px-4 py-2"
                    style={{ alignItems: "center", alignContent: "center" }}
                  >
                    <b>Price</b>
                  </td>
                  <td
                    className="px-4 py-2"
                    style={{ alignItems: "center", alignContent: "center" }}
                  >
                    <b>Quantity</b>
                  </td>
                  <td
                    className="px-4 py-2"
                    style={{ alignItems: "center", alignContent: "center" }}
                  >
                    <b>Total</b>
                  </td>
                </tr>
              </thead>
              <tbody>
                {items.map((item) => (
                  <tr
                    key={item?.product?.id}
                    className="shadow-sm p-3 mb-5 bg-white rounded"
                    style={{
                      alignItems: "center",
                      borderRadius: "4px",
                      height: "102px",
                    }}
                  >
                    <td className="px-3 py-2">
                      <div style={{ position: "relative" }}>
                        <img
                          src={`${api_url}/${item?.product?.images[0]?.image?.path}`}
                          className="img-fluid rounded-3 me-3"
                          alt={item?.product?.name}
                          style={{ width: "54px", height: "54px" }}
                        />
                        <i
                          className="bi bi-x-circle-fill"
                          onClick={() => handleRemoveItem(item?.product_id)}
                          style={{
                            position: "absolute",
                            top: "0px",
                            left: "0px",
                            display: "flex",
                            borderRadius: "50%",
                            cursor: "pointer",
                            color: "black",
                            background: "white",
                            borderRadius: "99%",
                            fontSize: "18px",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        />
                        <span>{item?.product?.name}</span>
                      </div>
                    </td>
                    <td>{item?.product?.price?.toFixed(2)} AED</td>
                    <td>
                      <div className="d-flex align-items-center">
                        <button
                          className="btn btn-link px-2"
                          onClick={() =>
                            handleAddItem(item?.product?.id, item?.quantity)
                          }
                        >
                          <i className="fas fa-minus"></i>
                        </button>
                        <input
                          type="number"
                          className="form-control form-control-sm mx-2"
                          value={item?.quantity}
                          onChange={(e) =>
                            handleQuantityChange(
                              item?.product?.id,
                              parseInt(e.target.value)
                            )
                          }
                          style={{ width: "72px", height: "42px" }}
                        />
                        <button
                          className="btn btn-link px-2"
                          onClick={() =>
                            handleAddItem(item?.product?.id, item?.quantity)
                          }
                        >
                          <i className="fas fa-plus"></i>
                        </button>
                      </div>
                      {/* <div className="d-flex align-items-center">
                      <button
                        className="btn btn-link px-2"
                        onClick={() => console.log("Decrease quantity")}
                      >
                        <i className="fas fa-minus"></i>
                      </button>
                      <input
                        id={`quantity-${item?.id}`}
                        min="1"
                        name="quantity"
                        defaultValue={item?.quantity}
                        type="number"
                        className="form-control form-control-sm mx-2"
                        style={{
                          width: "72px",
                          height: "42px",
                          backgroundColor: "white",
                        }}
                      />
                      <button
                        className="btn btn-link px-2"
                        onClick={() => console.log("Increase quantity")}
                      >
                        <i className="fas fa-plus"></i>
                      </button>
                    </div> */}
                    </td>
                    <td>
                      {(item?.product?.price * item?.quantity)?.toFixed(2)} AED
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="col-4 ms-5">
          <h5
            style={{
              fontWeight: "bold",
              marginBottom: "20px",
              marginTop: "52px",
            }}
          >
            Cart Totals
          </h5>
          <div className="d-flex">
            <div
              style={{
                backgroundColor: "#e8e6f1", // Light purple background
                borderRadius: "10px",
                padding: "20px",
                marginTop: "25px",
                width: "88%", // Ensure proper width
              }}
            >
              {/* <h5
                style={{
                  color: "#6B2592", // Purple text color
                  fontWeight: "bold",
                  marginBottom: "20px",
                }}
              >
                Cart Totals
              </h5> */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "15px",
                }}
              >
                <span style={{ fontWeight: "bold", color: "#1d3178" }}>
                  Subtotals:
                </span>
                <span style={{ color: "#1d3178" }}>
                  AED{" "}
                  {items
                    .reduce(
                      (total, item) =>
                        total + item?.product?.price * item?.quantity,
                      0
                    )
                    .toFixed(2)}{" "}
                </span>
              </div>
              <hr />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "15px",
                }}
              >
                <span style={{ fontWeight: "bold", color: "#1d3178" }}>
                  Totals:
                </span>
                <span style={{ color: "#1d3178" }}>
                  AED{" "}
                  {items
                    .reduce(
                      (total, item) =>
                        total + item?.product?.price * item?.quantity,
                      0
                    )
                    .toFixed(2)}{" "}
                </span>
              </div>
              <hr />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "15px",
                  marginTop: "15px",
                }}
              >
                <span
                  style={{
                    height: "10px",
                    width: "10px",
                    backgroundColor: "green",
                    borderRadius: "50%",
                    display: "inline-block",
                    marginRight: "10px",
                  }}
                ></span>
                <span style={{ fontSize: "12px", color: "#6c757d" }}>
                  Shipping & taxes calculated at checkout
                </span>
              </div>
              <button
                type="button"
                className="btn btn-primary  w-100"
                style={{
                  backgroundColor: "#000740", // Dark purple
                  borderRadius: "8px",
                  border: "none",
                  color: "white",
                  padding: "10px",
                }}
                onClick={handleCheckout}
              >
                Proceed To Checkout
              </button>
            </div>
          </div>
          <div className=" ">
            <h5
              style={{
                fontWeight: "bold",
                marginBottom: "10px",
                marginTop: "25px",
              }}
            >
              Calculate Shipping
            </h5>
            <div className="d-flex">
              <div
                style={{
                  backgroundColor: "#e8e6f1", // Light purple background
                  borderRadius: "10px",
                  padding: "20px",
                  width: "88%", // Adjust width
                  marginTop: "15px",
                }}
              >
                {/* <h5
                  style={{
                    color: "#6B2592", // Purple text
                    fontWeight: "bold",
                    marginBottom: "20px",
                  }}
                >
                  Calculate Shipping
                </h5> */}
                <form>
                  <div style={{ marginBottom: "15px" }}>
                    <input
                      type="text"
                      placeholder="UAE"
                      style={{
                        width: "100%",
                        padding: "10px",
                        border: "1px solid #e0e0e0",
                        borderRadius: "5px",
                        backgroundColor: "#e8e6f1",
                      }}
                    />
                  </div>
                  <div style={{ marginBottom: "15px" }} className="cart-text">
                    <input
                      type="text"
                      placeholder="Dubai"
                      style={{
                        width: "100%",
                        padding: "10px",
                        border: "1px solid #e0e0e0",
                        borderRadius: "5px",
                        backgroundColor: "#e8e6f1",
                      }}
                    />
                  </div>
                  <div style={{ marginBottom: "15px" }}>
                    <input
                      type="text"
                      placeholder="Postal Code"
                      style={{
                        width: "100%",
                        padding: "10px",
                        border: "1px solid #e0e0e0",
                        borderRadius: "5px",
                        backgroundColor: "#e8e6f1",
                      }}
                    />
                  </div>
                  <button
                    type="button"
                    className="btn btn-primary w-100"
                    style={{
                      backgroundColor: "#000740", // Dark purple
                      color: "white",
                      border: "none",
                      borderRadius: "8px",
                      padding: "10px",
                    }}
                  >
                    Calculate Shipping
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardDetails;
