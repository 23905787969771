import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { signOutAction } from "../redux/auth/authActions";
import Footer from "../components/Footer";
import RedirectToHome from "../components/RedirectToHome";
import RedirectToSignin from "../components/RedirectToSignin";
import PrimaryMobileHeader from "../components/PrimaryMobileHeader";
import SecondaryHeader from "../components/SecondaryHeader";
import { Container, Row, Col } from "react-bootstrap";
import fram1 from "../assets/images/Frame 894.png";
import hondaspare from "../assets/images/b5.png";
import Paymentlogo1 from "../assets/images/paymentlogo1.png";
import Paymentlogo2 from "../assets/images/paymentlogo2.png";
import Paymentlogo3 from "../assets/images/paymentogo3.png";
import Paymentlogo4 from "../assets/images/paymentlogo4.png";
import Paypallogo from "../assets/images/paypallogo.png";
import Link from "antd/es/typography/Link";
import { Button } from "antd";
import ShopHeader from "../components/ShopHeader";
import visaCard from "../assets/images/website icons/Visa.png";
import masterCard from "../assets/images/website icons/Mastercard.png";
import Nagad from "../assets/images/website icons/Nagad.png";
import bkash from "../assets/images/website icons/Bkash.png";
import paypal from "../assets/images/website icons/logos_paypal.png";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
const Cart = () => {
  const dispatch = useDispatch();
  const [isMobile, setIsMobile] = useState(false);
  const { isAuthenticated } = useSelector((state) => state.auth);
  const [selectedOption, setSelectedOption] = useState("delivery");

  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
    country: "",
    city: "",
    state: "",
    zipCode: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };
  const handleSignOut = () => {
    dispatch(signOutAction());
  };
  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div style={{ backgroundColor: "white" }}>
      <RedirectToSignin />
      <RedirectToHome />
      {!isMobile ? <SecondaryHeader /> : <PrimaryMobileHeader />}

      <Container className="py-4">
        <ShopHeader />
        {/* <Row className="justify-content-center">
          <div className="shop-header mb-3 mt-3">
            <div className="shop-search-bar">
              <input type="text" placeholder="What are you looking for?" />
              <i className="bi bi-search shop-search-icon"></i>
            </div>
            <div className="shop-header-icons">
              <Link to="/wishlist" className="shop-header-icon">
                <i className="bi bi-heart" style={{ color: "black" }}></i>
              </Link>
              <Link to="/cart" className="shop-header-icon cart-icon">
                <i className="bi bi-cart" style={{ color: "black" }}></i>
                <span
                  className="cart-badge"
                  style={{ backgroundColor: "#000740" }}
                >
                  2
                </span>
              </Link>
            </div>
          </div>
        </Row> */}

        <div
          className="line"
          style={{ height: "1px", backgroundColor: "#ccc", margin: "20px 0" }}
        />

        <div
          style={{
            display: "flex",
            gap: "10px",
            opacity: "0.3",
            fontSize: "12.5px",
          }}
        >
          <span>Home</span>
          <span>/</span>
          <span>Checkout</span>
        </div>

        <Row>
          {/* <Col xs={12} md={6}>
            <div className="Checkout_container" style={{ padding: "10px" }}>
              <form>
                {[
                  "First Name",
                  "Email Address",
                  "Phone Number",
                  "Company Name",
                  "Country",
                  "Town/City",
                  "Street Address",
                  "Apartment, floor, etc. (optional)",
                ].map((label, index) => (
                  <div key={index} style={{ marginBottom: "15px" }}>
                    <label style={{ display: "block" }}>{label}:</label>
                    <input
                      style={{
                        height: "40px",
                        width: "100%",
                        borderRadius: "4px",
                        border: "1px solid",
                      }}
                      type="text"
                      name={label.replace(" ", "_").toLowerCase()}
                    />
                  </div>
                ))}

                <div style={{ display: "flex", alignItems: "center" }}>
                  <input type="checkbox" style={{ marginRight: "8px" }} />
                  <span>
                    Save this information for faster check-out next time
                  </span>
                </div>
              </form>
            </div>
          </Col> */}

          <Col xs={12} md={6}>
            <div className="">
              <h3 className=" mt-4">Checkout</h3>

              <h5 className=" mt-4">Shipping Information</h5>
            </div>
            <div
              className="d-flex justify-content-start mt-3 ms-3 mb-3"
              style={{ height: "3rem" }}
            >
              {/* Delivery Option */}
              <div
                className={`d-flex align-items-center p-3 me-3 rounded ${
                  selectedOption === "delivery"
                    ? "selected-option"
                    : "unselected-option"
                }`}
                style={{
                  border: `2px solid ${
                    selectedOption === "delivery" ? "#6A5BFF" : "#E0E0E0"
                  }`,
                  cursor: "pointer",
                }}
                onClick={() => handleOptionChange("delivery")}
              >
                <input
                  type="radio"
                  name="deliveryOption"
                  value="delivery"
                  checked={selectedOption === "delivery"}
                  onChange={() => handleOptionChange("delivery")}
                  style={{ marginRight: "10px", }}
                />
                <i
                  className="bi bi-truck me-2"
                  style={{
                    fontSize: "18px",
                    color:
                      selectedOption === "delivery" ? "#6A5BFF" : "#A0A0A0",
                  }}
                ></i>
                <span
                  style={{
                    color:
                      selectedOption === "delivery" ? "#6A5BFF" : "#000000",
                    fontWeight:
                      selectedOption === "delivery" ? "bold" : "normal",
                  }}
                >
                  Delivery
                </span>
              </div>

              {/* Pick Up Option */}
              <div
                className={`d-flex align-items-center p-3 rounded ${
                  selectedOption === "pickup"
                    ? "selected-option"
                    : "unselected-option"
                }`}
                style={{
                  border: `2px solid ${
                    selectedOption === "pickup" ? "#6A5BFF" : "#E0E0E0"
                  }`,
                  cursor: "pointer",
                }}
                onClick={() => handleOptionChange("pickup")}
              >
                <input
                  type="radio"
                  name="deliveryOption"
                  value="pickup"
                  checked={selectedOption === "pickup"}
                  onChange={() => handleOptionChange("pickup")}
                  style={{ marginRight: "10px" }}
                />
                <i
                  className="bi bi-box me-2"
                  style={{
                    fontSize: "18px",
                    color: selectedOption === "pickup" ? "#6A5BFF" : "#A0A0A0",
                  }}
                ></i>
                <span
                  style={{
                    color: selectedOption === "pickup" ? "#6A5BFF" : "#000000",
                    fontWeight: selectedOption === "pickup" ? "bold" : "normal",
                  }}
                >
                  Pick up
                </span>
              </div>
            </div>
            <form>
              {/* Full Name */}
              <div className="mb-3">
                <label htmlFor="fullName" className="form-label">
                  Full name <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  id="fullName"
                  name="fullName"
                  placeholder="Enter full name"
                  className="form-control"
                  value={formData.fullName}
                  onChange={handleChange}
                  required
                  style={{backgroundColor:"white"}}
                />
              </div>

              {/* Email Address */}
              <div className="mb-3">
                <label htmlFor="email" className="form-label">
                  Email address <span className="text-danger">*</span>
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Enter email address"
                  className="form-control"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  style={{backgroundColor:"white"}}
                />
              </div>

              {/* Phone Number */}
              <div className="mb-3">
                <label htmlFor="phoneNumber" className="form-label">
                  Phone number <span className="text-danger">*</span>
                </label>
                <PhoneInput
                  country={"us"}
                  value={formData.phoneNumber}
                  onChange={(phone) =>
                    setFormData((prev) => ({ ...prev, phoneNumber: phone }))
                  }
                  inputStyle={{
                    width: "100%",
                    border: "1px solid #ced4da",
                    borderRadius: "5px",
                    height: "38px",
                    backgroundColor:"white"
                  }}
                  buttonStyle={{ border: "none", borderRadius: "5px 0 0 5px" }}
                />
              </div>

              {/* Country */}
              <div className="mb-3">
                <label htmlFor="country" className="form-label">
                  Country <span className="text-danger">*</span>
                </label>
                <select
                  id="country"
                  name="country"
                  className="form-select"
                  value={formData.country}
                  onChange={handleChange}
                  required
                  style={{backgroundColor:"white"}}
                >
                  <option value="">Choose state</option>
                  <option value="uae">UAE</option>
                  <option value="usa">USA</option>
                  <option value="canada">Canada</option>
                </select>
              </div>

              {/* City, State, ZIP */}
              <div className="row">
                <div className="col-md-4 mb-3">
                  <label htmlFor="city" className="form-label">
                    City
                  </label>
                  <input
                    type="text"
                    id="city"
                    name="city"
                    placeholder="Enter city"
                    className="form-control"
                    value={formData.city}
                    onChange={handleChange}
                    style={{backgroundColor:"white"}}
                  />
                </div>
                <div className="col-md-4 mb-3">
                  <label htmlFor="state" className="form-label">
                    State
                  </label>
                  <input
                    type="text"
                    id="state"
                    name="state"
                    placeholder="Enter state"
                    className="form-control"
                    value={formData.state}
                    onChange={handleChange}
                    style={{backgroundColor:"white"}}
                  />
                </div>
                <div className="col-md-4 mb-3">
                  <label htmlFor="zipCode" className="form-label">
                    ZIP Code
                  </label>
                  <input
                    type="text"
                    id="zipCode"
                    name="zipCode"
                    placeholder="Enter ZIP code"
                    className="form-control"
                    value={formData.zipCode}
                    onChange={handleChange}
                    style={{backgroundColor:"white"}}
                  />
                </div>
              </div>
            </form>
          </Col>

          <Col xs={12} md={6}>
            <div
              className="place-order"
              style={{
                padding: "50px",
                width: "100%",

                marginLeft: "32px",
              }}
            >
              <h5>Review your cart</h5>
              <Container className="bg-white">
                <Row>
                  <Col xs={12}>
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr className="bg-white">
                            <th className="bg-white">Item</th>
                            <th className="bg-white">Price</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="bg-white">
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  src={fram1}
                                  style={{
                                    width: "48px",
                                    height: "42px",
                                    marginRight: "16px",
                                  }}
                                  alt="Toyota"
                                />
                                <span>Toyota Services Filter Kits</span>
                              </div>
                            </td>
                            <td className="bg-white text-end">650 AED</td>
                          </tr>
                          <tr>
                            <td className="bg-white">
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  src={hondaspare}
                                  style={{
                                    width: "48px",
                                    height: "42px",
                                    marginRight: "16px",
                                  }}
                                  alt="Honda"
                                />
                                <span>Honda Services Filter Kits</span>
                              </div>
                            </td>
                            <td className="bg-white text-end">1100 AED</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "20px",
                      }}
                    >
                      <span>Sub Total:</span>
                      <span>1750 AED</span>
                    </div>
                    <div
                      className="line"
                      style={{
                        height: "0.5px",
                        backgroundColor: "#ccc",
                        margin: "20px 0",
                      }}
                    />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <span>Shipping:</span>
                      <span>25 AED</span>
                    </div>
                    <div
                      className="line"
                      style={{
                        width: "100%",
                        opacity: "50%",
                        height: "0.5px",
                        backgroundColor: "#CCC",
                        margin: "20px 0",
                      }}
                    />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "10px",
                      }}
                    >
                      <span>Total:</span>
                      <span>1750 AED</span>
                    </div>
                    <div
                      style={{
                        fontFamily: "Arial, sans-serif",
                        fontSize: "16px",
                      }}
                    >
                      {/* Bank Option */}
                      <div className="d-flex align-items-center justify-content-between mb-4">
                        <div className="d-flex align-items-center">
                          <input
                            type="radio"
                            id="bank"
                            name="payment-method"
                            style={{
                              width: "18px",
                              height: "18px",
                              marginRight: "10px",
                              cursor: "pointer",
                            }}
                          />
                          <label htmlFor="bank" style={{ cursor: "pointer" }}>
                            Bank
                          </label>
                        </div>
                        <div>
                          <img
                            src={bkash}
                            alt="Bkash"
                            style={{ width: "40px", marginRight: "10px" }}
                          />
                          <img
                            src={visaCard}
                            alt="Visa"
                            style={{ width: "40px", marginRight: "10px" }}
                          />
                          <img
                            src={masterCard}
                            alt="MasterCard"
                            style={{ width: "40px", marginRight: "10px" }}
                          />
                          <img
                            src={Nagad}
                            alt="Nagad"
                            style={{ width: "40px", marginRight: "10px" }}
                          />
                        </div>
                      </div>

                      {/* PayPal Option */}
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                          <input
                            type="radio"
                            id="paypal"
                            name="payment-method"
                            style={{
                              width: "18px",
                              height: "18px",
                              marginRight: "10px",
                              cursor: "pointer",
                            }}
                          />
                          <label
                            htmlFor="paypal"
                            style={{
                              cursor: "pointer",
                            }}
                          >
                            PayPal
                          </label>
                        </div>
                        <div>
                          <img
                            src={paypal}
                            alt="PayPal"
                            style={{ width: "30px", height: "30px" }}
                          />
                        </div>
                      </div>
                    </div>
                    <div style={{ marginTop: "20px" }}>
                      <Button
                        style={{
                          width: "100%",
                          height: "56px",
                          borderRadius: "4px",
                          backgroundColor: "#000740",
                          color: "white",
                          fontSize: "16px",
                        }}
                      >
                        Place Order
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </Col>
        </Row>
      </Container>

      <Footer />
    </div>
  );
};

export default Cart;
