import "../components/Testimonials.css";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import b1 from "../assets/images/b1.png";
import b2 from "../assets/images/b2.png";
import b3 from "../assets/images/b3.png";
import b4 from "../assets/images/b4.png";
import b5 from "../assets/images/b5.png";
import b6 from "../assets/images/b6.png";
import comma from "../assets/images/comma.png";
import userIcon from "../assets/images/user-ico.png";
import useScrollAnimation from "./useScrollAnimation";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import carousel styles
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
import { useDispatch, useSelector } from "react-redux";
import Card from "./ItemCard";
import { getNewArrivals } from "../redux/auth/authActions";
import ProductCard from "./ProductCard";
import ProductGrid from "./ProductGrid";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { RotatingLines } from "react-loader-spinner";
import loaderLogo from "../assets/images/CM LOGO DARK BLUE.png";
const NewArrivals = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [cars, setCars] = useState([]);
  const elementsRef = useScrollAnimation();
  const [isLoading, setIsLoading] = useState(true);
  const fetchNewArrivals = async (page) => {
    setIsLoading(true);
    const response = await dispatch(getNewArrivals());
    setCars(response?.data);
    setIsLoading(false);
  };
  const car = {
    id: 112,
    code: "CAR-24-10-111",
    slug: "kia-telluride-2024-1728129690-70046",
    name: "Kia Telluride 2024",
    vin: null,
    engine_number: null,
    car_make_id: 10,
    car_model_id: 186,
    car_variant_id: null,
    car_body_type_id: null,
    car_category_id: 1,
    transmission: "Automatic",
    variant_details: null,
    steering_type: "LHD",
    fuel_type: "Petrol",
    fuel_tank_capacity: "30L",
    engine_size: null,
    ext_color: "Black",
    int_color: "Red",
    production_year: "2024",
    mileage: 0,
    average_on_road: 13,
    is_new_arrival: 1,
    created_at: "2024-10-05T12:01:30.000000Z",
    updated_at: "2024-10-05T12:01:30.000000Z",
    deleted_at: null,
    is_booked: false,
    booked_code: "",
    car_make: {
      id: 10,
      code: "CMake-24-10-010",
      name: "Kia",
      slug: "kia-1728121061-96926",
      logo: {
        id: 10,
        new_name: "kia.png",
        original_name: "kia.png",
        path: "uploads/car-makes/kia.png",
        extension: "png",
        size: "0",
        type: "image/png",
        created_at: "2024-10-05T09:37:41.000000Z",
        updated_at: "2024-10-05T09:37:41.000000Z",
        deleted_at: null,
      },
      official_website: "https://www.kia.com",
      is_featured: 1,
      order: 9,
      created_at: "2024-10-05T09:37:41.000000Z",
      updated_at: "2024-10-05T09:37:41.000000Z",
      deleted_at: null,
      no_of_cars: 16,
      no_of_models: 15,
    },
    car_model: {
      id: 186,
      code: "CModel-24-10-186",
      name: "Telluride",
      slug: "telluride-1728121061-64565",
      logo: null,
      car_make_id: 10,
      created_at: "2024-10-05T09:37:41.000000Z",
      updated_at: "2024-10-05T09:37:41.000000Z",
      deleted_at: null,
      no_of_cars: 1,
    },
    car_variant: null,
    images: [
      {
        id: 112,
        name: "Front View",
        car_id: 112,
        image_id: 274,
        is_thumbnail: 1,
        is_thumbnail_interior: 1,
        created_at: "2024-10-05T12:01:30.000000Z",
        updated_at: "2024-10-05T12:01:30.000000Z",
        deleted_at: null,
        image: {
          id: 274,
          new_name: "1728129690_kia_telluride_2024.png",
          original_name: "kia telluride 2024.png",
          path: "uploads/car/images/1728129690_kia_telluride_2024.png",
          extension: "png",
          size: "1051880",
          type: "image/png",
          created_at: "2024-10-05T12:01:30.000000Z",
          updated_at: "2024-10-05T12:01:30.000000Z",
          deleted_at: null,
        },
      },
    ],
    car_body_type: null,
    car_category: {
      id: 1,
      code: "CC-24-10-001",
      name: "Normal",
      slug: "normal-1728121072-78172",
      created_at: "2024-10-05T09:37:52.000000Z",
      updated_at: "2024-10-05T09:37:52.000000Z",
      deleted_at: null,
    },
    attachments: [
      {
        id: 111,
        name: "Car Specification File",
        file_id: 275,
        attachment_for: "car",
        source_type: "App\\Models\\Car",
        source_id: 112,
        created_at: "2024-10-05T12:01:30.000000Z",
        updated_at: "2024-10-05T12:01:30.000000Z",
        deleted_at: null,
        file: {
          id: 275,
          new_name: "1728129690_prado_spec_sheet_compressed_compressed.pdf",
          original_name: "prado spec sheet-compressed_compressed.pdf",
          path: "uploads/car/specification_files/1728129690_prado_spec_sheet_compressed_compressed.pdf",
          extension: "pdf",
          size: "1964770",
          type: "application/pdf",
          created_at: "2024-10-05T12:01:30.000000Z",
          updated_at: "2024-10-05T12:01:30.000000Z",
          deleted_at: null,
        },
      },
    ],
  };

  // const cars = Array(12)
  //   .fill(null)
  //   .map((_, index) => ({ ...car, id: index + 1 }));

  useEffect(() => {
    fetchNewArrivals();
    // Detect and set the default language based on the browser or localStorage
    const detectedLanguage =
      localStorage.getItem("selectedLanguage") ||
      navigator.language.split("-")[0] ||
      "en";
    i18n.changeLanguage(detectedLanguage);
    document.documentElement.lang = detectedLanguage;
  }, [dispatch]);
  // const chunkArray = (array, chunkSize) => {
  //   const results = [];
  //   for (let i = 0; i < array.length; i += chunkSize) {
  //     results.push(array.slice(i, i + chunkSize));
  //   }
  //   return results;
  // };

  // const chunkCars = (carsArray) => {
  //   return Array.from({ length: Math.ceil(carsArray.length / 4) }, (_, i) =>
  //     carsArray.slice(i * 4, i * 4 + 4)
  //   );
  // };

  // // Chunked rows for all cars (will handle first and second row separately)
  // const chunkedCars = chunkCars(cars);

  const chunkArray = (array, chunkSize) => {
    const results = [];
    for (let i = 0; i < array?.length; i += chunkSize) {
      results.push(array?.slice(i, i + chunkSize));
    }
    return results;
  };

  // Ensure 12 cars are chunked into 2 rows per slide
  const chunkCars = (carsArray) => {
    return chunkArray(carsArray, 8); // Each slide should contain 8 cars (4 for the first row, 4 for the second row)
  };

  const chunkedCars = chunkCars(cars);

  const [startX, setStartX] = useState(0);
  const [startY, setStartY] = useState(0);

  const handleTouchStart = (e) => {
    const touch = e.touches[0];
    setStartX(touch.clientX);
    setStartY(touch.clientY);
  };

  const handleTouchMove = (e) => {
    const touch = e.touches[0];
    const diffX = Math.abs(touch.clientX - startX);
    const diffY = Math.abs(touch.clientY - startY);

    if (diffY > diffX) {
      // Vertical swipe detected, stop propagation to allow scrolling
      e.stopPropagation();
    }
  };

  return (
    <div className="pt-3 pb-3">
      {/* First Carousel for the first 6 cars */}
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center vh-100">
          <RotatingLines
            strokeColor="#000740"
            strokeWidth="4"
            animationDuration="0.75"
            width="96"
            visible={true}
          />
          <img
            src={loaderLogo}
            style={{ width: "250px", height: "250px" }}
            alt="Loading"
          />
        </div>
      ) : (
        <>
          <div className="d-flex justify-content-between align-items-center mb-2">
            <h4 className="text-capitalize">{t("new_arrivals")}</h4>
          </div>

          <Carousel
            className="custom-carousel"
            showThumbs={false}
            autoPlay={false}
            infiniteLoop={false}
            showStatus={false}
            showIndicators={false}
            // onTouchStart={handleTouchStart}
            // onTouchMove={handleTouchMove}
            selectedItem={0}
            interval={4000}
            renderArrowPrev={(onClickHandler, hasPrev) =>
              hasPrev && (
                <button
                  type="button"
                  onClick={onClickHandler}
                  className="custom-prev-arrow"
                >
                  <i className="bi bi-chevron-left custom-carousel-icon"></i>
                </button>
              )
            }
            renderArrowNext={(onClickHandler, hasNext) =>
              hasNext && (
                <button
                  type="button"
                  onClick={onClickHandler}
                  className="custom-next-arrow"
                >
                  <i className="bi bi-chevron-right custom-carousel-icon"></i>
                </button>
              )
            }
          >
            {chunkedCars.map((carChunk, slideIndex) => (
              <div key={slideIndex} className="carousel-slide custom-slide">
                {/* First Row for each slide */}
                <div className="d-flex justify-content-between flex-wrap">
                  {carChunk.slice(0, 4).map((item, index) => (
                    <div
                      className="col-12 col-sm-6 col-lg-3 mb-3 pe-3"
                      key={`first-row-${index}`}
                    >
                      <ProductCard productData={item} />
                    </div>
                  ))}
                  {/* Fill remaining slots with empty divs to maintain layout */}
                  {carChunk.length < 4 &&
                    Array.from({ length: 4 - carChunk.length }).map(
                      (_, idx) => (
                        <div
                          className="col-12 col-sm-6 col-lg-3 mb-3"
                          key={`empty-first-${idx}`}
                        ></div>
                      )
                    )}
                </div>

                {/* Second Row for each slide */}
                <div className="d-flex justify-content-between flex-wrap">
                  {carChunk.slice(4, 8).map((item, index) => (
                    <div
                      className="col-12 col-sm-6 col-lg-3 mb-3 pe-3"
                      key={`second-row-${index}`}
                    >
                      <ProductCard productData={item} />
                    </div>
                  ))}
                  {/* Fill remaining slots with empty divs to maintain layout */}
                  {carChunk.slice(4, 8).length < 4 &&
                    Array.from({ length: 4 - carChunk.slice(4, 8).length }).map(
                      (_, idx) => (
                        <div
                          className="col-12 col-sm-6 col-lg-3 mb-3"
                          key={`empty-second-${idx}`}
                        ></div>
                      )
                    )}
                </div>
              </div>
            ))}
          </Carousel>
        </>
      )}
    </div>
  );
};

export default NewArrivals;
