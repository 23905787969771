import React from "react";
import { Routes, Route } from "react-router-dom";
import SignInPage from "./pages/SignInPage";
import SignUpIndividualPage from "./pages/SignUpIndividualPage";
import SignUpAdvanceIndividualPage from "./pages/SignUpAdvanceIndividualPage";
import SignUpAsCompanyPage from "./pages/SignUpAsCompanyPage";
import SignUpAsAdvanceCompanyPage from "./pages/SignUpAsAdvanceCompanyPage";
import HomePage from "./pages/HomePage";
import AuthGuard from "./components/AuthGuard";
import ServicePage from "./pages/ServicePage";
import NotFoundPage from "./pages/NotFoundPage";
import TendorsPage from "./pages/TendorsPage";
import SupplyContract from "./pages/SupplyContract";
import WarrantyInformation from "./pages/WarrantyInformation";
import MakeADeal from "./pages/MakeADeal";
import Logistics from "./pages/Logistics";
import RequestForQuotation from "./pages/RequestForQuotation";
import PaymentMethod from "./pages/PaymentMethod";
import BankAccountDetail from "./pages/BankAccountDetail";
import LetterOfCredit from "./pages/LetterOfCredit";
import TermsAndCondition from "./pages/TermsAndCondition";
import Cash from "./pages/Cash";
import Rules from "./pages/Rules";
import CryptoCurrency from "./pages/CryptoCurrency";
import ComingSoon from "./pages/ComingSoon";
import TrackOrder from "./pages/TrackOrder";
import SalesMen from "./pages/SalesMen";
import BrandModels from "./pages/BrandModels";
import BrandList from "./pages/BrandList";
import CrossTrade from "./pages/CrossTrade";
import OrderVehicle from "./pages/OrderVehicle";
import OfferVehicle from "./pages/OfferVehicle";
import Forgotten from "./pages/Forgotten";
import ContacyUs from "./pages/ContacyUs";
import AboutUs from "./pages/AboutUs";
import OurTeam from "./pages/OurTeam";
import Accessories from "./pages/Accessories";
import SpareParts from "./pages/SpareParts";
import ServiceKits from "./pages/ServiceKits";
import Wishlist from "./pages/WishList";
import ProductDetailsPage from "./pages/ProductDetails";
import Career from "./pages/Career";
import MyAccount from "./pages/MyAccount";
import Password from "./pages/Password";
import CarSpecification from "./pages/CarSpecification";
import Checkout from "./pages/checkout";
import Cart from "./pages/Cart";
import Documents from "./pages/DocAndCertificates";
import ReviewPage from "./pages/DocumentsReview";
import DocumentPayments from "./pages/DocumentPayment";
import MyOrders from "./pages/MyOrders";
import Inquries from "./pages/Inquries";
import Notification from "./pages/Notification";
import FormTracking from "./pages/FormTracking";
import CarBookings from "./pages/CarBookings";
import OtpRegistration from "./pages/OtpRegistration";
import Modification from "./pages/Modification";
import RegisterSuccess from "./pages/RegisterSuccess";
const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/signin" element={<SignInPage />} />
      <Route path="/individual-register" element={<SignUpIndividualPage />} />
      <Route
        path="/individual-advance-register"
        element={<SignUpAdvanceIndividualPage />}
      />
      <Route path="/company-register" element={<SignUpAsCompanyPage />} />
      <Route
        path="/company-advance-register"
        element={<SignUpAsAdvanceCompanyPage />}
      />
      <Route path="/track-order" element={<TrackOrder />} />
      <Route path="/forgotten" element={<Forgotten />} />
      <Route
        path="/register-success"
        element={
          <AuthGuard>
            <RegisterSuccess />
          </AuthGuard>
        }
      />

      <Route
        path="/"
        element={
          <AuthGuard>
            <HomePage />
          </AuthGuard>
        }
      />
      <Route
        path="/services"
        element={
          <AuthGuard>
            <ServicePage />
          </AuthGuard>
        }
      />
      <Route
        path="/tender"
        element={
          <AuthGuard>
            <TendorsPage />
          </AuthGuard>
        }
      />
      <Route
        path="/supply-contract"
        element={
          <AuthGuard>
            <SupplyContract />
          </AuthGuard>
        }
      />
      <Route
        path="/warranty-information"
        element={
          <AuthGuard>
            <WarrantyInformation />
          </AuthGuard>
        }
      />
      <Route
        path="/make-a-deal"
        element={
          <AuthGuard>
            <MakeADeal />
          </AuthGuard>
        }
      />
      <Route
        path="/logistics"
        element={
          <AuthGuard>
            <Logistics />
          </AuthGuard>
        }
      />
      <Route
        path="/request-for-quotation"
        element={
          <AuthGuard>
            <RequestForQuotation />
          </AuthGuard>
        }
      />
      <Route
        path="/payment-method"
        element={
          <AuthGuard>
            <PaymentMethod />
          </AuthGuard>
        }
      />
      <Route
        path="/bank-detail"
        element={
          <AuthGuard>
            <BankAccountDetail />
          </AuthGuard>
        }
      />
      <Route
        path="/letter-of-credit"
        element={
          <AuthGuard>
            <LetterOfCredit />
          </AuthGuard>
        }
      />
      <Route
        path="/terms-and-condition"
        element={
          <AuthGuard>
            <TermsAndCondition />
          </AuthGuard>
        }
      />
      <Route
        path="/cash"
        element={
          <AuthGuard>
            <Cash />
          </AuthGuard>
        }
      />
      <Route
        path="/rules"
        element={
          <AuthGuard>
            <Rules />
          </AuthGuard>
        }
      />
      <Route
        path="/crypto-currency"
        element={
          <AuthGuard>
            <CryptoCurrency />
          </AuthGuard>
        }
      />
      <Route
        path="/salesmen"
        element={
          <AuthGuard>
            <SalesMen />
          </AuthGuard>
        }
      />
      <Route
        path="/car/listing"
        element={
          <AuthGuard>
            <BrandModels />
          </AuthGuard>
        }
      />
      <Route
        path="/brands"
        element={
          <AuthGuard>
            <BrandList />
          </AuthGuard>
        }
      />
      <Route
        path="/cross-trade"
        element={
          <AuthGuard>
            <CrossTrade />
          </AuthGuard>
        }
      />
      <Route
        path="/order-vehicle"
        element={
          <AuthGuard>
            <OrderVehicle />
          </AuthGuard>
        }
      />
      <Route
        path="/offer-vehicle"
        element={
          <AuthGuard>
            <OfferVehicle />
          </AuthGuard>
        }
      />
      <Route
        path="/contact-us"
        element={
          <AuthGuard>
            <ContacyUs />
          </AuthGuard>
        }
      />
      <Route
        path="/about-us"
        element={
          <AuthGuard>
            <AboutUs />
          </AuthGuard>
        }
      />
      <Route
        path="/our-team"
        element={
          <AuthGuard>
            <OurTeam />
          </AuthGuard>
        }
      />
      <Route
        path="/career"
        element={
          <AuthGuard>
            <Career />
          </AuthGuard>
        }
      />
      <Route
        path="/accessories"
        element={
          <AuthGuard>
            <Accessories />
          </AuthGuard>
        }
      />
      <Route
        path="/service-kits"
        element={
          <AuthGuard>
            <ServiceKits />
          </AuthGuard>
        }
      />
      <Route
        path="spare-parts"
        element={
          <AuthGuard>
            <SpareParts />
          </AuthGuard>
        }
      />
      <Route
        path="wishlist"
        element={
          <AuthGuard>
            <Wishlist />
          </AuthGuard>
        }
      />
      <Route
        path="product-details/:id"
        element={
          <AuthGuard>
            <ProductDetailsPage />
          </AuthGuard>
        }
      />
      <Route
        path="account"
        element={
          <AuthGuard>
            <MyAccount />
          </AuthGuard>
        }
      />
      <Route
        path="password"
        element={
          <AuthGuard>
            <Password />
          </AuthGuard>
        }
      />
      <Route
        path="car-specification"
        element={
          <AuthGuard>
            <CarSpecification />
          </AuthGuard>
        }
      />
      <Route
        path="checkout"
        element={
          <AuthGuard>
            <Checkout />
          </AuthGuard>
        }
      />

      <Route
        path="/documents"
        element={
          <AuthGuard>
            <Documents />
          </AuthGuard>
        }
      />
      <Route
        path="/review"
        element={
          <AuthGuard>
            <ReviewPage />
          </AuthGuard>
        }
      />
      <Route
        path="cart"
        element={
          <AuthGuard>
            <Cart />
          </AuthGuard>
        }
      />
      <Route
        path="order"
        element={
          <AuthGuard>
            <MyOrders />
          </AuthGuard>
        }
      />
      <Route
        path="inquries"
        element={
          <AuthGuard>
            <Inquries />
          </AuthGuard>
        }
      />
      <Route
        path="notification"
        element={
          <AuthGuard>
            <Notification />
          </AuthGuard>
        }
      />
      <Route
        path="form-tracking"
        element={
          <AuthGuard>
            <FormTracking />
          </AuthGuard>
        }
      />
      <Route
        path="/documentspayments"
        element={
          <AuthGuard>
            <DocumentPayments />
          </AuthGuard>
        }
      />
      <Route
        path="/car-bookings"
        element={
          <AuthGuard>
            <CarBookings />
          </AuthGuard>
        }
      />
      <Route path="/otp-verification" element={<OtpRegistration />} />
      <Route path="/modifications" element={<Modification />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default AppRoutes;
