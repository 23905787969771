import React, { useState, useEffect } from "react";
import { getDepartments } from "../redux/auth/authActions";
import "../styles/ChatBox.css";
import { useDispatch, useSelector } from "react-redux";
import { RotatingLines } from "react-loader-spinner"; // Import the loader
import { message } from "antd";
import ChatLogo from "../assets/images/white-logo.png";
import ChatIcon from "../assets/images/website icons/token_chat.png";
const ChatRoom = ({ onSubmit, close }) => {
  const dispatch = useDispatch();
  const reduxState = useSelector((state) => state.auth);
  const userData = reduxState?.user;
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [enquiryMessage, setEnquiryMessage] = useState("");
  const [departmentsList, setDepartments] = useState([]);
  const [loading, setLoading] = useState(false); // State for loader
  const [buttonLoading, setButtonLoading] = useState(false);
  const [time, setTime] = useState("");
  const getDepartmentOptions = async () => {
    setLoading(true); // Show loader while fetching departments
    const response = await dispatch(getDepartments());
    setDepartments(response?.data);
    setLoading(false); // Hide loader after fetching departments
  };

  const handleSendEnquiry = () => {
    if (!selectedDepartment || !enquiryMessage.trim()) {
      message.error("Please select a department and enter a message.");
      return;
    }
    // Send inquiry data to parent
    setButtonLoading(true);
    onSubmit(enquiryMessage, selectedDepartment);
    setButtonLoading(false);

    // Clear inputs
    setSelectedDepartment("");
    setEnquiryMessage("");
  };

  useEffect(() => {
    getDepartmentOptions();
  }, [dispatch]);

  useEffect(() => {
    // Function to update the time
    const updateTime = () => {
      const now = new Date();
      const hours = now.getHours();
      const minutes = now.getMinutes();
      const ampm = hours >= 12 ? "PM" : "AM";
      const formattedTime = `${hours % 12 || 12}:${
        minutes < 10 ? `0${minutes}` : minutes
      } ${ampm}`;
      setTime(formattedTime);
    };

    updateTime(); // Set initial time
    const interval = setInterval(updateTime, 1000); // Update time every second

    return () => clearInterval(interval); // Clean up the interval on component unmount
  }, []);

  return (
    <>
      {/* Chat Header */}
      <div
        className="chat-header  text-white p-3 text-center"
        // style={{
        //   borderTopLeftRadius: "15px",
        //   borderTopRightRadius: "15px",
        // }}
      >
        <button
          className="btn p-0 position-absolute"
          onClick={close} // Replace with your close function
          style={{
            top: "7px", // Positioning at the top of the container
            right: "13px", // Positioning to the right of the container
            background: "transparent", // No background for the button
            border: "none", // No border
            cursor: "pointer",
          }}
          aria-label="Close"
        >
          <i
            className="bi bi-x-lg"
            style={{
              fontSize: "24px", // Adjust icon size
              color: "#fff", // White color for the close button
            }}
          ></i>
        </button>
        {/* Logo */}
        <div className="d-flex mb-4">
          <img
            src={ChatLogo} // Replace with your actual logo path
            alt="Chat Logo"
            className="chat-logo me-2"
          />
        </div>

        {/* Welcome Text */}
        <h5 className="mb-3 fw-bold text-start ms-3">
          Welcome to Our Customer Services!
        </h5>

        {/* Chat Info Section */}
        <div
          className="card shadow-sm card-need-help bg-white"
          style={{
            left: "47.5%",
            transform: "translateX(-50%)",
          }}
        >
          <div className="card-body d-flex text-start justify-content-between">
            <div>
              <p className="mb-1 text-dark fw-bold">
                Need help? We’re here to assist!
              </p>
              <hr />
              <div className="d-flex">
                <img
                  src={ChatIcon}
                  alt="Chat Icon"
                  className="me-2"
                  style={{ height: "30px", width: "30px" }}
                />
                <div>
                  <p className="mb-0 small">
                    <strong>Chat with us</strong>
                  </p>
                  <p
                    className="mb-0 text-muted small"
                    style={{ fontSize: "0.65em" }}
                  >
                    Hello welcome to cruisemotors.com
                  </p>
                </div>
                <span
                  className="text-muted small"
                  style={{ fontSize: "0.7em" }}
                >
                  {time}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Chat Body */}
      <div
        className="chat-body flex-grow-1"
        style={{
          background: "#f9f9f9",
          paddingTop: "120px",
        }}
      >
        <ul className="list-group">
          {departmentsList.map((dept, index) => (
            <li
              key={index}
              className={`list-group-item mb-2  rounded-3 shadow-sm bg-white ${
                selectedDepartment === dept ? "active-department" : ""
              }`}
              style={{
                border: "1px solid #ddd",
                cursor: "pointer",
                height: "3em",
                paddingTop: "11px",
              }}
              onClick={() => setSelectedDepartment(dept)}
            >
              {dept.name}
            </li>
          ))}
        </ul>

        {/* Textarea for Message */}
        <textarea
          className="form-control mt-3 shadow-sm bg-white"
          placeholder="Type your message..."
          rows="3"
          style={{
            borderRadius: "10px",
            border: "1px solid #ddd",
            resize: "none",
          }}
          value={enquiryMessage}
          onChange={(e) => setEnquiryMessage(e.target.value)}
        ></textarea>
      </div>

      {/* Chat Footer */}
      <div className="chat-footer bg-white text-center p-3">
        <button
          className="btn btn-primary w-100 py-2"
          style={{
            borderRadius: "10px",
            background: "#000740",
          }}
          onClick={handleSendEnquiry}
        >
          Start Chat
        </button>
      </div>
    </>
  );
};

export default ChatRoom;
