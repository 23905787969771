import React, { useState, useEffect, useCallback } from "react";
import Select from "react-select";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../styles/ShopHeader.css";
import { generalSearch } from "../redux/auth/authActions";
import { useDispatch, useSelector } from "react-redux";
import debounce from "lodash.debounce"; // Import debounce from lodash
import { signOutAction, getCart, cartUpdate } from "../redux/auth/authActions";
import { use } from "i18next";
import { API_URL } from "../api/authApi";
const ShopHeader = ({ cartLength, cartTotal, isMobile }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [searchOptions, setSearchOptions] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [cart, setCart] = useState([]);
  const [cartVisible, setCartVisible] = useState(false);
  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const userData = user?.data?.user || {};
  const api_url = API_URL.replace("/api", "");
  const navigate = useNavigate();
  // Custom styles for react-select
  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderRadius: "10px",
      backgroundColor: "#f8f9fa",
      border: "none", // Removes the border
      boxShadow: "none", // Removes box shadow
      paddingLeft: "10px", // Add padding for better alignment
      height: "50px", // Match the height of the input
    }),
    menu: (base) => ({
      ...base,
      minWidth: "300px", // Minimum width for the dropdown menu
      zIndex: 9999, // Ensures the dropdown is visible above other elements
    }),
    input: (provided) => ({
      ...provided,
      margin: "0",
      height:"2rem" // Ensures input field takes up the full height
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      display: "none", // Hide the default react-select arrow
    }),
    indicatorSeparator: () => ({
      display: "none", // Hide the default separator
    }),
  };

  // Debounced search function
  const fetchSearchResults = async (value) => {
    if (value?.length > 0) {
      const response = await dispatch(generalSearch({ search: value }));

      const formattedOptions = response?.data?.map((item) => ({
        value: item.id,
        label: item.name,
        slug: item.slug,
      }));

      setSearchOptions(formattedOptions || []); // Ensure array is set, even if empty
    } else {
      setSearchOptions([]); // Clear options when input is empty
    }
  };

  // Debounce the input change handler
  const debouncedSearch = useCallback(
    debounce((value) => {
      fetchSearchResults(value);
    }, 300), // 300ms delay
    []
  );

  // Handle input change
  const handleInputChange = (value) => {
    setInputValue(value);
    debouncedSearch(value); // Use debounced search
  };
  const fetchCart = async () => {
    const response = await dispatch(getCart(userData?.id));
    setCart(response?.data);
  };

  // Handle selecting an option from search
  const handleSearchChange = (selectedOption) => {
    if (selectedOption) {
      window.location.href = `/product-details/${selectedOption.value}`;
    }
  };
  const handleCartClick = () => {
    navigate("/cart"); // Navigate to the cart page
  };

  useEffect(() => {
    fetchCart();
  }, []);

  useEffect(() => {
    if (cartVisible) {
      fetchCart();
    }
  }, [cartVisible]);

  useEffect(() => {}, [searchOptions]);

  return (
    <div className="d-flex justify-content-between mb-3 mt-1">
      <div className="shop-search-bar">
        <Select
          styles={customStyles}
          options={searchOptions} // Options are displayed here
          onInputChange={handleInputChange} // Handle search input
          inputValue={inputValue}
          onChange={handleSearchChange} // Handle selection
          placeholder="What are you looking for?"
          noOptionsMessage={() => "No items found"} // Custom message if no options found
        />
        {inputValue === "" && <i className="bi bi-search shop-search-icon"></i>}
      </div>
      <div className="shop-header-icons">
        <Link to="/wishlist" className="shop-header-icon">
          <i className="bi bi-heart"></i>
        </Link>
        {/* <Link to="/cart" className="shop-header-icon cart-icon">
          <i className="bi bi-cart"></i>
          {cartLength > 0 && <span className="cart-badge">{cartLength}</span>}
        </Link> */}
        <div style={{ position: "relative" }}>
          {/* Cart Button */}
          <button
            className="btn btn-light position-relative"
            onMouseEnter={() => setCartVisible(true)}
            // onMouseLeave={() => setCartVisible(false)}
            onClick={handleCartClick}
            style={{
              border: "none",
              background: "transparent",
              cursor: "pointer",
            }}
          >
            <i className="bi bi-cart3" style={{ fontSize: "24px" }}></i>
            {cartLength > 0 && <span className="cart-badge">{cartLength}</span>}
          </button>

          {/* Cart Preview Dropdown */}
          {cartVisible && (
            <div
              className="card shadow-sm"
              style={{
                position: "absolute",
                top: "100%", // Position below the button
                right: "0",
                zIndex: 999,
                width: "300px", // Adjust width as needed
                background: "#fff",
                borderRadius: "10px",
              }}
            >
              <div
                className="card-header fw-bold d-flex justify-content-between align-items-center"
                style={{ padding: "10px" }}
              >
                <span>Cart Items</span>
                <button
                  className="btn p-0"
                  onClick={() => setCartVisible(false)} // Function to close the dropdown
                  style={{
                    background: "transparent",
                    border: "none",
                    fontSize: "20px",
                    color: "black",
                    cursor: "pointer",
                  }}
                  aria-label="Close"
                >
                  <i className="bi bi-x-lg"></i> {/* Bootstrap close icon */}
                </button>
              </div>
              <div
                className="card-body p-2"
                style={{ maxHeight: "200px", overflowY: "auto" }}
              >
                {cart?.length > 0 ? (
                  cart.map((item, index) => (
                    <div key={index} className="d-flex align-items-center mb-2">
                      {/* Item Image */}
                      <img
                        src={`${api_url}/${item?.product?.images[0]?.image?.path}`}
                        alt={item.name}
                        style={{
                          width: "50px",
                          height: "50px",
                          borderRadius: "5px",
                          marginRight: "10px",
                        }}
                      />
                      {/* Item Details */}
                      <div style={{ flex: 1 }}>
                        <p
                          className="mb-1"
                          style={{ fontSize: "14px", fontWeight: "500" }}
                        >
                          {item?.product?.name?.length > 30
                            ? `${item?.product?.name.slice(0, 30)}...`
                            : item?.product?.name}
                        </p>
                        <p
                          className="mb-0 text-muted"
                          style={{ fontSize: "12px" }}
                        >
                          {item?.quantity} x {item?.product?.price} AED
                        </p>
                      </div>
                    </div>
                  ))
                ) : (
                  <p className="text-center text-muted">No items in the cart</p>
                )}
              </div>
              <div
                className="card-footer d-flex flex-column"
                style={{ backgroundColor: "#f9f9f9", padding: "10px" }}
              >
                {/* Total Items and Price */}
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <span className="fw-bold">Total Items</span>
                  <span className="text-dark">
                    {cart?.reduce((total, item) => total + item?.quantity, 0)}{" "}
                    items for{" "}
                    <strong>
                      {cart?.reduce(
                        (total, item) =>
                          total + item?.product?.price * item?.quantity,
                        0
                      )}{" "}
                      AED
                    </strong>
                  </span>
                </div>

                {/* Buttons */}
                <div className="d-flex justify-content-between">
                  <Link
                    to="/cart"
                    className="btn btn-outline-primary"
                    style={{
                      flex: 1,
                      marginRight: "10px",
                      borderRadius: "5px",
                    }}
                  >
                    View Cart
                  </Link>
                  <Link
                    to="/cart"
                    className="btn btn-primary"
                    style={{
                      flex: 1,
                      borderRadius: "5px",
                      backgroundColor: "#000740", // Match dark blue button color
                    }}
                  >
                    Checkout
                  </Link>
                </div>
              </div>
            </div>
          )}
        </div>
        {!isMobile && cartTotal > 0 && (
          <span className="cart-total">{cartTotal} AED</span>
        )}
      </div>
    </div>
  );
};

export default ShopHeader;
