import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { RotatingLines } from "react-loader-spinner";
import loaderLogo from "../assets/images/CM LOGO DARK BLUE.png";
import { getPreviousChat } from "../redux/auth/authActions";
import { useDispatch, useSelector } from "react-redux";
import FloatingButton from "./FloatingButton";
import FloatingWhatsapp from "./FloatingWhatsapp";
import { use } from "i18next";
const PageLoader = ({ children }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { pathname } = useLocation();
  const [unread, setUnread] = useState(false);
  const [logged, setIslogged] = useState(false);
  const { isAuthenticated } = useSelector((state) => state.auth);
  const getChatRooms = async () => {
    // Start the loader
    const response = await dispatch(getPreviousChat());
    if (Array.isArray(response?.data)) {
      for (const room of response?.data) {
        if (room?.has_unread_messages === true) {
          setUnread(true);
          break;
        }
      }
    }
  };
  useEffect(() => {
    console.log(
      "useEffet in PageLoader",
      isAuthenticated,
      localStorage.authToken
    );
    if (isAuthenticated && localStorage.authToken) {
      setIslogged(true);
    }
    setIsLoading(true);
    isAuthenticated && getChatRooms();
    window.scrollTo(0, 0);
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 500);

    return () => clearTimeout(timeout);
  }, [pathname]);
  useEffect(() => {
    console.log("Unread state has changed:", unread);
  }, [unread]);
  useEffect(() => {
    console.log("window.scroll");
    window.scrollTo(0, 0);
  }, []);   

  return (
    <>
      {isLoading && (
        <div
          className="d-flex justify-content-center align-items-center vh-100 position-fixed top-0 start-0 w-100 h-100"
          style={{ zIndex: 9999, backgroundColor: "rgba(255,255,255,0.8)" }}
        >
          {/* Loader Spinner */}
          <RotatingLines
            strokeColor="#000740"
            strokeWidth="4"
            animationDuration="0.75"
            width="96"
            visible={true}
          />

          {/* Loader Logo */}
          <img
            src={loaderLogo}
            alt="Loading..."
            style={{ width: "250px", height: "250px", marginLeft: "20px" }}
          />
        </div>
      )}

      <div style={{ visibility: isLoading ? "hidden" : "visible" }}>
        {children}
      </div>

      {/* Floating Button - always visible */}
      {isAuthenticated && localStorage.authToken && (
        <>
          <FloatingButton unread={unread} />
          <FloatingWhatsapp />
        </>
      )}
    </>
  );
};

export default PageLoader;
