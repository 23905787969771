import { useEffect, useRef } from 'react';

const useScrollAnimation = () => {
  const elementsRef = useRef([]); // Shared ref across components

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const animationClass = entry.target.getAttribute('data-animation'); // Get animation class from data attribute
            const animationSpeed = entry.target.getAttribute('data-speed'); // Get speed class from data attribute

            // Add the animation and speed classes
            entry.target.classList.add('animate__animated', animationClass);
            if (animationSpeed) {
              entry.target.classList.add(animationSpeed);
            }

            observer.unobserve(entry.target); // Stop observing once the animation is triggered
          }
        });
      },
      { threshold: 0.1 } // Trigger when 10% of the element is visible
    );

    elementsRef.current.forEach((element) => {
      if (element) {
        observer.observe(element);
      }
    });

    return () => {
      elementsRef.current.forEach((element) => {
        if (element) {
          observer.unobserve(element);
        }
      });
    };
  }, []);

  return elementsRef;
};

export default useScrollAnimation;
