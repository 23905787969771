import React from "react";
import SearchFilter from "./SearchFilter";
import Card from "./ItemCard";

const CardGrid = ({ products, btnText, fromPage, removeFromWishlist, updateCart }) => {
  const test = (id) => {
    removeFromWishlist(id);
  };
  return (
    <>
      <div className="row g-2 g-sm-3 mb-5">
        {products?.map((product) => (
          <div className="col-12 col-sm-4 col-lg-3">
            <Card
              productData={product}
              btnText={btnText}
              fromPage={fromPage}
              FromWishlist={test}
              updateCart={updateCart}
            />
          </div>
        ))}
      </div>
    </>
  );
};

export default CardGrid;
