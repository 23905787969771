import React, { useEffect, useState } from "react";
import styles from "./Services.module.css";
import { Link } from "react-router-dom";
import service1 from "../assets/images/s1.png";
import service2 from "../assets/images/s2.png";
import service3 from "../assets/images/s3.png";
import service4 from "../assets/images/s4.png";
import service5 from "../assets/images/s5.png";
import service6 from "../assets/images/s6.png";

import useScrollAnimation from "./useScrollAnimation";

const UserDashboardSideMenu = () => {
  const [isMobile, setIsMobile] = useState(false);
  const elementsRef = useScrollAnimation();
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600); // Define the breakpoint for mobile
    };

    // Set initial value
    handleResize();

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      {!isMobile && (
        <div className="card bg-white border-0 rounded-10 vh-100">
          <div className="card-body">
            <ul className="list-group list-group-flush">
              <li className="list-group-item bg-white p-0">
                <Link
                  className="text-decoration-none text-dark pt-3 pb-3 d-block font-14 d-flex justify-content-start align-items-center"
                  to="/account"
                >
                  <i className="bi bi-person font-20 me-2"></i>
                  <span>My Account</span>
                </Link>
              </li>
              <li className="list-group-item bg-white p-0">
                <Link
                  className="text-decoration-none text-dark pt-3 pb-3 d-block font-14 d-flex justify-content-start align-items-center"
                  to="/password"
                >
                  <i className="bi bi-key font-20 me-2"></i>
                  <span>Change Password</span>
                </Link>
              </li>
              {/* <li className="list-group-item bg-white p-0">
                <Link
                  className="text-decoration-none text-dark pt-3 pb-3 d-block font-14 d-flex justify-content-start align-items-center"
                  to="/order"
                >
                  <i className="bi bi-cart3 font-20 me-2"></i>
                  <span>My Orders</span>
                </Link>
              </li> */}
              <li className="list-group-item bg-white p-0">
                <Link
                  className="text-decoration-none text-dark pt-3 pb-3 d-block font-14 d-flex justify-content-start align-items-center"
                  to="/car-bookings"
                >
                  <i className="bi bi-car-front font-20 me-2"></i>
                  <span>Car Bookings</span>
                </Link>
              </li>
              <li className="list-group-item bg-white p-0">
                <Link
                  className="text-decoration-none text-dark pt-3 pb-3 d-block font-14 d-flex justify-content-start align-items-center"
                  to="/inquries"
                >
                  <i className="bi bi-file-earmark-text font-20 me-2"></i>
                  <span>Inquiries</span>
                </Link>
              </li>
              {/* <li className="list-group-item bg-white p-0">
                <Link
                  className="text-decoration-none text-dark pt-3 pb-3 d-block font-14 d-flex justify-content-start align-items-center"
                  to="/notification"
                >
                  <i className="bi bi-bell font-20 me-2"></i>
                  <span>Notification</span>
                </Link>
              </li> */}
              <li className="list-group-item bg-white p-0">
                <Link
                  className="text-decoration-none text-dark pt-3 pb-3 d-block font-14 d-flex justify-content-start align-items-center"
                  to="/form-tracking"
                >
                  <i className="bi bi-geo font-20 me-2"></i>
                  <span>Form Tracking</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      )}
      {/* For mobile view, you can use an off-canvas or collapsible menu */}
      {isMobile && (
        <nav className="navbar navbar-expand-lg navbar-light bg-light d-lg-none ">
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#mobileMenu"
            aria-controls="mobileMenu"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon">
                <i className="bi bi-list font-30 text-muted p-3"></i>
            </span>
          </button>
          <div className="collapse navbar-collapse ms-2" id="mobileMenu">
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link className="nav-link" to="/account">
                  <i className="bi bi-person font-20 me-2"></i> My Account
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/password">
                  <i className="bi bi-key font-20 me-2"></i> Change Password
                </Link>
              </li>
              {/* <li className="nav-item">
                <Link className="nav-link" to="/order">
                  <i className="bi bi-cart3 font-20 me-2"></i> My Orders
                </Link>
              </li> */}
              <li className="nav-item">
                <Link className="nav-link" to="/car-bookings">
                  <i className="bi bi-car-front font-20 me-2"></i> Car Bookings
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/inquries">
                  <i className="bi bi-file-earmark-text font-20 me-2"></i>{" "}
                  Inquiries
                </Link>
              </li>
              {/* <li className="nav-item">
                <Link className="nav-link" to="/notification">
                  <i className="bi bi-bell font-20 me-2"></i> Notification
                </Link>
              </li> */}
              <li className="nav-item">
                <Link className="nav-link" to="/form-tracking">
                  <i className="bi bi-geo font-20 me-2"></i> Form Tracking
                </Link>
              </li>
            </ul>
          </div>
        </nav>
      )}
    </>
  );
};

export default UserDashboardSideMenu;
