import "./ServicePage.css";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import SecondaryHeader from "../components/SecondaryHeader";
import Footer from "../components/Footer";
import banner from "../assets/images/image (4).png";
import RedirectToSignin from "../components/RedirectToSignin";
import PrimaryMobileHeader from "../components/PrimaryMobileHeader";
import RedirectToHome from "../components/RedirectToHome";
import { Navigate } from "react-router-dom";
import { useHistory } from "react-router-dom";
import documentlogo from "../assets/images/gallerylogo.png";
import { useNavigate } from "react-router-dom";
const ReviewPage = () => {
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector((state) => state.auth);
  const [isMobile, setIsMobile] = useState(false);

  const location = useLocation();
  const { formData } = location.state || {};
  console.log("formData", formData);

  // Inside your ReviewPage component
  //   const history = useHistory();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const navigate = useNavigate();

  const handlePayment = () => {
    navigate("/documentspayments", {
      state: {
        subtotal: formData.subtotal,
        grandTotal: formData.grandTotal,
      },
    });
  };
  //   const handlePayNow = () => {
  //     history.push("/payment", {
  //       subtotal: formData.subtotal,
  //       grandTotal: formData.grandTotal,
  //     });
  //   };
  const CertificationSection = ({ certificates }) => {
    return (
      <div style={{ }}>
        {certificates
          .filter((certificate) => certificate.selected) // Only show selected certificates
          .map((certificate) => (
            <div
              className="mb-3"
              key={certificate.id}
              // style={{ width: "250px", height: "39px" }}
            >
              <div className="d-flex justify-content-between">
                <label className="text-muted" style={{ fontSize: "16px" }}>{certificate.name}</label>
                <i
                  className="bi bi-check-circle certificate-icon"
                  style={{
                    height: "20px",
                    width: "20px",
                    backgroundColor: "#00B80F",
                    color: "white",
                    borderRadius: "50%",
                    padding: "5px",
                    border: "none",
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                ></i>
              </div>
              <hr className="line" style={{ marginTop: "5px" }} />
            </div>
          ))}
      </div>
    );
  };

  return (
    <div className="bg-white">
      <RedirectToSignin />
      <RedirectToHome />
      {!isMobile ? <SecondaryHeader /> : <PrimaryMobileHeader />}
      <div className="container">
        <div className="tendorsPage mt-4 pb-5 ">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-7" style={{ width: "1300px" }}>
              <img
                src={banner}
                alt="Banner"
                className="img-fluid mb-5"
                style={{ height: "494px", width: "1308px", objectFit: "cover" }}
              />
            </div>
            <div className="col-12 col-sm-7" style={{ width: "1038px" }}>
              <h2
                className="text-center fw-bold"
                style={{ fontSize: "40px", color: "#050B20" }}
              >
                Documentation & Certification
              </h2>
              <div
                className="container table-responsive border align-content-center custom-border p-5"
                style={{
                  borderRadius: "20px",
                }}
              >
                <div className="row table-responsive mt-4">
                  {/* Vertical Line */}
                  {/* <hr style={{rotate:}}></hr> */}
                  <div
                    className="col-md-7 order-right-only"
                    style={{
                      borderRight:
                        "2px solid #ced4da" /* Adjust color and thickness as needed */,
                      borderTop: "none",
                      borderBottom: "none",
                      borderLeft: "none",
                    }}
                  >
                    {/* Vehicle Details Section */}
                    <div className="row mb-3">
                      <div className="col-md-5 text-muted">Make</div>
                      <div className="col-md-5 text-muted">Model</div>
                      <div
                        className="col-md-2 text-muted"
                        style={{
                          marginRight: 0,
                          float: "right",
                          display: "flex",
                        }}
                      >
                        Year
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-5">
                        {formData?.selectedCarMake}
                      </div>
                      <div className="col-md-5">
                        {formData.selectedCarModel}
                      </div>
                      <div className="col-md-2">{formData.year}</div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-12 text-muted">VIN Number</div>
                      <div className="col-md-12">{formData.vin}</div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-12 text-muted">Comments</div>
                      <div className="col-md-12">{formData.comments}</div>
                    </div>
                    <div className="row">
                      {/* INVOICES */}
                      <div className="col-md-5 text-muted mb-3">
                        <label>INVOICES</label>
                        <div
                          className="border d-flex justify-content-center align-items-center"
                          style={{
                            height: "128px",
                            width: "128px",
                            borderRadius: "10px",
                          }}
                        >
                          <img
                            style={{ width: "53px", height: "53px" }}
                            src={documentlogo}
                            alt="Document Logo"
                          />
                        </div>
                        {/* Display file name for Invoice */}
                        {formData?.attachments?.find(
                          (att) => att.name === "Invoice"
                        )?.file?.name ? (
                          <p className="mt-2 text-muted">
                            {
                              formData.attachments.find(
                                (att) => att.name === "Invoice"
                              ).file.name
                            }
                          </p>
                        ) : (
                          <p className="mt-2 text-muted">No file uploaded</p>
                        )}
                      </div>

                      {/* EXPORT DOCUMENTS */}
                      <div className="col-md-5 text-muted">
                        <label>EXPORT_DOCUMENTS</label>
                        <div
                          className="border d-flex justify-content-center align-items-center"
                          style={{
                            height: "128px",
                            width: "128px",
                            borderRadius: "10px",
                          }}
                        >
                          <img
                            style={{ width: "53px", height: "53px" }}
                            src={documentlogo}
                            alt="Document Logo"
                          />
                        </div>
                        {/* Display file name for Export Documents */}
                        {formData?.attachments?.find(
                          (att) => att.name === "Export Documents"
                        )?.file?.name ? (
                          <p className="mt-2 text-muted">
                            {
                              formData.attachments.find(
                                (att) => att.name === "Export Documents"
                              ).file.name
                            }
                          </p>
                        ) : (
                          <p className="mt-2 text-muted">No file uploaded</p>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="col table-responsive">
                    {/* Certification Section */}
                    <CertificationSection
                      certificates={formData.certificates}
                    />
                  </div>
                  <div></div>
                </div>
              </div>
              <div>
                <div
                  className="totals mt-4"
                  style={{
                    // width: "743px",
                    margin: "0 auto",
                    marginBottom: "8px",
                  }}
                >
                  <div className="d-flex flex-column ">
                    <div
                      className="text-end"
                      style={{
                        marginTop: "8px",
                        marginBottom: "8px",
                        width: "100%",
                      }}
                    >
                      <label style={{ marginBottom: "8px" }}>
                        <span className="me-5">Sub Total:</span>
                        <span className="ms-2">{formData.subtotal} AED</span>
                      </label>
                      <hr
                        className="line"
                        style={{ width: "100%", margin: "0" }}
                      />
                    </div>
                    <div
                      className="text-end"
                      style={{
                        marginTop: "8px",
                        marginBottom: "8px",
                        width: "100%",
                      }}
                    >
                      <label style={{ marginBottom: "8px" }}>
                        <span style={{}}>
                          <strong className="me-5">Grand Total:</strong>
                        </span>
                        <span className="ms-2">{formData.grandTotal} AED</span>
                      </label>
                      <hr
                        className="line"
                        style={{ width: "100%", margin: "0" }}
                      />
                    </div>
                  </div>
                </div>
                <div style={{ textAlign: "center", marginTop: "20px" }}>
                  <button
                    type="submit"
                    className="btn btn-primary w-267 h-52"
                    style={{height: "45px",fontSize: "20px",}}
                    onClick={handlePayment}
                  >
                    Continue to Payment
                  </button>
                  <p style={{ marginTop: "10px", color: "gray" }}>
                    We usually respond between 30-50 minutes ...
                  </p>
                </div>

                {/* Other content */}
              </div>
            </div>
            <div className="container-text col-12 col-sm-7">
              {/* Container for the fade effect */}
              <div className="fade-container">
                <div className="scrollable-section">
                  <div className="scrollable-content ms-2">
                    <div className="row">
                      {/* Payment Methods Section */}
                      <div className="col-12 pb-3">
                        <p>
                          <h4>
                            Obtain Your Vehicle Documentation & Certificates
                            with Ease
                          </h4>
                        </p>
                        <p>
                          At Cruise Motors, we know how important it is to have
                          the correct documentation and certifications for your
                          vehicle, whether for export, compliance, or
                          importation. That’s why we’ve made it simple for you
                          to apply for the required documents through our
                          Documentation & Certification service. With a quick
                          form submission, we handle the rest— catering to
                          certificate requirements for various countries, no
                          matter how specific.
                        </p>
                        <p>
                          <strong>What Certificates Can You Apply For?</strong>
                        </p>
                        <p>
                          We offer a range of essential vehicle documents to
                          meet your needs:
                        </p>
                        <p>
                          <strong>
                            . RTA Export Certificate (without Plate Number):
                          </strong>{" "}
                          Required for vehicle exports, this certificate proves
                          your car is ready for export without the need for a
                          plate number.
                        </p>
                        <p>
                          <strong>
                            .GSO (Gulf Standards Organization) Certificate:{" "}
                          </strong>
                          For vehicles destined for GCC countries, this
                          certification ensures compliance with regional
                          standards.
                        </p>
                        <p>
                          <strong>.Certificate of Origin:</strong> Required by
                          customs in many countries, this certificate confirms
                          where your vehicle was manufactured and helps ensure
                          smooth importation.
                        </p>
                        <p>
                          <strong>.Certificate of Conformity</strong>: Essential
                          for vehicle imports, this certificate confirms your
                          car meets the safety and environmental standards of
                          your destination country.
                        </p>
                      </div>

                      {/* <div className="col-12">
                        <Link className="theme-color" to="/terms-and-condition">
                          {t("trms_condtn")}
                        </Link>
                      </div> */}

                      {/* Additional Information Section */}
                      <div className="col-12 mt-4">
                        <p>
                          <strong>How it Works</strong>
                        </p>
                        <p>
                          <strong>
                            Step 1: Fill Out the Application Form & Make the
                            Payments
                          </strong>{" "}
                          We Simply fill in the form with your vehicle details
                          and the specific documentation or certificates you
                          require. This includes information like the make,
                          model, year, and any additional details relevant to
                          the certification.
                        </p>
                        <p>
                          <strong>Step 2: We Process Your Request</strong> We
                          Once we have your submission, our team will review the
                          information and begin the process of obtaining your
                          requested documents. We work closely with authorities
                          and certification bodies to ensure everything is done
                          accurately and efficiently.
                        </p>
                        <p>
                          <strong>Step 3: Receive Your Certificates</strong> We
                          Once the documents are issued, we’ll notify you and
                          arrange for the swift delivery of your vehicle
                          certificates. Whether digital or physical copies, we
                          ensure they are delivered promptly so you can move
                          forward with your vehicle plans.
                        </p>
                      </div>

                      <div className="col-12 mt-4">
                        <h4 className="fw-bold">
                          Why Apply for Certificates through Cruise Motors?
                        </h4>
                        <p>
                          Comprehensive Documentation Solutions: We handle every
                          type of vehicle certificate— from export certificates
                          to compliance documents—making it easier for you to
                          meet regulatory requirements.
                        </p>
                        <p>
                          For Each Country: Different countries have different
                          certification needs, and we cater to all. Whether
                          you’re exporting or importing, we ensure your vehicle
                          has the correct documents in place.
                        </p>
                      </div>
                      <div className="col-12 mt-4">
                        <h4 className="fw-bold">
                          Ready to Obtain Your Vehicle Certificates?
                        </h4>
                        <p>
                          If you need an RTA Export Certificate, GSO
                          Certificate, or any other vehicle documentation, we’re
                          here to help. Fill out our Documentation &
                          Certification Form today and let us handle the process
                          from start to finish.
                        </p>
                        <p>
                          Get your vehicle certified quickly and hassle-free
                          with Cruise Motors. Apply now!
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ReviewPage;
