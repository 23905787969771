import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { signOutAction, getCart, cartUpdate } from "../redux/auth/authActions";
import Footer from "../components/Footer";
import RedirectToHome from "../components/RedirectToHome";
import RedirectToSignin from "../components/RedirectToSignin";
import PrimaryMobileHeader from "../components/PrimaryMobileHeader";
import SecondaryHeader from "../components/SecondaryHeader";
import CartFilter from "../components/CartFilter";
import { Container } from "react-bootstrap";
import Checkout from "../components/CartDetails";
import { api_url } from "../api/authApi";
import ShopHeader from "../components/ShopHeader";
const Cart = () => {
  const dispatch = useDispatch();
  const [isMobile, setIsMobile] = useState(false);
  const [cart, setCart] = useState([]);
  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const userData = user?.data?.user || {};
  const handleSignOut = () => {
    dispatch(signOutAction());
  };
  const fetchCart = async () => {
    const response = await dispatch(getCart(userData?.id));
    setCart(response?.data);
  };

  const updateCart = async (data) => {
    const response = await dispatch(cartUpdate(data));
    setCart(response?.data);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };
    fetchCart();
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div style={{ backgroundColor: "white" }}>
      <RedirectToSignin />
      <RedirectToHome />
      {!isMobile ? <SecondaryHeader /> : <PrimaryMobileHeader />}
      <div className="container">
        {/* <CartFilter /> */}
        <ShopHeader/>
        <Checkout cart={cart} updateCart={updateCart}/>
      </div>
      <Footer />
    </div>
  );
};

export default Cart;
