import "./ServicePage.css";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import SecondaryHeader from "../components/SecondaryHeader";
import Footer from "../components/Footer";
import banner from "../assets/images/image (4).png";
import RedirectToSignin from "../components/RedirectToSignin";
import PrimaryMobileHeader from "../components/PrimaryMobileHeader";
import RedirectToHome from "../components/RedirectToHome";

function DocumentPayments() {
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector((state) => state.auth);
  const [isMobile, setIsMobile] = useState(false);

  const location = useLocation();
  const { subtotal, grandTotal } = location.state || {
    subtotal: 0,
    grandTotal: 0,
  }; // Default values

  const [formData, setFormData] = useState({
    cardHolderName: "",
    cardNumber: "",
    expiryDate: "",
    cvc: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="bg-white">
      <RedirectToSignin />
      <RedirectToHome />
      {!isMobile ? <SecondaryHeader /> : <PrimaryMobileHeader />}
      <div className="container">
        <div className="tendorsPage mt-4 pb-5">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-7" style={{ width: "1300px" }}>
              <img
                src={banner}
                alt="Banner"
                className="img-fluid mb-5"
                style={{ height: "494px", width: "1308px", objectFit: "cover" }}
              />
            </div>
            <div className="col-12 col-sm-7" style={{ width: "1038px" }}>
              <h2
                className="text-center fw-bold"
                style={{ fontSize: "40px", color: "#050B20" }}
              >
                Documentation & Certificates
              </h2>
              <div
                className="container mt-5 align-content-center"
                style={{
                  // height: "464px",
                  // width: "743px",
                  borderRadius: "20px",
                }}
              >
                <div className="container mt-5">
                  <p className="text-end">
                    Sub Total:
                    <span style={{ marginLeft: "100px" }}>{subtotal} AED</span>
                  </p>
                  <hr className="line" />

                  <p className="text-end">
                    <strong>Grand Total:</strong>
                    <strong>
                      {" "}
                      <span style={{ marginLeft: "100px" }}>
                        {grandTotal} AED
                      </span>
                    </strong>
                  </p>
                  <hr className="line" />
                  <form
                    onSubmit={handleSubmit}
                    className="payment-form"
                    style={{ marginTop: "30px" }}
                  >
                    <div className="mb-3">
                      <input
                        type="text"
                        name="cardHolderName"
                        placeholder="Card Holder Name"
                        value={formData.cardHolderName}
                        onChange={handleChange}
                        required
                        className="form-control bg-white doc-page-input"
                      />
                    </div>
                    <div className="mb-3">
                      <input
                        type="text"
                        name="cardNumber"
                        placeholder="Card Number"
                        value={formData.cardNumber}
                        onChange={handleChange}
                        required
                        maxLength="16"
                        className="form-control bg-white doc-page-input"
                      />
                    </div>
                    <div className="row mb-3">
                      <div className="col">
                        <input
                          type="text"
                          name="expiryDate"
                          placeholder="Expiry Date (MM/YY)"
                          value={formData.expiryDate}
                          onChange={handleChange}
                          required
                          className="form-control bg-white doc-page-input"
                        />
                      </div>
                      <div className="col">
                        <input
                          type="text"
                          name="cvc"
                          placeholder="CVC"
                          value={formData.cvc}
                          onChange={handleChange}
                          required
                          maxLength="4"
                          className="form-control bg-white doc-page-input"
                        />
                      </div>
                    </div>
                    <div
                      style={{ textAlign: "center", marginTop: "20px" }}
                      className="mb-3"
                    >
                      <button
                        type="submit"
                        className="btn btn-primary mt-2 mb-2"
                        style={{ width: "267px", height: "52px" }}
                      >
                        Pay Now
                      </button>
                      <p style={{ marginTop: "10px", color: "gray" }}>
                        We usually respond between 30-50 minutes ...
                      </p>
                    </div>
                  </form>
                  {/* <p className="text-center mt-3">We usually respond between 30-50 minutes</p> */}
                </div>
              </div>
            </div>
            <div className="container-text col-12 col-sm-7 mt-5">
              {/* Container for the fade effect */}
              <div className="fade-container">
                <div className="scrollable-section">
                  <div className="scrollable-content ms-2">
                    <div className="row">
                      {/* Payment Methods Section */}
                      <div className="col-12 pb-3">
                        <p>
                          <h4>
                            Obtain Your Vehicle Documentation & Certificates
                            with Ease
                          </h4>
                        </p>
                        <p>
                          At Cruise Motors, we know how important it is to have
                          the correct documentation and certifications for your
                          vehicle, whether for export, compliance, or
                          importation. That’s why we’ve made it simple for you
                          to apply for the required documents through our
                          Documentation & Certification service. With a quick
                          form submission, we handle the rest— catering to
                          certificate requirements for various countries, no
                          matter how specific.
                        </p>
                        <p>
                          <strong>What Certificates Can You Apply For?</strong>
                        </p>
                        <p>
                          We offer a range of essential vehicle documents to
                          meet your needs:
                        </p>
                        <p>
                          <strong>
                            . RTA Export Certificate (without Plate Number):
                          </strong>{" "}
                          Required for vehicle exports, this certificate proves
                          your car is ready for export without the need for a
                          plate number.
                        </p>
                        <p>
                          <strong>
                            .GSO (Gulf Standards Organization) Certificate:{" "}
                          </strong>
                          For vehicles destined for GCC countries, this
                          certification ensures compliance with regional
                          standards.
                        </p>
                        <p>
                          <strong>.Certificate of Origin:</strong> Required by
                          customs in many countries, this certificate confirms
                          where your vehicle was manufactured and helps ensure
                          smooth importation.
                        </p>
                        <p>
                          <strong>.Certificate of Conformity</strong>: Essential
                          for vehicle imports, this certificate confirms your
                          car meets the safety and environmental standards of
                          your destination country.
                        </p>
                      </div>

                      {/* <div className="col-12">
                        <Link className="theme-color" to="/terms-and-condition">
                          {t("trms_condtn")}
                        </Link>
                      </div> */}

                      {/* Additional Information Section */}
                      <div className="col-12 mt-4">
                        <p>
                          <strong>How it Works</strong>
                        </p>
                        <p>
                          <strong>
                            Step 1: Fill Out the Application Form & Make the
                            Payments
                          </strong>{" "}
                          We Simply fill in the form with your vehicle details
                          and the specific documentation or certificates you
                          require. This includes information like the make,
                          model, year, and any additional details relevant to
                          the certification.
                        </p>
                        <p>
                          <strong>Step 2: We Process Your Request</strong> We
                          Once we have your submission, our team will review the
                          information and begin the process of obtaining your
                          requested documents. We work closely with authorities
                          and certification bodies to ensure everything is done
                          accurately and efficiently.
                        </p>
                        <p>
                          <strong>Step 3: Receive Your Certificates</strong> We
                          Once the documents are issued, we’ll notify you and
                          arrange for the swift delivery of your vehicle
                          certificates. Whether digital or physical copies, we
                          ensure they are delivered promptly so you can move
                          forward with your vehicle plans.
                        </p>
                      </div>

                      <div className="col-12 mt-4">
                        <h4 className="fw-bold">
                          Why Apply for Certificates through Cruise Motors?
                        </h4>
                        <p>
                          Comprehensive Documentation Solutions: We handle every
                          type of vehicle certificate— from export certificates
                          to compliance documents—making it easier for you to
                          meet regulatory requirements.
                        </p>
                        <p>
                          For Each Country: Different countries have different
                          certification needs, and we cater to all. Whether
                          you’re exporting or importing, we ensure your vehicle
                          has the correct documents in place.
                        </p>
                      </div>
                      <div className="col-12 mt-4">
                        <h4 className="fw-bold">
                          Ready to Obtain Your Vehicle Certificates?
                        </h4>
                        <p>
                          If you need an RTA Export Certificate, GSO
                          Certificate, or any other vehicle documentation, we’re
                          here to help. Fill out our Documentation &
                          Certification Form today and let us handle the process
                          from start to finish.
                        </p>
                        <p>
                          Get your vehicle certified quickly and hassle-free
                          with Cruise Motors. Apply now!
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default DocumentPayments;
