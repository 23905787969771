import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCarsByBrand } from "../redux/auth/authActions";
import { useLocation } from "react-router-dom";
import ProductGrid from "../components/ProductGrid";
import CustomPagination from "../components/CustomPagination"; // Import the custom component
import Footer from "../components/Footer";
import SecondaryHeader from "../components/SecondaryHeader";
import PrimaryMobileHeader from "../components/PrimaryMobileHeader";
import RedirectToHome from "../components/RedirectToHome";
import RedirectToSignin from "../components/RedirectToSignin";
import { RotatingLines } from "react-loader-spinner";
import loaderLogo from "../assets/images/CM LOGO DARK BLUE.png";
import { API_URL } from "../api/authApi";
import { Button } from "react-bootstrap";
import { BsGrid, BsList } from "react-icons/bs";
import BgForBrandModels from "../components/BgForBrandModels";
const BrandModels = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [cars, setCars] = useState([]);
  const api_url = API_URL.replace("/api", "");
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(null);
  const { isAuthenticated } = useSelector((state) => state?.auth);
  const searchParams = new URLSearchParams(location?.search);
  const brand_slug = searchParams?.get("make");
  const model_slug = searchParams?.get("model");
  const vin = searchParams?.get("vin");
  const [logo, setLogo] = useState("");
  const [brandName, setBrandName] = useState("");
  const [backgroundLogo, setBackgroundLogo] = useState("");
  const [isLuxury, setIsLuxury] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const fetchCars = useCallback(
    async (page = 1, limit = 50, order_by = "new") => {
      setIsLoading(true);
      try {
        const response = await dispatch(
          getCarsByBrand({
            page,
            make: brand_slug,
            model: model_slug,
            limit,
            order_by,
          })
        );
        setIsLoading(false);
        setCars(response?.data?.result?.data || []);
        setLogo(`${api_url}/${response?.data?.car_make?.logo?.path}`);
        setBackgroundLogo(
          `${api_url}/${response?.data?.car_make?.background_logo?.path}`
        );
        console.log(
          "lllllll:",
          `${api_url}/${response?.data?.car_make?.background_logo?.path}`
        );
        setIsLuxury(response?.data?.car_make?.is_luxury || false);
        setBrandName(response?.data?.car_make?.name || "");
        setCurrentPage(response?.data?.result?.current_page || page);
        setLastPage(response?.data?.result?.last_page);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching cars:", error);
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch, brand_slug, model_slug]
  );

  useEffect(() => {
    if (brand_slug) {
      setCars([]);
      setCurrentPage(1);
      setLastPage(null);
      fetchCars(1);
    }
  }, [brand_slug, fetchCars]);

  const handlePageChange = (page) => {
    if (page >= 1 && page <= lastPage) {
      setCurrentPage(page);
      fetchCars(page);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600); // Define the breakpoint for mobile
    };
    
    // Set initial value
    handleResize();

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <div className="">
        <BgForBrandModels url={backgroundLogo} isBlack={isLuxury} />
        <RedirectToSignin />
        <RedirectToHome />
        {!isMobile ? (
          <SecondaryHeader isBlack={isLuxury} />
        ) : (
          <PrimaryMobileHeader />
        )}
        <div className="container">
          {isLoading ? (
            <div className="d-flex justify-content-center align-items-center vh-100">
              <RotatingLines
                strokeColor="#000740"
                strokeWidth="4"
                animationDuration="0.75"
                width="96"
                visible={true}
              />
              <img
                src={loaderLogo}
                style={{ width: "250px", height: "250px" }}
                alt="Loading"
              />
            </div>
          ) : (
            <>
              <div className="text-center mb-2">
                <img
                  src={logo}
                  alt="Logo"
                  className="img-fluid"
                  style={{
                    width: "180px",
                    height: "150px",
                    objectFit: "contain",
                  }}
                />
                <h4
                  className="text-uppercase fw-bold mt-1"
                  style={{
                    ...(isLuxury && {
                      color: "white",
                    }), // Style for companyLogo // Style for alternateLogo
                  }}
                >
                  {brandName}
                </h4>
              </div>
              <ProductGrid
                products={cars}
                car_make_slug={brand_slug}
                car_model_slug={model_slug}
                vin={vin}
                fetchCars={fetchCars}
                isLuxury={isLuxury}
              />
              <div className="d-flex justify-content-end mt-2">
                <CustomPagination
                  currentPage={currentPage}
                  lastPage={lastPage}
                  onPageChange={handlePageChange}
                  isLuxury={isLuxury}
                />
              </div>
            </>
          )}
        </div>
        {!isLoading && <Footer isBlack={isLuxury} />}
      </div>
    </>
  );
};

export default BrandModels;
