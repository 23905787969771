import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Result } from "antd";
import { signOutAction } from "../redux/auth/authActions";
import logo from "../assets/images/blue-logo.png"; // Import the image file
import checked from "../assets/images/checked.png";
import { Navigate, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
const SuccessForm = ({ refId, resetForm }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isAuthenticated } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  //   const [refId, setRefId] = useState("1234");
  const handleSignOut = () => {
    dispatch(signOutAction());
  };
  useEffect(() => {
    const detectedLanguage =
      localStorage.getItem("selectedLanguage") ||
      navigator.language.split("-")[0] ||
      "en";
    i18n.changeLanguage(detectedLanguage);
    document.documentElement.lang = detectedLanguage;
  }, []);
  if (!isAuthenticated) {
    return <Navigate to="/signin" />;
  }

  return (
    <div className="vh-100" style={{ backgroundColor: "var(--bs-body-bg)" }}>
      <div className="container d-flex justify-content-center align-items-center pt-5 pb-5">
        <div className="login-register">
          <div className="card text-center bg-white rounded-20 mt-5 mb-5">
            <div className="card-body p-5">
              <img src={logo} alt="Logo" width="200" className="" />
              {/* <div><img src={checked} alt='Icon' width='80' className='mb-3' /></div> */}
              <Result
                status="success"
                title={t("form_success_text")}
                subTitle={
                  <>
                    {t("reference_id")}
                    <span style={{ color: "#08b7f0", fontWeight: "bold" }}>
                      {refId}
                    </span>
                  </>
                }
                extra={[
                  <Button
                    type="primary"
                    key="home"
                    onClick={() => navigate("/")}
                  >
                    {t("home")}
                  </Button>,
                  <Button
                    key="submit-again"
                    onClick={() => {
                      resetForm(); // This should trigger the console log and reset
                    }}
                  >
                     {t("go_back")}
                  </Button>,
                ]}
                // subTitle="Your registration is currently being reviewed."
              />

              {/* 
                            <p className='text-muted'>Your registration is currently being reviewed.</p> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessForm;
