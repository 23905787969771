const getMainMenu = (t) => {
  return [

    {
      key: "services",
      label: t("menu.services"),
      path: "/services",
      children: [
        {
          key: "tender",
          label: t("menu.tender"),
          icon: <i className="bi bi-file-earmark-text"></i>,
          path: "/tender",
        },
        {
          key: "supply_contract",
          label: t("menu.supply_contract"),
          icon: <i className="bi bi-box"></i>,
          path: "/supply-contract",
        },
        {
          key: "spare_parts",
          label: t("menu.spare_parts"),
          icon: <i className="bi bi-tools"></i>,
          path: "/spare-parts",
        },
        {
          key: "service_kits",
          label: t("menu.service_kits"),
          icon: <i className="bi bi-gear"></i>,
          path: "/service-kits",
        },
        {
          key: "warranty",
          label: t("menu.warranty"),
          icon: <i className="bi bi-shield-check"></i>,
          path: "/warranty-information",
        },
        {
          key: "cross_trade",
          label: t("menu.cross_trade"),
          icon: <i className="bi bi-arrow-left-right"></i>,
          path: "/cross-trade",
        },
      ],
    },
    {
      key: "logistics",
      label: t("menu.logistics"),
      path: "/logistics",
      children: [
        {
          key: "shipping",
          label: t("menu.request_for_quotation"),
          icon: <i className="bi bi-file-earmark-text"></i>,
          path: "/request-for-quotation",
        },
        {
          key: "shipping",
          label: t("menu.documentation_certificates"),
          icon: <i className="bi bi-file-earmark-medical"></i>,
          path: "/documents",
        },
      ],
    },
    {
      key: "modifications",
      label: t("menu.modifications"),
      path: "/modifications",
    },
    {
      key: "payment_method",
      label: t("menu.payment_method"),
      path: "/payment-method",
      children: [
        {
          key: "bank_account_details",
          label: t("menu.bank_account_details"),
          icon: <i className="bi bi-bank"></i>,
          path: "/bank-detail",
        },
        {
          key: "letter_of_credit",
          label: t("menu.letter_of_credit"),
          icon: <i className="bi bi-file-earmark-text"></i>,
          path: "/letter-of-credit",
        },
        {
          key: "cash",
          label: t("menu.cash"),
          icon: <i className="bi bi-cash-stack"></i>,
          path: "/cash",
        },
        {
          key: "rules",
          label: t("menu.rules_60_40"),
          icon: <i className="bi bi-percent"></i>,
          path: "/rules",
        },
        {
          key: "crypto_currency",
          label: t("menu.usdt_crypto"),
          icon: <i className="bi bi-coin"></i>,
          path: "/crypto-currency",
        },
        {
          key: "payment_terms",
          label: t("menu.terms_conditions"),
          icon: <i className="bi bi-file-earmark-lock"></i>,
          path: "/terms-and-condition",
        },
      ],
    },
    {
      key: "make_a_deal",
      label: t("menu.make_a_deal"),
      path: "/make-a-deal",
    },
  ];
};

const getFooterMenu = (t) => {
  return [
    { name: t("menu.footer.home"), path: "/" },
    { name: t("menu.footer.about"), path: "/about" },
    { name: t("menu.footer.contact"), path: "/contact" },
  ];
};

export default { getMainMenu, getFooterMenu };
