import React from "react";
import { Pagination } from "react-bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css"; // Import Bootstrap Icons
import "./CustomPagination.css";

const CustomPagination = ({
  currentPage,
  lastPage,
  onPageChange,
  isLuxury,
}) => {
  const getPageNumbers = () => {
    const pages = [];

    // Always show the first page
    pages.push(1);

    // Determine if an ellipsis is needed after the first page
    if (currentPage > 3) {
      pages.push("...");
    }

    // Display the current page with one page before and one after, if within bounds
    for (
      let i = Math.max(2, currentPage - 1);
      i <= Math.min(lastPage - 1, currentPage + 1);
      i++
    ) {
      pages.push(i);
    }

    // Add an ellipsis if there’s a gap between the current range and the last page
    if (currentPage < lastPage - 2) {
      pages.push("...");
    }

    // Always show the last page
    if (lastPage > 1) {
      pages.push(lastPage);
    }

    return pages;
  };

  return (
    <Pagination className={`justify-content-center custom-pagination ${
      isLuxury ? "luxury-custom-pagination" : ""
    }`}>
      <Pagination.First
        className="custom-page-first"
        onClick={() => onPageChange(1)}
        disabled={currentPage === 1}
        style={{ borderRadius: "10px" }}
      >
        <i className="bi bi-chevron-double-left" style={{ color: isLuxury ? "white" : "black" }}></i>{" "}
        {/* Double left icon */}
      </Pagination.First>

      <Pagination.Prev
        className="custom-page-prev"
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
      >
        <i className="bi bi-chevron-left" style={{ color: isLuxury ? "white" : "black" }}></i>{" "}
        {/* Single left icon */}
      </Pagination.Prev>

      {getPageNumbers().map((page, index) =>
        typeof page === "number" ? (
          <Pagination.Item
            className="custom-page-item"
            key={page}
            active={page === currentPage}
            onClick={() => onPageChange(page)}
          >
            {page}
          </Pagination.Item>
        ) : (
          <Pagination.Ellipsis
            key={`ellipsis-${index}`}
            className="dotdot"
            disabled
            style={{ marginLeft: "1rem" }}
          />
        )
      )}

      <Pagination.Next
        className="custom-page-next"
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === lastPage}
      >
        <i className="bi bi-chevron-right" style={{ color: isLuxury ? "white" : "black" }}></i>{" "}
        {/* Single right icon */}
      </Pagination.Next>

      <Pagination.Last
        className="custom-page-last"
        onClick={() => onPageChange(lastPage)}
        disabled={currentPage === lastPage}
      >
        <i
          className="bi bi-chevron-double-right"
          style={{ color: isLuxury ? "white" : "black" }}
        ></i>{" "}
        {/* Double right icon */}
      </Pagination.Last>
    </Pagination>
  );
};

export default CustomPagination;
