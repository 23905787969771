import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import productImg from "../assets/images/ss4.png";
import mainImg from "../assets/images/Frame 894.png";
import "../styles/ProductDetailsPage.css";
import RelatedItemsCarousel from "../components/RelatedItemsCarousel";
import ShopHeader from "../components/ShopHeader";
import Footer from "../components/Footer";
import RedirectToSignin from "../components/RedirectToSignin";
import RedirectToHome from "../components/RedirectToHome";
import PrimaryMobileHeader from "../components/PrimaryMobileHeader";
import SecondaryHeader from "../components/SecondaryHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart as solidHeart } from "@fortawesome/free-solid-svg-icons"; // Solid heart for wishlist icon
import { faHeart as regularHeart } from "@fortawesome/free-regular-svg-icons"; // Regular heart for unselected
import {
  getProductDetails,
  removeWishList,
  cartUpdate,
} from "../redux/auth/authActions";
import { useLocation } from "react-router-dom";
import sampImage from "../assets/images/eshop.png";
import { FaStar, FaStarHalfAlt, FaRegStar } from "react-icons/fa";
const ProductDetailsPage = () => {
  const [isMobile, setIsMobile] = useState(false);
  const dispatch = useDispatch();
  const [quantity, setQuantity] = useState(1);
  const [selectedImage, setSelectedImage] = useState("");
  const [product, setProduct] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isInCart, setIsInCart] = useState(false);
  const location = useLocation();
  const [wishlistAdded, setWishlistAdded] = useState(false);
  const { id, fromPage } = location.state || {};
  const [activeTab, setActiveTab] = useState("description");
  const renderStars = (rating) => {
    const fullStars = Math.floor(rating); // Number of fully filled stars
    const hasHalfStar = rating % 1 >= 0.5; // Check if there’s a half-star
    const emptyStars = 5 - fullStars - (hasHalfStar ? 1 : 0); // Remaining empty stars

    return (
      <>
        {Array.from({ length: fullStars }).map((_, index) => (
          <FaStar key={`full-${index}`} color="#FFD700" /> // Filled star
        ))}
        {hasHalfStar && <FaStarHalfAlt color="#FFD700" />}
        {Array.from({ length: emptyStars }).map((_, index) => (
          <FaRegStar key={`empty-${index}`} color="#FFD700" /> // Empty star
        ))}
      </>
    );
  };
  const [timeRemaining, setTimeRemaining] = useState({
    hours: "00",
    minutes: "00",
    seconds: "00",
  });
  useEffect(() => {
    const calculateRemainingTime = () => {
      const now = new Date();
      const endOfDay = new Date();
      endOfDay.setHours(23, 59, 59, 999); // Set time to the last moment of the day

      const diff = endOfDay - now; // Difference in milliseconds

      if (diff > 0) {
        const hours = Math.floor((diff / (1000 * 60 * 60)) % 24)
          .toString()
          .padStart(2, "0");
        const minutes = Math.floor((diff / (1000 * 60)) % 60)
          .toString()
          .padStart(2, "0");
        const seconds = Math.floor((diff / 1000) % 60)
          .toString()
          .padStart(2, "0");

        setTimeRemaining({ hours, minutes, seconds });
      } else {
        setTimeRemaining({ hours: "00", minutes: "00", seconds: "00" });
      }
    };

    const timer = setInterval(calculateRemainingTime, 1000);

    return () => clearInterval(timer); // Cleanup timer
  }, []);

  const toggleWishlist = async () => {
    await dispatch(removeWishList(id)); // Toggle wishlist state
    fetchData();
  };

  const handleQuantityChange = (value) => {
    if (quantity + value > 0) {
      setQuantity(quantity + value);
    }
  };

  const updateCart = async (id, method) => {
    const data = {
      product_id: id,
      quantity: "1",
      action: method, // Either "add" or "remove"
    };
    const response = await dispatch(cartUpdate(data));
  };

  const fetchData = async (page) => {
    try {
      setIsLoading(true);
      const response = await dispatch(getProductDetails(id));
      setWishlistAdded(response?.data?.is_in_wishlist);
      if (response?.data?.images?.length > 0) {
        setSelectedImage(
          `http://dev-api.cruisemotors.co/${response?.data?.images[0].image?.path}`
        );
      }
      setProduct(response?.data);
      setIsInCart(response?.data?.is_in_cart);
    } catch (error) {
      console.error("API Error:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleCartButtonClick = async () => {
    if (isInCart) {
      // Navigate to cart page
      window.location.href = "/cart"; // Replace with your cart route
    } else {
      const data = {
        product_id: id,
        quantity: quantity,
        action: "add", // Either "add" or "remove"
      };
      const response = await dispatch(cartUpdate(data));
      setIsInCart(true);
    }
  };
  const handleShare = () => {
    // Check if Web Share API is available
    const productUrl = window.location.href;
    if (navigator.share) {
      navigator
        .share({
          title: `Check out this product: ${product?.name}`,
          text: `I found this amazing product: ${product?.name}. Check it out!`,
          url: productUrl, // The URL to share
        })
        .then(() => console.log("Product shared successfully!"))
        .catch((error) => console.error("Error sharing the product:", error));
    } else {
      // Fallback for PC web where Web Share API is not supported
      const shareText = `Check out this product: ${product?.name} at ${productUrl}`;
      navigator.clipboard
        .writeText(shareText)
        .then(() => alert("Product link copied to clipboard!"))
        .catch((error) => console.error("Error copying to clipboard:", error));
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600); // Define the breakpoint for mobile
    };

    // Set initial value
    handleResize();
    fetchData();

    // Add event listener
    window.addEventListener("resize", handleResize);
    const toggleWishlist = async () => {
      await dispatch(removeWishList(id)); // Toggle wishlist state
      fetchData();
    };

    // Cleanup the event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <div className="bg-white">
      <RedirectToSignin />
      <RedirectToHome />
      {!isMobile ? <SecondaryHeader /> : <PrimaryMobileHeader />}
      <div className="container">
        <ShopHeader />
        <nav className="breadcrumb">
          <a className="me-2" href="/">
            Home
          </a>{" "}
          /{" "}
          <a className="ms-2 me-2" href="/service-kits">
            Service Kits
          </a>{" "}
          / <span className="ms-2">{product?.name}</span>
        </nav>
        <div className="row">
          {/* Left Section (Product Images) */}
          <div className="col-md-6">
            {/* Main Product Image */}
            <img
              src={selectedImage}
              alt="Main Product"
              className="img-fluid mb-3"
              style={{
                minHeight: "86%",
                objectFit: "contain",
                maxWidth: "86%",
              }}
            />

            {/* Thumbnail Images */}
            <div className="d-flex justify-content-start">
              {product?.images?.map((thumb, index) => (
                <img
                  key={index}
                  // Construct the full image path using the base URL and the path from the image object
                  src={`http://dev-api.cruisemotors.co/${thumb.image.path}`}
                  alt={`Thumbnail ${index + 1}`}
                  className={`thumbnail ${
                    selectedImage ===
                    `http://dev-api.cruisemotors.co/${thumb.image.path}`
                      ? "active"
                      : ""
                  }`} // Optional active class for styling
                  onClick={() =>
                    setSelectedImage(
                      `http://dev-api.cruisemotors.co/${thumb.image.path}`
                    )
                  } // Update selected image on click
                />
              ))}
            </div>
          </div>

          {/* Right Section (Product Details) */}
          <div className="col-md-6">
            {/* Product Title and Ratings */}
            <h2 className="fw-bold mb-4">{product?.name}</h2>
            <div className="d-flex align-items-center mb-2">
              <div className="">{renderStars(4.5)}</div>
              <span
                className="badge bg-white rounded text-dark ms-1"
                style={{
                  border: "1px solid #000740", // Add a solid border
                  padding: "5px 10px", // Add padding for spacing
                  display: "inline-block", // Ensure it behaves like a box
                  fontSize: "14px", // Adjust font size if necessary
                  borderRadius: "5px", // Optional: Rounded corners
                }}
              >
                4.5
              </span>
              <div
                style={{
                  width: "1px",
                  height: "20px",
                  backgroundColor: "#000740", // Line color
                  margin: "10px 10px 10px 27px", // Add space around the line
                }}
              ></div>
              <p className="mb-0 ms-3 text-muted">SKU: {product?.sku}</p>
            </div>
            <hr />
            {/* Product Description */}
            <p className="text-muted">{product?.short_description}</p>

            {/* Pricing Section */}
            <div className="mb-4 d-flex align-items-center">
              <h3 className="text-purple fw-bold">
                AED{" "}
                {product?.offer
                  ? (
                      product?.price -
                      (product?.price * product?.offer) / 100
                    ).toFixed(2)
                  : product?.price}
              </h3>
              <del className="text-muted ms-3">AED {product?.price}</del>
            </div>

            {/* Special Offer */}
            <div
              className="alert d-flex align-items-center justify-content-start"
              style={{
                backgroundColor: "#FFF4E5", // Light background color
                borderRadius: "10px",
                padding: "10px 20px",
                fontSize: "16px",
                color: "#F15A24", // Orange text for "Special Offer"
              }}
            >
              <div className="me-3 fw-bold" style={{ color: "#F15A24" }}>
                Special Offer :
              </div>
              <div className="d-flex align-items-center">
                {/* Hours */}
                <div
                  style={{
                    display: "inline-block",
                    backgroundColor: "#FFE6CC", // Box background color
                    borderRadius: "5px",
                    padding: "5px 10px",
                    fontWeight: "bold",
                    color: "#F15A24",
                    marginRight: "5px",
                  }}
                >
                  {timeRemaining.hours}
                </div>
                {/* Minutes */}
                <div
                  style={{
                    display: "inline-block",
                    backgroundColor: "#FFE6CC",
                    borderRadius: "5px",
                    padding: "5px 10px",
                    fontWeight: "bold",
                    color: "#F15A24",
                    marginRight: "5px",
                  }}
                >
                  {timeRemaining.minutes}
                </div>
                {/* Seconds */}
                <div
                  style={{
                    display: "inline-block",
                    backgroundColor: "#FFE6CC",
                    borderRadius: "5px",
                    padding: "5px 10px",
                    fontWeight: "bold",
                    color: "#F15A24",
                  }}
                >
                  {timeRemaining.seconds}
                </div>
                <span
                  className="ms-3"
                  style={{
                    fontSize: "14px",
                    color: "#6C757D", // Gray text for the description
                  }}
                >
                  Remains until the end of the offer.
                </span>
              </div>
            </div>
            {/* <div className="alert alert-warning d-flex align-items-center">
              <div>Special Offer Ends in:</div>
              <div className="ms-2">
                <span className="fw-bold">08</span> :{" "}
                <span className="fw-bold">50</span> :{" "}
                <span className="fw-bold">02</span>
              </div>
            </div> */}

            {/* Quantity and Buttons */}
            <div className="d-flex align-items-center mb-4 bg-white">
              <div
                className="input-group me-3"
                style={{
                  width: "120px",
                  border: "1px solid #000740",
                  borderRadius: "10px",
                }}
              >
                <button
                  className="btn "
                  onClick={() => handleQuantityChange(-1)}
                >
                  -
                </button>
                <input
                  type="number"
                  className="form-control text-center bg-white"
                  value={quantity}
                  min="1"
                  style={{ border: "none" }}
                />
                {/* <span>{quantity}</span> */}
                <button
                  className="btn "
                  onClick={() => handleQuantityChange(1)}
                >
                  +
                </button>
              </div>
              <button
                className="btn btn-primary me-2"
                onClick={handleCartButtonClick}
              >
                <i
                  className={`bi ${isInCart ? "bi-bag-check" : "bi-cart"} me-2`}
                ></i>
                {isInCart ? "Go to Cart" : "Add to Cart"}
              </button>
              <button className="btn " style={{ backgroundColor: "#2331A6", color:"white" }}>
                {" "}
                <i class="bi bi-cart me-2"></i>Buy Now
              </button>
            </div>

            {/* Additional Information */}
            <div
              style={{
                border: "1px solid #dee2e6", // Border for the box
                borderRadius: "10px", // Rounded corners
                padding: "15px 20px", // Spacing inside the box
                backgroundColor: "#fff", // White background
              }}
            >
              <ul className="list-unstyled">
                {/* Payment Section */}
                <li className="d-flex align-items-start mb-3">
                  <i
                    className="bi bi-credit-card"
                    style={{
                      fontSize: "24px",
                      color: "#6c757d",
                      marginRight: "15px",
                    }}
                  ></i>
                  <div>
                    <strong style={{ fontSize: "16px", color: "#333" }}>
                      Payment.
                    </strong>
                    <span
                      style={{
                        fontSize: "14px",
                        color: "#6c757d",
                        marginLeft: "5px",
                      }}
                    >
                      Payment upon receipt of goods, Payment by card in the
                      department, Google Pay, Online card, -5% discount in case
                      of payment.
                    </span>
                  </div>
                </li>
                <hr />
                {/* Warranty Section */}
                <li className="d-flex align-items-start">
                  <i
                    className="bi bi-shield-check"
                    style={{
                      fontSize: "24px",
                      color: "#6c757d",
                      marginRight: "15px",
                    }}
                  ></i>
                  <div>
                    <strong style={{ fontSize: "16px", color: "#333" }}>
                      Warranty.
                    </strong>
                    <span
                      style={{
                        fontSize: "14px",
                        color: "#6c757d",
                        marginLeft: "5px",
                      }}
                    >
                      The Consumer Protection Act does not provide for the
                      return of this product of proper quality.
                    </span>
                  </div>
                </li>
              </ul>
            </div>

            {/* Wishlist, Share, Compare */}
            <div className="d-flex justify-content-start mt-3">
              {/* Add to Wishlist */}
              <button
                className="btn d-flex align-items-center justify-content-center me-3"
                style={{
                  border: "1px solid #E0E0E0",
                  borderRadius: "10px",
                  backgroundColor: "#fff",
                  padding: "10px 15px",
                  fontSize: "14px",
                  color: "#000",
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                }}
                onClick={toggleWishlist}
              >
                <i
                  className={`bi ${
                    wishlistAdded ? "bi-heart-fill" : "bi-heart"
                  } me-2`}
                  style={{
                    fontSize: "18px",
                    color: wishlistAdded ? "red" : "#000", // Conditional color
                    cursor: "pointer", // Optional for better UX
                  }}
                ></i>
                {/* <FontAwesomeIcon
                  icon={wishlistAdded ? solidHeart : regularHeart}
                  style={{ height: "18px", width: "18px" }}
                /> */}
                Add to wishlist
              </button>

              {/* Share this Product */}
              <button
                className="btn d-flex align-items-center justify-content-center me-3"
                style={{
                  border: "1px solid #E0E0E0",
                  borderRadius: "10px",
                  backgroundColor: "#fff",
                  padding: "10px 15px",
                  fontSize: "14px",
                  color: "#000",
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                }}
                onClick={handleShare}
              >
                <i
                  className="bi bi-upload me-2"
                  style={{
                    fontSize: "18px",
                    color: "#000",
                  }}
                ></i>
                Share this Product
              </button>

              {/* Compare */}
              <button
                className="btn d-flex align-items-center justify-content-center"
                style={{
                  border: "1px solid #E0E0E0",
                  borderRadius: "10px",
                  backgroundColor: "#fff",
                  padding: "10px 15px",
                  fontSize: "14px",
                  color: "#000",
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                }}
              >
                <i
                  className="bi bi-arrow-left-right me-2"
                  style={{
                    fontSize: "18px",
                    color: "#000",
                    fontWeight: "bold",
                  }}
                ></i>
                Compare
              </button>
            </div>
          </div>
        </div>
        <div>
          {/* Tab Header */}
          <div className="mt-3">
            <div className="d-flex border-bottom mb-3 mt-5">
              {/* Description Tab */}
              <button
                className={` px-4 pb-2 ${
                  activeTab === "description" ? "active-tab" : ""
                }`}
                onClick={() => setActiveTab("description")}
                style={{
                  border: "none",
                  borderBottom:
                    activeTab === "description" ? "3px solid black" : "none",
                  background: "transparent",
                  fontWeight: activeTab === "description" ? "bold" : "normal",
                }}
              >
                Description
              </button>
              {/* Reviews Tab */}
              <button
                className={` px-4 pb-2 ${
                  activeTab === "reviews" ? "active-tab" : ""
                }`}
                onClick={() => setActiveTab("reviews")}
                style={{
                  border: "none",
                  borderBottom:
                    activeTab === "reviews" ? "3px solid black" : "none",
                  background: "transparent",
                  fontWeight: activeTab === "reviews" ? "bold" : "normal",
                }}
              >
                Reviews
              </button>
            </div>

            {/* Tab Content */}
            <div>
              {activeTab === "description" && (
                <div>
                  <p>
                    Quisque varius diam vel metus mattis, id aliquam diam
                    rhoncus. Proin vitae magna in dui finibus malesuada et at
                    nulla. Morbi elit ex, viverra vitae ante vel, blandit
                    feugiat ligula. Fusce fermentum iaculis nibh, at sodales leo
                    maximus a. Nullam ultricies sodales nunc, in pellentesque
                    lorem mattis quis. Cras imperdiet est in nunc tristique
                    lacinia. Nullam aliquam mauris eu accumsan tincidunt.
                    Suspendisse velit ex, aliquet vel ornare vel, dignissim a
                    tortor.
                  </p>
                  <p>
                    Morbi ut sapien vitae odio accumsan gravida. Morbi vitae
                    erat auctor, eleifend nunc a, lobortis neque. Praesent
                    aliquam dignissim viverra. Maecenas lacus odio, feugiat eu
                    nunc sit amet, maximus sagittis dolor. Vivamus nisi sapien,
                    elementum sit amet eros sit amet, ultricies cursus ipsum.
                    Sed consequat luctus ligula. Curabitur laoreet rhoncus
                    blandit. Aenean vel diam ut arcu pharetra dignissim ut sed
                    leo. Vivamus faucibus, ipsum in vestibulum vulputate, lorem
                    orci convallis quam, sit amet consequat nulla felis pharetra
                    lacus. Duis semper erat mauris, sed egestas purus commodo
                    vel.
                  </p>
                </div>
              )}
              {activeTab === "reviews" && (
                <div>
                  <p>No reviews yet. Be the first to review this product!</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ProductDetailsPage;
