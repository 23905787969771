import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  signOutAction,
  submitSupplyContractForm,
} from "../redux/auth/authActions";
import { Navigate, Link, Router, Route, Routes } from "react-router-dom";
import SecondaryHeader from "../components/SecondaryHeader";
import Footer from "../components/Footer";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Button, message, Upload, Table, Tag } from "antd";

import banner from "../assets/images/warranty-information.png";
import RedirectToSignin from "../components/RedirectToSignin";
import RedirectToHome from "../components/RedirectToHome";
import PrimaryMobileHeader from "../components/PrimaryMobileHeader";
import UserDashboardSideMenu from "../components/UserDashboardSideMenu";
import InputField from "../components/InputField";

const Notification = () => {
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector((state) => state.auth);
  const [isMobile, setIsMobile] = useState(false);
  const [formData, setFormData] = useState({
    // old_password: "",
    new_password: "",
    confirm_password: "",
    mobile: "",
    person_designation: "",
    company_address: "",
  });

  const [uploadedFiles, setUploadedFiles] = useState({
    tradeLicense: null,
    vehicleSpecs: null,
    purchaseOrder: null,
    letterOfIntent: null,
  });

  const [fileList, setFileList] = useState({
    tradeLicense: [],
    purchaseOrder: [],
  });

  const [errors, setErrors] = useState({});
  const [charCount, setCharCount] = useState(0);
  const charLimit = 200;
  const allowedFileTypes = [
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "image/jpeg",
    "image/png",
  ];
  const validate = () => {
    let formErrors = {};

    // if (!formData.old_password)
    //   formErrors.old_password = "Please enter old password!";
    if (!formData.new_password)
      formErrors.new_password = "Please enter new password!";
    if (!formData.confirm_password)
      formErrors.confirm_password = "Please enter confirm password!";

    return formErrors;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handlePhoneChange = (value) => {
    setFormData({
      ...formData,
      mobile: value,
    });
  };

  const beforeUpload = (file) => {
    const isAllowedFileType = allowedFileTypes.includes(file.type);

    if (!isAllowedFileType) {
      message.error(
        `${file.name} is not a valid file type. Only PDF, Word documents, and images are allowed.`
      );
    }

    return isAllowedFileType ? false : Upload.LIST_IGNORE; // Prevent upload if file type is not allowed
  };

  const handleFileChange = ({ fileList: newFileList }, fileType) => {
    setFileList({
      ...fileList,
      [fileType]: newFileList,
    });
  };

  const handleUploadChange = (info, fileType) => {
    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);
      setUploadedFiles({
        ...uploadedFiles,
        [fileType]: info.file,
      });
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
      setUploadedFiles({
        ...uploadedFiles,
        [fileType]: null,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formErrors = validate();

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    const data = new FormData(); // Create FormData object
    // data.append("old_password", formData.old_password);
    data.append("new_password", formData.new_password);
    data.append("confirm_password", formData.confirm_password);

    const response = await dispatch(submitSupplyContractForm(data));
    if (response?.code == 200) {
      message.success("Submitted successfully!");
    } else {
      message.error(response?.message || "Submission failed!");
    }
  };

  const handleSignOut = () => {
    dispatch(signOutAction());
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600); // Define the breakpoint for mobile
    };

    // Set initial value
    handleResize();

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const dataSource = [
    {
      key: "1",
      date: "2024-10-03",
      message: "Your order has been shipped",
      status: "Unread",
    },
    {
      key: "2",
      date: "2024-10-02",
      message: "Your payment has been confirmed",
      status: "Read",
    },
    {
      key: "3",
      date: "2024-10-01",
      message: "Your order has been delivered",
      status: "Unread",
    },
  ];

  // Column definition for the table
  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Message",
      dataIndex: "message",
      key: "message",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => {
        let color = status === "Read" ? "green" : "volcano";
        return <Tag color={color}>{status}</Tag>;
      },
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Button type="primary" onClick={() => handleRead(record)}>
          View
        </Button>
      ),
    },
  ];

  // Handle the Read button click
  const handleRead = (record) => {
    console.log(`Marking as read: ${record.message}`);
    // You can add your "read" logic here (e.g., updating the status or redirecting)
  };

  return (
    <div>
      <RedirectToSignin />
      <RedirectToHome />
      {!isMobile ? <SecondaryHeader /> : <PrimaryMobileHeader />}
      <div className="container">
        <div className="tendorsPage mt-4 pb-5">
          <div className="row justify-content-center">
            <div className="col-12">
              <h3 className="mb-4 text-center text-md-start">My Dashboard</h3>
            </div>
            <div className="col-12 col-md-3 mb-4">
              <UserDashboardSideMenu />
            </div>
            <div className="col-12 col-md-9">
              <div className="card bg-white border-0 rounded-10">
                <div className="card-body">
                  <form onSubmit={handleSubmit}>
                    <div className="card rounded-10 bg-white border-0 mb-5">
                      <div className="card-body p-3 p-md-5">
                        <h4 className="mb-4 text-center text-md-start">
                          Notification
                        </h4>
                        <div className="table-responsive">
                          <Table
                            dataSource={dataSource}
                            columns={columns}
                            pagination={{ pageSize: 5 }} // Adjust pagination to show fewer rows on mobile
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Notification;
